import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Button, DropdownOption, Input, Dropdown, Toggle, Tooltip, AmountInput } from '@column/column-ui-kit';
import { EditToolbar, FormElement, FormLabel, FormParagraphLight, Inner, Grid } from '~/styles';
import { CopyInput, DateInput, PageHeader, RestrictedBanner, SectionHeader } from '~/components';
import { ROUTE } from '~/app/routes';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { EntityRepository, LoanRepository } from '~/repositories';
import { CreateLoanRequest } from '~/typings/API';
import { currenciesUSD } from '~/util';

const AddEntity = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: ${({ theme }) => theme.primary.background};
`;

const ToggleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  min-height: 40px;

  svg {
    --icon-size: 18px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

export const PageLoansCreate: React.FC = () => {
  const { currentUser, currentPermission } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [entityList, setEntityList] = useState<DropdownOption[]>([]);
  const [loan, setLoan] = useState<Partial<CreateLoanRequest>>({});

  const handleCreate = () => {
    if (!loan.currency) {
      loan.currency = 'USD';
    }

    LoanRepository.create(loan)
      .then((response) => {
        navigate(ROUTE.LOANS);
        addSuccessNotification({
          content: `${response.description} added`,
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const fetchEntities = (startingAfter?: string, name?: string) => {
    EntityRepository.getAll({ startingAfter, name })
      .then((response) => {
        if (response.entities.length) {
          const entries: DropdownOption[] = startingAfter ? entityList.filter((e) => !e.isLoading) : [];
          response.entities.map((entry) => {
            entries.push({
              label: entry.name,
              value: entry.id,
            });
          });
          if (response.hasMore) {
            entries.push({
              label: 'Loading...',
              isLoading: true,
              value: false,
            });
          }
          setEntityList(entries);
        }
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleScrollEnd = () => {
    const gotLoading = entityList.find((e) => e.isLoading);

    if (gotLoading) {
      const filteredList = entityList.filter((e) => !e.isLoading);
      fetchEntities(filteredList[filteredList.length - 1]?.value);
    }
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => ({
          isSandbox: state.isSandbox,
          isLoading: state.isLoading,
          defaultPlatformId: state.currentUser?.defaultPlatformId,
        }),
        () => {
          if (!currentUser) {
            return;
          }
          fetchEntities();
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  return (
    <>
      <PageHeader text="Create Loan">
        <Button onClick={() => navigate(ROUTE.LOANS)} variant="secondary" size="small">
          Cancel
        </Button>
        <Button onClick={handleCreate} isDisabled={currentPermission?.loans !== 'write'} size="small">
          Create Loan
        </Button>
      </PageHeader>

      {currentPermission?.loans !== 'write' && (
        <Inner pb={0}>
          <RestrictedBanner />
        </Inner>
      )}

      <SectionHeader text="Information" />
      <Inner data-disabled={currentPermission?.loans !== 'write'} pt={16}>
        <Grid>
          <FormElement newRow>
            <FormLabel>Description</FormLabel>
            <Input
              onChange={(value: string) => setLoan({ ...loan, description: value })}
              value={loan?.description ?? ''}
              placeholder="Description"
            />
          </FormElement>
          <FormElement>
            <FormLabel>Is Revolving?</FormLabel>
            <ToggleWrapper>
              <Toggle
                isChecked={loan.isRevolving}
                onCheckedChange={(value: boolean) => setLoan({ ...loan, isRevolving: value })}
              />
              <Tooltip content="Indicates whether or not the loan is revolving. If checked the loan can have multiple disbursements.">
                <Icon.CircleQuestionmark />
              </Tooltip>
            </ToggleWrapper>
          </FormElement>

          <FormElement>
            <FormLabel>Entity</FormLabel>
            {entityList.length > 0 && (
              <Dropdown
                fullWidth
                active={loan.entityId}
                onChange={(value: string) => setLoan({ ...loan, entityId: value })}
                search
                searchLabel="Business or person name..."
                onSearchChange={(value: string) => fetchEntities(undefined, value)}
                onScrollEnd={handleScrollEnd}
                options={entityList}
              />
            )}
            {entityList.length < 1 && (
              <FormParagraphLight>
                No entities found - <AddEntity to={`${ROUTE.ENTITIES}/edit/business`}>add one first</AddEntity>.
              </FormParagraphLight>
            )}
          </FormElement>
          {entityList.length > 0 && (
            <FormElement>
              <FormLabel>Entity ID</FormLabel>
              <CopyInput value={loan?.entityId ?? ''} placeholder="Select an entity first" />
            </FormElement>
          )}

          <FormElement>
            <FormLabel>Maturity Date</FormLabel>
            <DateInput
              onChange={(value?: string) => setLoan({ ...loan, maturityDate: value })}
              value={loan?.maturityDate}
            />
          </FormElement>
          <FormElement>
            <FormLabel>Max Principal Balance</FormLabel>
            <AmountInput
              onChange={(value: number) => setLoan({ ...loan, maxPrincipalBalance: value?.toString() })}
              onCurrencyCodeChange={(value: string) => setLoan({ ...loan, currency: value })}
              currencyCode={loan.currency}
              currencyList={currenciesUSD}
              value={loan.maxPrincipalBalance ? parseInt(loan.maxPrincipalBalance, 10) : undefined}
            />
          </FormElement>
        </Grid>
      </Inner>

      <Inner>
        <EditToolbar>
          <Button onClick={() => navigate(ROUTE.LOANS)} variant="secondary" size="small">
            Cancel
          </Button>
          <Button onClick={handleCreate} isDisabled={currentPermission?.loans !== 'write'} size="small">
            Create Loan
          </Button>
        </EditToolbar>
      </Inner>
    </>
  );
};
