import { Icon } from '@column/column-ui-kit';
import React, { PropsWithChildren, MouseEvent, ReactNode, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';

interface LearnMoreProps {
  className?: string;
  headline: string;
  customLabel?: string;
}

const Wrapper = styled.span`
  font-size: 14px;
  line-height: inherit;
  font-weight: 600;
  color: ${({ theme }) => theme.primary.blendToBackground(900)};
  transition: color 0.15s;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.primary.blendToBackground(1100)};
  }
`;

export const LearnMore: React.FC<PropsWithChildren<LearnMoreProps>> = ({
  className,
  headline,
  children,
  customLabel,
}) => {
  const { openHelpSidebarCustom, getHelpSidebar, closeHelpSidebar, contentHelpSidebarCustom } = useHelpSidebarStore();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();

      if (getHelpSidebar() === 'Custom' && contentHelpSidebarCustom?.headline === headline) {
        closeHelpSidebar('Custom');
        return;
      }

      openHelpSidebarCustom({
        headline,
        content: children,
      });
    },
    [headline, children, contentHelpSidebarCustom]
  );

  return (
    <Wrapper onClick={handleClick} className={`${className} learn-more`}>
      {customLabel ?? 'Learn more'}
    </Wrapper>
  );
};
