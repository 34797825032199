import React from 'react';
import { BottomLinks, HelpSidebarBase } from './Base';
import { HelpSidebarList, HelpSidebarListItem } from './Onboarding';
import { HelpSidebarType } from '~/stores/HelpSidebar';
import { UnderlineLink } from '~/elements';

export const HelpSidebarRoles: React.FC = () => {
  const helpSidebar: HelpSidebarType[] = ['Roles'];

  return (
    <HelpSidebarBase helpSidebars={helpSidebar} headline="Roles">
      <HelpSidebarList>
        <HelpSidebarListItem>
          <h4>Admin</h4>
          <p>
            Admins manage platform users, settings, and info. Full access to money movement and other enabled features.
            Platform Owners are always Admin.
          </p>
        </HelpSidebarListItem>

        <HelpSidebarListItem>
          <h4>Developer</h4>
          <p>
            Developers manage platform API keys and webhooks. Full access to money movement and other enabled features.
            Developers cannot edit platform users, info, or settings.
          </p>
        </HelpSidebarListItem>

        <HelpSidebarListItem>
          <h4>Money Mover</h4>
          <p>
            Money Movers have full access to money movement and other enabled features. Money Movers cannot view
            platform users, info, settings, API keys, or webhooks.
          </p>
        </HelpSidebarListItem>

        <HelpSidebarListItem>
          <h4>Viewer</h4>
          <p>
            Viewers can create reports, and view/export data. Viewers cannot view platform users, info, settings, API
            keys, or webhooks.
          </p>
        </HelpSidebarListItem>

        <HelpSidebarListItem>
          <UnderlineLink href="https://column.com/docs/guides/roles-and-permissions" newTab>
            Learn more about Roles & Permissions
          </UnderlineLink>
        </HelpSidebarListItem>
      </HelpSidebarList>
      <BottomLinks />
    </HelpSidebarBase>
  );
};
