import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@column/column-ui-kit';
import styled from 'styled-components';
import { PopoverBase, PopoverBaseProps } from '../Base';
import { useSessionStore } from '~/stores/Session';
import { ROUTE } from '~/public/routes';
import { ROUTE as APP_ROUTE } from '~/app/routes';

interface PopoverPlatformStatusProps extends PopoverBaseProps {
  active?: string;
  onActiveChange?: (active: string) => void;
  onSelect?: () => void;
}

const Wrapper = styled(PopoverBase)`
  --popover-right: 0;
  --popover-left: auto;
  --popover-top: 36px;
  --popover-width: 240px;
  --popover-padding: 16px;
`;

const Title = styled.h4`
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 4px;
  color: ${({ theme }) => theme.foreground};
`;

const Description = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  margin: 0 0 12px;
  color: ${({ theme }) => theme.secondary.background};
`;

export const PopoverPlatformSandbox: React.FC<PopoverPlatformStatusProps> = (props) => {
  const currentPlatform = useSessionStore((s) => s.currentPlatform);
  const onboardingEnabled = currentPlatform?.metadata?.defaultDependencyGraphInstanceId;

  const navigate = useNavigate();

  return (
    <Wrapper {...props}>
      <Title>{onboardingEnabled ? 'Continue onboarding' : 'Start moving real money'}</Title>
      <Description>
        {onboardingEnabled
          ? 'Please finish our onboarding process for us to review. '
          : 'Please contact us to start your onboarding process. '}
      </Description>

      {onboardingEnabled ? (
        <Button variant="primary" size="small" onClick={() => navigate(ROUTE.REGISTER_BUSINESS_INFORMATION)}>
          Continue
        </Button>
      ) : (
        <Button
          variant="primary"
          size="small"
          onClick={() => {
            navigate(APP_ROUTE.CONTACT_FORM);
            if (props.onSelect) {
              props.onSelect();
            }
          }}
        >
          Contact us
        </Button>
      )}
    </Wrapper>
  );
};
