import React from 'react';
import styled, { css } from 'styled-components';
import { Outlet } from 'react-router-dom';
import { EditPage } from '~/styles';

interface RouteContentStyleProps {
  container?: 'small' | 'fullWidth';
}

interface RouteContentProps extends Partial<RouteContentStyleProps> {}

const Page = styled(EditPage)`
  flex-grow: 1;
`;

const Content = styled.div<RouteContentStyleProps>`
  ${({ container }) =>
    container !== 'fullWidth' &&
    css`
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
    `}

  ${({ container }) =>
    container === 'small' &&
    css`
      max-width: 660px;
    `}
`;

export const RouteContent: React.FC<RouteContentProps> = ({ container }) => {
  return (
    <Page fullWidth>
      <Content container={container}>
        <Outlet />
      </Content>
    </Page>
  );
};
