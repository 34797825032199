import { AchIcon } from './Ach';
import { BookIcon } from './Book';
import { CheckScannerIcon } from './CheckScanner';
import { ClockIcon } from './Clock';
import { IntlWireIcon } from './IntlWire';
import { IssueCheckIcon } from './IssueCheck';
import { RDCIcon } from './RDC';
import { RealtimeIcon } from './Realtime';
import { WireIcon } from './Wire';
import { CheckJam } from './CheckJam';
import { CheckContinue } from './CheckContinue';

export const IconTransfers = {
  Ach: AchIcon,
  Book: BookIcon,
  CheckScanner: CheckScannerIcon,
  Clock: ClockIcon,
  IntlWire: IntlWireIcon,
  IssueCheck: IssueCheckIcon,
  RDC: RDCIcon,
  Realtime: RealtimeIcon,
  Wire: WireIcon,
  CheckJam,
  CheckContinue,
} as const;

export type IconTransferType = keyof typeof IconTransfers;
