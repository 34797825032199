import React from 'react';
import { useNotificationStore } from '~/stores/Notification';

export const getFormErrors = (errors: any): string[] => {
  const errorMessages: string[] = [];

  const extractMessages = (errorObject: any) => {
    if (errorObject.hasOwnProperty('message') && errorObject.message.length > 0) {
      errorMessages.push(errorObject.message);
    } else {
      Object.values(errorObject).forEach((value) => {
        if (typeof value === 'object' && value !== null) {
          extractMessages(value);
        }
      });
    }
  };

  Object.values(errors).forEach(extractMessages);

  return errorMessages;
};

export const useDisplayFormErrors = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);

  const displayFormErrors = (errors: any, display: string = 'page', prefix?: string): void => {
    const errorMessages = getFormErrors(errors);

    if (errorMessages.length === 1) {
      addDangerNotification({
        display,
        content: (prefix ?? '') + errorMessages[0],
      });
    }

    if (errorMessages.length > 1) {
      addDangerNotification({
        display,
        content: (
          <>
            <h4>{prefix ?? ''}Please fix the following errors:</h4>
            <ul>
              {errorMessages.map((text) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          </>
        ),
      });
    }
  };

  return { displayFormErrors };
};
