import React from 'react';
import { HelpSidebarCustom } from './Custom';
import { HelpSidebarOnboarding } from './Onboarding';
import { HelpSidebarSupport } from './Support';
import { HelpSidebarRoles } from './Roles';

export const helpSidebars = [
  <HelpSidebarOnboarding key="HelpSidebarOnboarding" />,
  <HelpSidebarSupport key="HelpSidebarSupport" />,
  <HelpSidebarCustom key="HelpSidebarCustom" />,
  <HelpSidebarRoles key="HelpSidebarRoles" />,
];
