import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon } from '@column/column-ui-kit';
import {
  EventRepository,
  getEventRoute,
  getEventStatus,
  EventTypes,
  getEventIcon,
  getRawEventDirection,
  getRawEventType,
  getEventSummary,
  parseEvent,
  ParsedEvent,
} from '~/repositories';
import {
  EmptyState,
  PaginationWrapper,
  PaginationWrapperRefProps,
  PopoverFilterEntry,
  TableColumn,
  PageHeader,
} from '~/components';
import { ROUTE } from '~/app/routes';
import { useModalStore } from '~/stores/Modal';
import { isValidDate, getDateTextShort, getTimePassed } from '~/util';
import { EventEvent } from '~/typings/API';

type EventsTableRow = ParsedEvent & { action: React.ReactElement };

export const EventMeta = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: -4px 0;
`;

export const EventType = styled.h4`
  flex: auto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  flex-grow: 0;
  padding: 0;
  text-transform: capitalize;
`;

export const EventDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

export const EventIcon = styled.div`
  margin-left: -2px;
  display: flex;
  align-items: center;
`;

export const RelativeTime = styled.div`
  position: relative;
  opacity: 1;
  transition: opacity 0.1s;

  tr:hover & {
    opacity: 0;
  }
`;

export const AbsoluteTime = styled.div`
  position: absolute;
  display: inline-block;
  top: 0;
  left: -8px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.1s;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 10000px;
  background: ${({ theme }) => theme.background};
  border-radius: 8px;
  padding: 4px 8px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(1100, 50)};
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  tr:hover & {
    opacity: 1;
    z-index: 10;
  }
`;

export const Timestamp = styled.div`
  position: relative;
  min-height: 24px;
  font-size: 14px;
  line-height: 24px;
`;

export const Action = styled.div`
  text-align: right;
`;

export const JSONButton = styled(Button)`
  &:hover {
    &:after {
      background: ${({ theme }) => theme.gray.blendToBackground(75)};
    }
  }
`;

export const PageEvents: React.FC = () => {
  const openModal = useModalStore((state) => state.openModal);
  const navigate = useNavigate();
  const paginationRef = useRef<PaginationWrapperRefProps>(null);

  const handleFetch = async (params: any) => {
    return EventRepository.getAll(params).then((response) => {
      const entries = response.events.map((event: EventEvent) => ({
        ...parseEvent(event),
        action: (
          <Action>
            <JSONButton
              onlyIcon
              size="tiny"
              variant="muted"
              onClick={(e) => {
                e.stopPropagation();
                openModal('EventDetail', {
                  data: event.data,
                });
              }}
            >
              JSON
            </JSONButton>
          </Action>
        ),
      }));

      return { entries, hasMore: Boolean(response?.hasMore) };
    });
  };

  const eventTypes = Object.values(EventTypes).filter((name: string) => !['UNRECOGNIZED'].includes(name));

  const filter: PopoverFilterEntry[] = [
    {
      id: 'types',
      label: 'Type',
      type: 'select',
      list: eventTypes.map((eventType) => ({
        label: eventType,
        value: eventType,
      })),
    },
    {
      id: 'created',
      label: 'Created',
      type: 'date',
    },
  ];

  const columns: TableColumn[] = [
    {
      Header: 'Type',
      accessor: 'data',
      percent: 50,

      Cell: (current) => (
        <EventMeta>
          <EventType>
            {getRawEventDirection(current.value.eventType)} {getRawEventType(current.value.eventType)}
          </EventType>

          <EventDetail>
            <EventIcon>{getEventIcon(current.value.eventType)}</EventIcon>

            <span>{getEventSummary(current.value)}</span>
          </EventDetail>
        </EventMeta>
      ),
    },
    {
      Header: 'Status',
      accessor: 'eventType',
      Cell: (current) => getEventStatus(current.value),
    },
    {
      Header: 'Created',
      accessor: 'createdAt',
      sortType: 'datetime',
      Cell: (current) => (
        <Timestamp>
          <RelativeTime>{getTimePassed(current.value)}</RelativeTime>
          <AbsoluteTime>{isValidDate(current.value) ? getDateTextShort(current.value) : '-'}</AbsoluteTime>
        </Timestamp>
      ),
    },
    {
      Header: '',
      percent: 10,
      accessor: 'action',
    },
  ];

  return (
    <>
      <PageHeader text="Events" border={false} />

      <PaginationWrapper
        tableId="events"
        ref={paginationRef}
        fetch={handleFetch}
        columns={columns}
        filter={filter}
        rowClick={({ original }: { original: EventsTableRow }) =>
          navigate(
            getRawEventType(original.eventType) === 'Identity'
              ? `${getEventRoute(original.eventType)}/${(original.data.subType ?? 'undefined').toLowerCase()}/${original.data.id}`
              : getRawEventType(original.eventType) === undefined
                ? ''
                : `${getEventRoute(original.eventType)}/${original.data.id}`
          )
        }
        empty={
          <EmptyState headline="No events found" text="Create your first person or a business entity.">
            <Button onClick={() => navigate(`${ROUTE.ENTITIES}/edit/person`)} size="small" icon={<Icon.User />}>
              Create Entity
            </Button>
          </EmptyState>
        }
      />
    </>
  );
};
