import { client } from './client';
import { convertValues } from '~/util';
import {
  AchReceiveACHTransferRequest,
  AchReceiveACHTransferResponse,
  WireReceiveWireTransferRequest,
  WireReceiveWireTransferResponse,
  AchSettleACHTransferRequest,
  AchSettleACHTransferResponse,
  WireSettleWireTransferRequest,
  WireSettleWireTransferResponse,
} from '~/typings/API';

export class SimulateRepository {
  static async receiveACHCredit(request: AchReceiveACHTransferRequest) {
    return client
      .post<AchReceiveACHTransferRequest, AchReceiveACHTransferResponse>('/simulate/receive-ach-credit', request)
      .then((response) => convertValues<AchReceiveACHTransferResponse>(response));
  }

  static async receiveACHDebit(request: AchReceiveACHTransferRequest) {
    return client
      .post<AchReceiveACHTransferRequest, AchReceiveACHTransferResponse>('/simulate/receive-ach-debit', request)
      .then((response) => convertValues<AchReceiveACHTransferResponse>(response));
  }

  static async settleACH(request: AchSettleACHTransferRequest) {
    return client
      .post<AchSettleACHTransferRequest, AchSettleACHTransferResponse>('/simulate/transfers/ach/settle', request)
      .then((response) => convertValues<AchSettleACHTransferResponse>(response));
  }

  static async receiveWire(request: WireReceiveWireTransferRequest) {
    return client
      .post<WireReceiveWireTransferRequest, WireReceiveWireTransferResponse>('/simulate/receive-wire', request)
      .then((response) => convertValues<WireReceiveWireTransferResponse>(response));
  }

  static async receiveInternationalWire(request: WireReceiveWireTransferRequest) {
    return client
      .post<
        WireReceiveWireTransferRequest,
        WireReceiveWireTransferResponse
      >('/simulate/receive-international-wire', request)
      .then((response) => convertValues<WireReceiveWireTransferResponse>(response));
  }

  static async settleWire(request: WireSettleWireTransferRequest) {
    return client
      .post<WireSettleWireTransferRequest, WireSettleWireTransferResponse>('/simulate/transfers/wire/settle', request)
      .then((response) => convertValues<WireSettleWireTransferResponse>(response));
  }
}
