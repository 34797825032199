import { client } from './client';
import { PlatformRoleName } from './UserRepository';
import { convertValues } from '~/util';

export interface Invite {
  email: string;
  createdAt?: Date;
  updatedAt?: Date;
  platformId: string;
  platformRole: PlatformRoleName;
  createdBy: string;
  platformName: string;
}

export interface CreatePlatformInviteRequest {
  email: string;
  platformId: string;
  platformRole: PlatformRoleName;
}

export interface ListInvitesResponse {
  invites: Invite[];
}

export interface DeleteInviteRequest {
  platformId: string;
  email: string;
}

export interface ResendInviteEmailRequest {
  email: string;
}

export class InviteRepository {
  static async getAll(platformId?: string) {
    return client.get<unknown, ListInvitesResponse>('/dashboard/invites', { platformId }).then((response) => ({
      invites: Array.isArray(response?.invites) ? response.invites.map((e) => convertValues<Invite>(e)) : [],
    }));
  }

  static async create(request: Partial<CreatePlatformInviteRequest>) {
    return client
      .post<Partial<CreatePlatformInviteRequest>, Invite>(
        `/dashboard/invites/platforms/${request.platformId}`,
        request,
        {
          platformId: request.platformId,
        }
      )
      .then((response) => convertValues<Invite>(response));
  }

  static async resend(email: string, platformId?: string) {
    return client.post<ResendInviteEmailRequest, object>('/dashboard/invites/resend_email', { email }, { platformId });
  }

  static async delete(platformId: string, email: string) {
    return client.delete<DeleteInviteRequest, object>(`/dashboard/invites/platforms/${platformId}/email/${email}`, {
      platformId,
    });
  }
}
