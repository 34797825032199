import React from 'react';
import styled from 'styled-components';
import { Icon } from '@column/column-ui-kit';
import { useNavigate } from 'react-router-dom';
import { IconCircle } from './GetStarted';
import { Box } from '~/styles';

interface OutageBannerProps {}

const StyledBox = styled(Box)`
  position: relative;
  background: ${({ theme }) => theme.warning.blendToBackground(50)};
  box-shadow:
    ${({ theme }) => theme.warning.blendToBackground(500)} 0px 0px 0px 1px inset,
    rgba(38, 38, 44, 0.07) 0px 1px 3px;
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: inherit;
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.div`
  line-height: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.foreground};
`;

const LabelInfo = styled.span`
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  color: ${({ theme }) => theme.secondary.background};
`;

const StyledIconCircle = styled(IconCircle)`
  background: ${({ theme }) => theme.warning.blendToBackground(50)};
  box-shadow: ${({ theme }) => theme.warning.blendToBackground(500)} 0px 0px 0px 1px inset;
  flex-shrink: 0;

  svg {
    --icon-color: ${({ theme }) => theme.warning.blendToBackground(1200)};
  }
`;

export const OutageBanner: React.FC<OutageBannerProps> = () => {
  const navigate = useNavigate();

  return (
    <StyledBox>
      <Title>
        <LabelWrapper>
          <StyledIconCircle>
            <Icon.Warning />
          </StyledIconCircle>
          <Labels>
            <Label>Upcoming maintenance</Label>
            <LabelInfo>
              The Column Dashboard and API will be unavailable Saturday, June 24th from 9:30-10:30am PST (while the
              Federal Reserve is closed) for scheduled maintenance.
            </LabelInfo>
          </Labels>
        </LabelWrapper>
      </Title>
    </StyledBox>
  );
};
