import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Chip, Tooltip, Icon } from '@column/column-ui-kit';
import { PAGE } from './pages';
import { PageBankAccountRoute } from './pages/BankAccounts/Route';
import type { PermissionLevel, PlatformRolePermissionKey } from '~/repositories';

const ROUTE_PREFIX = '/app';

export const ROUTE = {
  ROOT: ROUTE_PREFIX,
  SVB_RESOURCES: `${ROUTE_PREFIX}/svb-resources`,
  ENTITIES: `${ROUTE_PREFIX}/entities`,
  BANK_ACCOUNTS: `${ROUTE_PREFIX}/accounts`,
  TRANSFERS: `${ROUTE_PREFIX}/transfers`,
  RETURNS: `${ROUTE_PREFIX}/transfers/returns`,
  COUNTERPARTIES: `${ROUTE_PREFIX}/accounts/counterparties`,
  EVENTS: `${ROUTE_PREFIX}/events`,
  EVENTS_WEBHOOK: `${ROUTE_PREFIX}/events/webhook`,
  PROFILE: `${ROUTE_PREFIX}/profile`,
  PLATFORMS: `${ROUTE_PREFIX}/profile/platforms`,
  DOCUMENTS: `${ROUTE_PREFIX}/documents`,
  REPORTING: `${ROUTE_PREFIX}/documents/reporting`,
  STATEMENTS: `${ROUTE_PREFIX}/documents/statements`,
  LOANS: `${ROUTE_PREFIX}/loans`,
  CONTACT_FORM: `${ROUTE_PREFIX}/contact`,

  PLATFORM_ROOT: `${ROUTE_PREFIX}/platform`,
  PLATFORM: `${ROUTE_PREFIX}/platform/info`,
  PLATFORM_FEATURES: `${ROUTE_PREFIX}/platform/features`,
  PLATFORM_API_KEYS: `${ROUTE_PREFIX}/platform/api-keys`,
  PLATFORM_WEBHOOKS: `${ROUTE_PREFIX}/platform/webhooks`,
  PLATFORM_SETTINGS: `${ROUTE_PREFIX}/platform/settings`,
  PLATFORM_ROOT_ENTITY: `${ROUTE_PREFIX}/platform/root-entity-sandbox`,
} as const;

const Badge = styled(Chip)`
  display: inline-block;
  vertical-align: top;
  margin-left: 4px;
`;

const RootEntitySandbox = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const SandboxTooltip = styled(Tooltip)`
  svg {
    --icon-size: 16px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
    margin-left: 2px;
  }

  ${RootEntitySandbox} {
    cursor: not-allowed;
    opacity: 0.75;
    color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  }
`;

export type PageTypeKey = keyof typeof PAGE;
type PageTypeRender = ReactNode;
export type PageTypeContent = PageTypeKey & PageTypeRender;
export type PageTypeLevel = 'top' | 'nested';
type PageTypeVisibility = 'sidebar' | 'tab' | 'hidden';
type PageTypeRouteElement = 'Page' | 'ContentDefault' | 'ContentSmall' | 'ContentFullWidth' | FC;
export type PageTypeRestrict = Partial<Record<PlatformRolePermissionKey, PermissionLevel[]>>;

interface PageTypeUrl {
  route: string;
  redirect?: string;
}

interface PageTypeBase<L extends PageTypeLevel> {
  title: string;
  label?: string | ReactNode;
  url: string | PageTypeUrl;
  visibility?: PageTypeVisibility | (L extends 'nested' ? 'inherit' : never);
  routeElement?: PageTypeRouteElement;
  restrictIf?: PageTypeRestrict;
}

interface PageWithoutChildPages<L extends PageTypeLevel> extends PageTypeBase<L> {
  content: PageTypeContent;
}

interface PageWithChildPages<L extends PageTypeLevel> extends PageTypeBase<L> {
  childPages: PageType<'nested'>[];
}

interface PageWithChildPagesWithoutRedirect<L extends PageTypeLevel> extends PageWithChildPages<L> {
  content: PageTypeContent;
}

interface PageWithChildPagesAndRedirect<L extends PageTypeLevel> extends PageWithChildPages<L> {
  content: 'redirectToFirstChild';
}

export type PageType<L extends PageTypeLevel> =
  | PageWithoutChildPages<L>
  | PageWithChildPagesAndRedirect<L>
  | PageWithChildPagesWithoutRedirect<L>;

export const Sitemap = (isSandbox?: boolean): PageType<'top'>[] => [
  {
    title: 'Home',
    url: ROUTE_PREFIX,
    content: 'Home',
    visibility: 'sidebar',
  },
  {
    title: 'Platform',
    url: `${ROUTE_PREFIX}/platform`,
    content: 'redirectToFirstChild',
    visibility: 'sidebar',
    routeElement: 'Page',
    childPages: [
      {
        title: 'Info',
        url: 'info',
        content: 'PlatformInfo',
        visibility: 'tab',
        restrictIf: {
          platformInfo: ['none', 'default'],
        },
      },
      {
        title: 'Settings',
        url: 'settings',
        content: 'PlatformsEdit',
        visibility: 'tab',
        restrictIf: {
          platformInfo: ['none', 'default'],
        },
      },
      {
        title: 'API Keys',
        url: 'api-keys',
        content: 'ApiKeys',
        visibility: 'tab',
        restrictIf: {
          apiKeys: ['none', 'default'],
        },
        childPages: [
          {
            title: 'Create',
            url: 'edit',
            content: 'ApiKeysEdit',
            childPages: [
              {
                title: 'Create',
                url: ':URLType',
                content: 'ApiKeysEdit',
                childPages: [
                  {
                    title: 'Edit',
                    url: ':id',
                    content: 'ApiKeysEdit',
                    restrictIf: {
                      apiKeys: ['none', 'default'],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Webhooks',
        url: 'webhooks',
        content: 'Webhooks',
        visibility: 'tab',
        restrictIf: {
          webhooks: ['none', 'default'],
        },
        childPages: [
          {
            title: 'Create',
            url: 'edit',
            content: 'WebhooksEdit',
            childPages: [
              {
                title: 'Create',
                url: ':URLType',
                content: 'WebhooksEdit',
                childPages: [
                  {
                    title: 'Edit',
                    url: ':id',
                    content: 'WebhooksEdit',
                    restrictIf: {
                      apiKeys: ['none', 'default'],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Root Entity (Sandbox)',
        label: isSandbox ? (
          <RootEntitySandbox>
            Root Entity
            <Badge size="small" type="info">
              Sandbox
            </Badge>
          </RootEntitySandbox>
        ) : (
          <SandboxTooltip
            content={
              <>
                Only available in
                <br />
                sandbox mode
              </>
            }
          >
            <RootEntitySandbox>
              Root Entity
              <Badge size="small" type="info">
                Sandbox
              </Badge>
              <Icon.Lock />
            </RootEntitySandbox>
          </SandboxTooltip>
        ),
        url: {
          route: 'root-entity-sandbox',
          redirect: isSandbox ? 'root-entity-sandbox' : 'info',
        },
        content: 'RootEntity',
        visibility: 'tab',
        restrictIf: {
          platformInfo: ['none', 'default'],
        },
      },
    ],
  },
  {
    title: 'User',
    url: `${ROUTE_PREFIX}/profile`,
    content: 'redirectToFirstChild',
    visibility: 'sidebar',
    routeElement: 'Page',
    childPages: [
      {
        title: 'Profile',
        url: '',
        content: 'Personal',
        visibility: 'tab',
      },
      {
        title: 'Your Platforms',
        url: 'platforms',
        content: 'Platforms',
        visibility: 'tab',
        childPages: [
          {
            title: 'Edit',
            url: 'edit/:id',
            content: 'PlatformsEdit',
          },
        ],
      },
    ],
  },
  {
    title: 'Transfers',
    url: `${ROUTE_PREFIX}/transfers`,
    content: 'Transfers',
    visibility: 'sidebar',
    restrictIf: {
      bookTransfers: ['none'],
      wireTransfers: ['none'],
      achCredits: ['none'],
      achDebits: ['none'],
    },
    childPages: [
      {
        title: 'Edit Check',
        url: 'edit/check/:id',
        content: 'TransfersCheck',
      },
      {
        title: 'View',
        url: 'edit/:type/:id',
        content: 'TransfersView',
        restrictIf: {
          bookTransfers: ['none'],
          wireTransfers: ['none'],
          achCredits: ['none'],
          achDebits: ['none'],
        },
      },
      {
        title: 'Add',
        url: 'edit/:type',
        content: 'TransfersAdd',
      },
      {
        title: 'Returns',
        url: 'returns',
        content: 'Returns',
        restrictIf: {
          achReturns: ['none'],
        },
        childPages: [
          {
            title: 'Edit',
            url: 'view/:achTransferId',
            content: 'ReturnsView',
            restrictIf: {
              achReturns: ['none'],
            },
          },
        ],
      },
    ],
  },
  {
    title: 'Loans',
    url: `${ROUTE_PREFIX}/loans`,
    content: 'Loans',
    visibility: 'sidebar',
    restrictIf: {
      loans: ['none'],
    },
    childPages: [
      {
        title: 'Create',
        url: 'edit',
        content: 'LoansCreate',
      },
      {
        title: 'Edit',
        url: 'edit/:id',
        content: 'LoansEdit',
        restrictIf: {
          loans: ['none'],
        },
        childPages: [
          {
            title: 'Create Disbursement',
            url: 'disbursement',
            content: 'LoansDisbursementCreate',
          },
          {
            title: 'Create Payment',
            url: 'payment',
            content: 'LoansPaymentCreate',
          },
        ],
      },
      {
        title: 'Disbursements',
        url: 'disbursements',
        content: 'LoansDisbursementOverview',
        restrictIf: {
          loans: ['none'],
        },
        childPages: [
          {
            title: 'Edit',
            url: 'edit/:id',
            content: 'LoansDisbursementEdit',
            restrictIf: {
              loans: ['none'],
            },
          },
          {
            title: 'Create',
            url: 'create',
            content: 'LoansDisbursementCreate',
          },
        ],
      },
      {
        title: 'Payments',
        url: 'payments',
        content: 'LoansPaymentOverview',
        restrictIf: {
          loans: ['none'],
        },
        childPages: [
          {
            title: 'Edit',
            url: 'edit/:id',
            content: 'LoansPaymentView',
            restrictIf: {
              loans: ['none'],
            },
          },
          {
            title: 'Create',
            url: 'create',
            content: 'LoansPaymentCreate',
          },
        ],
      },
    ],
  },
  {
    title: 'Accounts',
    url: `${ROUTE_PREFIX}/accounts`,
    content: 'BankAccounts',
    visibility: 'sidebar',
    childPages: [
      {
        title: 'Edit',
        url: 'edit/:id',
        content: 'redirectToFirstChild',
        routeElement: PageBankAccountRoute,
        childPages: [
          {
            title: 'Transfers',
            url: '',
            content: 'BankAccountsTransfers',
            visibility: 'tab',
          },
          {
            title: 'Edit',
            url: 'info',
            content: 'BankAccountsEdit',
            visibility: 'tab',
          },
          {
            title: 'Statements',
            url: 'statements',
            content: 'BankAccountsStatements',
            visibility: 'tab',
          },
        ],
      },
      {
        title: 'Create',
        url: 'edit',
        content: 'BankAccountsEdit',
      },
      {
        title: 'Counterparties',
        url: 'counterparties',
        content: 'Counterparties',
        restrictIf: {
          counterparties: ['none'],
        },
        childPages: [
          {
            title: 'Edit',
            url: 'edit/:id',
            content: 'CounterpartiesEdit',
            restrictIf: {
              counterparties: ['none'],
            },
          },
          {
            title: 'Create',
            url: 'edit',
            content: 'CounterpartiesEdit',
          },
        ],
      },
    ],
  },
  {
    title: 'Entities',
    url: `${ROUTE_PREFIX}/entities`,
    content: 'Entities',
    visibility: 'sidebar',
    childPages: [
      {
        title: 'Edit',
        url: 'edit/:type/:id',
        content: 'EntitiesEdit',
      },
      {
        title: 'Create',
        url: 'edit/:type',
        content: 'EntitiesEdit',
      },
    ],
  },
  {
    title: 'Documents',
    url: `${ROUTE_PREFIX}/documents`,
    visibility: 'sidebar',
    routeElement: 'Page',
    content: 'redirectToFirstChild',
    childPages: [
      {
        title: 'Statements',
        url: 'statements',
        content: 'Statements',
        visibility: 'tab',
        childPages: [
          {
            title: 'View',
            url: ':id',
            content: 'DocumentView',
            restrictIf: {
              reporting: ['none'],
            },
          },
        ],
      },
      {
        title: 'Reports',
        url: 'reporting',
        content: 'Reporting',
        visibility: 'tab',
        restrictIf: {
          reporting: ['none'],
        },
        childPages: [
          {
            title: 'Schedule',
            url: 'schedule',
            content: 'ReportingSchedule',
          },
          {
            title: 'View',
            url: ':id',
            content: 'DocumentView',
            restrictIf: {
              reporting: ['none'],
            },
          },
        ],
      },
    ],
  },
  {
    title: 'Documents',
    url: `${ROUTE_PREFIX}/documents`,
    content: 'Documents',
  },
  {
    title: 'Events',
    url: `${ROUTE_PREFIX}/events`,
    content: 'Events',
    visibility: 'sidebar',
    childPages: [
      {
        title: 'Webhook',
        url: 'webhook',
        content: 'WebhookEvents',
      },
    ],
  },
  {
    title: 'Contact',
    url: `${ROUTE_PREFIX}/contact`,
    content: 'ContactForm',
    routeElement: 'ContentSmall',
  },
  {
    title: 'SVB Resources',
    url: `${ROUTE_PREFIX}/svb-resources`,
    content: 'SVBResources',
  },
];
