/* Code generated by "enumer -linecomment -type=EventType -ts=../../../frontend/src/if/event/EventType.ts"; DO NOT EDIT. */

export enum EventType {
  EvtACHOutgoingTransferInitiated = 'ach.outgoing_transfer.initiated',
  EvtACHOutgoingTransferSubmitted = 'ach.outgoing_transfer.submitted',
  EvtACHOutgoingTransferSettled = 'ach.outgoing_transfer.settled',
  EvtACHOutgoingTransferCompleted = 'ach.outgoing_transfer.completed',
  EvtACHOutgoingTransferManualReview = 'ach.outgoing_transfer.manual_review',
  EvtACHOutgoingTransferReturned = 'ach.outgoing_transfer.returned',
  EvtACHOutgoingTransferCanceled = 'ach.outgoing_transfer.canceled',
  EvtACHOutgoingTransferReturnDishonored = 'ach.outgoing_transfer.return_dishonored',
  EvtACHOutgoingTransferReturnDishonoredFundsUnlocked = 'ach.outgoing_transfer.return_dishonored_funds_unlocked',
  EvtACHOutgoingTransferReturnContested = 'ach.outgoing_transfer.return_contested',
  EvtACHOutgoingTransferNOC = 'ach.outgoing_transfer.noc',
  EvtACHIncomingTransferSettled = 'ach.incoming_transfer.settled',
  EvtACHIncomingTransferCompleted = 'ach.incoming_transfer.completed',
  EvtACHIncomingTransferNSF = 'ach.incoming_transfer.nsf',
  EvtACHIncomingTransferReturned = 'ach.incoming_transfer.returned',
  EvtACHIncomingTransferScheduled = 'ach.incoming_transfer.scheduled',
  EvtACHIncomingTransferReturnDishonored = 'ach.incoming_transfer.return_dishonored',
  EvtACHIncomingTransferReturnContested = 'ach.incoming_transfer.return_contested',
  EvtWireOutgoingTransferInitiated = 'wire.outgoing_transfer.initiated',
  EvtWireOutgoingTransferSubmitted = 'wire.outgoing_transfer.submitted',
  EvtWireOutgoingTransferRejected = 'wire.outgoing_transfer.rejected',
  EvtWireOutgoingTransferCompleted = 'wire.outgoing_transfer.completed',
  EvtWireIncomingTransferCompleted = 'wire.incoming_transfer.completed',
  EvtWireIncomingTransferRejected = 'wire.incoming_transfer.rejected',
  EvtWireOutgoingTransferManualReview = 'wire.outgoing_transfer.manual_review',
  EvtWireIncomingDrawdownRequestReceived = 'wire.incoming_drawdown_request.received',
  EvtWireIncomingDrawdownRequestApproved = 'wire.incoming_drawdown_request.approved',
  EvtWireIncomingDrawdownRequestDenied = 'wire.incoming_drawdown_request.denied',
  EvtWireOutgoingDrawdownRequestInitiated = 'wire.outgoing_drawdown_request.initiated',
  EvtWireOutgoingDrawdownRequestSent = 'wire.outgoing_drawdown_request.sent',
  EvtWireOutgoingDrawdownRequestApproved = 'wire.outgoing_drawdown_request.approved',
  EvtWireOutgoingDrawdownRequestRejected = 'wire.outgoing_drawdown_request.rejected',
  EvtBookTransferCompleted = 'book.transfer.completed',
  EvtBookTransferInitiated = 'book.transfer.initiated',
  EvtBookTransferHoldCreated = 'book.transfer.hold_created',
  EvtBookTransferUpdated = 'book.transfer.updated',
  EvtBookTransferCanceled = 'book.transfer.canceled',
  EvtIdentityVerificationPending = 'identity.verification.pending',
  EvtIdentityVerificationManualReview = 'identity.verification.manual_review',
  EvtIdentityVerificationVerified = 'identity.verification.verified',
  EvtIdentityVerificationDenied = 'identity.verification.denied',
  EvtIdentityCreated = 'identity.created',
  EvtAccountOverdrafted = 'account.overdrafted',
  EvtAccountCreated = 'account.created',
  EvtAccountOverdraftReleased = 'account.overdraft_released',
  EvtLoanCreated = 'loan.created',
  EvtLoanUpdated = 'loan.updated',
  EvtLoanInDispute = 'loan.in_dispute',
  EvtLoanDelinquent = 'loan.delinquent',
  EvtLoanPaidOff = 'loan.paid_off',
  EvtLoanChargedOff = 'loan.charged_off',
  EvtLoanCanceled = 'loan.canceled',
  EvtLoanDisbursementHoldCreated = 'loan.disbursement.hold_created',
  EvtLoanDisbursementHoldUpdated = 'loan.disbursement.hold_updated',
  EvtLoanDisbursementHoldCanceled = 'loan.disbursement.hold_canceled',
  EvtLoanDisbursementCompleted = 'loan.disbursement.completed',
  EvtLoanPaymentCompleted = 'loan.payment.completed',
  EvtBankAccountSummaryScheduled = 'reporting.bank_account_summary.scheduled',
  EvtBankAccountSummaryCompleted = 'reporting.bank_account_summary.completed',
  EvtBankAccountSummaryFailed = 'reporting.bank_account_summary.failed',
  EvtBankAccountTransactionScheduled = 'reporting.bank_account_transaction.scheduled',
  EvtBankAccountTransactionCompleted = 'reporting.bank_account_transaction.completed',
  EvtBankAccountTransactionFailed = 'reporting.bank_account_transaction.failed',
  EvtBankAccountMonthlyStatementCompleted = 'reporting.bank_account_monthly_statement.completed',
  EvtBankAccountDailyStatementCompleted = 'reporting.bank_account_daily_statement.completed',
  EvtBankAccountInterestCompleted = 'reporting.bank_account_interest.completed',
  EvtSwiftOutgoingTransferInitiated = 'swift.outgoing_transfer.initiated',
  EvtSwiftOutgoingTransferSubmitted = 'swift.outgoing_transfer.submitted',
  EvtSwiftOutgoingTransferPendingReturn = 'swift.outgoing_transfer.pending_return',
  EvtSwiftOutgoingTransferReturned = 'swift.outgoing_transfer.returned',
  EvtSwiftOutgoingTransferCompleted = 'swift.outgoing_transfer.completed',
  EvtSwiftOutgoingTransferManualReview = 'swift.outgoing_transfer.manual_review',
  EvtSwiftOutgoingTransferCancellationRequested = 'swift.outgoing_transfer.cancellation_requested',
  EvtSwiftOutgoingTransferCancellationAccepted = 'swift.outgoing_transfer.cancellation_accepted',
  EvtSwiftOutgoingTransferCancellationRejected = 'swift.outgoing_transfer.cancellation_rejected',
  EvtSwiftOutgoingTransferTrackingUpdated = 'swift.outgoing_transfer.tracking_updated',
  EvtSwiftIncomingTransferInitiated = 'swift.incoming_transfer.initiated',
  EvtSwiftIncomingTransferCompleted = 'swift.incoming_transfer.completed',
  EvtSwiftIncomingTransferPendingReturn = 'swift.incoming_transfer.pending_return',
  EvtSwiftIncomingTransferReturned = 'swift.incoming_transfer.returned',
  EvtSwiftIncomingTransferCancellationRequested = 'swift.incoming_transfer.cancellation_requested',
  EvtSwiftIncomingTransferCancellationAccepted = 'swift.incoming_transfer.cancellation_accepted',
  EvtSwiftIncomingTransferCancellationRejected = 'swift.incoming_transfer.cancellation_rejected',
  EvtSwiftIncomingTransferTrackingUpdated = 'swift.incoming_transfer.tracking_updated',
  EvtCheckIncomingDebitSettled = 'check.incoming_debit.settled',
  EvtCheckIncomingDebitPendingStop = 'check.incoming_debit.pending_stop',
  EvtCheckIncomingDebitStopped = 'check.incoming_debit.stopped',
  EvtCheckIncomingDebitInitiated = 'check.incoming_debit.initiated',
  EvtCheckIncomingDebitAutoReturn = 'check.incoming_debit.auto_return',
  EvtCheckIncomingDebitPendingFirstReturn = 'check.incoming_debit.pending_first_return',
  EvtCheckIncomingDebitFirstReturn = 'check.incoming_debit.first_return',
  EvtCheckIncomingDebitUserInitiatedReturnSubmitted = 'check.incoming_debit.user_initiated_return_submitted',
  EvtCheckIncomingDebitUserInitiatedReturned = 'check.incoming_debit.user_initiated_returned',
  EvtCheckIncomingDebitUserInitiatedReturnDishonored = 'check.incoming_debit.user_initiated_return_dishonored',
  EvtCheckIncomingDebitIssued = 'check.incoming_debit.issued',
  EvtCheckIncomingDebitManualReview = 'check.incoming_debit.manual_review',
  EvtCheckIncomingDebitPendingDeposit = 'check.incoming_debit.pending_deposit',
  EvtCheckIncomingDebitRejected = 'check.incoming_debit.rejected',
  EvtCheckIncomingDebitPendingReclear = 'check.incoming_debit.pending_reclear',
  EvtCheckIncomingDebitPendingSecondReturn = 'check.incoming_debit.pending_second_return',
  EvtCheckIncomingDebitSecondReturn = 'check.incoming_debit.second_return',
  EvtCheckIncomingDebitDeliveryCreated = 'check.incoming_debit.delivery.created',
  EvtCheckIncomingDebitDeliveryMailed = 'check.incoming_debit.delivery.mailed',
  EvtCheckIncomingDebitDeliveryInTransit = 'check.incoming_debit.delivery.in_transit',
  EvtCheckIncomingDebitDeliveryInLocalArea = 'check.incoming_debit.delivery.in_local_area',
  EvtCheckIncomingDebitDeliveryProcessedForDelivery = 'check.incoming_debit.delivery.processed_for_delivery',
  EvtCheckIncomingDebitDeliveryDelivered = 'check.incoming_debit.delivery.delivered',
  EvtCheckIncomingDebitDeliveryFailed = 'check.incoming_debit.delivery.failed',
  EvtCheckIncomingDebitDeliveryRerouted = 'check.incoming_debit.delivery.rerouted',
  EvtCheckIncomingDebitDeliveryReturnedToSender = 'check.incoming_debit.delivery.returned_to_sender',
  EvtCheckOutgoingDebitInitiated = 'check.outgoing_debit.initiated',
  EvtCheckOutgoingDebitPendingDeposit = 'check.outgoing_debit.pending_deposit',
  EvtCheckOutgoingDebitManualReview = 'check.outgoing_debit.manual_review',
  EvtCheckOutgoingDebitRejected = 'check.outgoing_debit.rejected',
  EvtCheckOutgoingDebitDeposited = 'check.outgoing_debit.deposited',
  EvtCheckOutgoingDebitPendingReclear = 'check.outgoing_debit.pending_reclear',
  EvtCheckOutgoingDebitRecleared = 'check.outgoing_debit.recleared',
  EvtCheckOutgoingDebitSettled = 'check.outgoing_debit.settled',
  EvtCheckOutgoingDebitReturned = 'check.outgoing_debit.returned',
  EvtRealtimeOutgoingTransferInitiated = 'realtime.outgoing_transfer.initiated',
  EvtRealtimeOutgoingTransferBlocked = 'realtime.outgoing_transfer.blocked',
  EvtRealtimeOutgoingTransferRejected = 'realtime.outgoing_transfer.rejected',
  EvtRealtimeOutgoingTransferPending = 'realtime.outgoing_transfer.pending',
  EvtRealtimeOutgoingTransferAccepted = 'realtime.outgoing_transfer.accepted',
  EvtRealtimeOutgoingTransferCompleted = 'realtime.outgoing_transfer.completed',
  EvtRealtimeOutgoingTransferManualReview = 'realtime.outgoing_transfer.manual_review',
  EvtRealtimeOutgoingTransferManualReviewApproved = 'realtime.outgoing_transfer.manual_review_approved',
  EvtRealtimeOutgoingTransferManualReviewRejected = 'realtime.outgoing_transfer.manual_review_rejected',
  EvtRealtimeIncomingTransferAccepted = 'realtime.incoming_transfer.accepted',
  EvtRealtimeIncomingTransferRejected = 'realtime.incoming_transfer.rejected',
  EvtRealtimeIncomingTransferCompleted = 'realtime.incoming_transfer.completed',
  EvtInterestPayoutCompleted = 'interest.payout.completed',
  EvtBankAccountDailyCount = 'bank_account.daily_count',
  EvtEntityDailyCount = 'identity.daily_count',
  EvtActiveLoanDailyCount = 'loan.active_daily_count',
  Evt180DayActiveBankAccountCount = 'bank_account.180day_active_daily_count',
  Evt180DayActiveEntityCount = 'identity.180day_active_daily_count',
  UNRECOGNIZED = 'UNRECOGNIZED',
}

export const EventTypeFromJSON = (object: any) => {
  switch (object) {
    case 1:
    case 'ach.outgoing_transfer.initiated':
      return EventType.EvtACHOutgoingTransferInitiated;
    case 2:
    case 'ach.outgoing_transfer.submitted':
      return EventType.EvtACHOutgoingTransferSubmitted;
    case 4:
    case 'ach.outgoing_transfer.settled':
      return EventType.EvtACHOutgoingTransferSettled;
    case 5:
    case 'ach.outgoing_transfer.completed':
      return EventType.EvtACHOutgoingTransferCompleted;
    case 19:
    case 'ach.outgoing_transfer.manual_review':
      return EventType.EvtACHOutgoingTransferManualReview;
    case 6:
    case 'ach.outgoing_transfer.returned':
      return EventType.EvtACHOutgoingTransferReturned;
    case 7:
    case 'ach.outgoing_transfer.canceled':
      return EventType.EvtACHOutgoingTransferCanceled;
    case 20:
    case 'ach.outgoing_transfer.return_dishonored':
      return EventType.EvtACHOutgoingTransferReturnDishonored;
    case 25:
    case 'ach.outgoing_transfer.return_dishonored_funds_unlocked':
      return EventType.EvtACHOutgoingTransferReturnDishonoredFundsUnlocked;
    case 21:
    case 'ach.outgoing_transfer.return_contested':
      return EventType.EvtACHOutgoingTransferReturnContested;
    case 24:
    case 'ach.outgoing_transfer.noc':
      return EventType.EvtACHOutgoingTransferNOC;
    case 8:
    case 'ach.incoming_transfer.settled':
      return EventType.EvtACHIncomingTransferSettled;
    case 9:
    case 'ach.incoming_transfer.completed':
      return EventType.EvtACHIncomingTransferCompleted;
    case 10:
    case 'ach.incoming_transfer.nsf':
      return EventType.EvtACHIncomingTransferNSF;
    case 11:
    case 'ach.incoming_transfer.returned':
      return EventType.EvtACHIncomingTransferReturned;
    case 12:
    case 'ach.incoming_transfer.scheduled':
      return EventType.EvtACHIncomingTransferScheduled;
    case 22:
    case 'ach.incoming_transfer.return_dishonored':
      return EventType.EvtACHIncomingTransferReturnDishonored;
    case 23:
    case 'ach.incoming_transfer.return_contested':
      return EventType.EvtACHIncomingTransferReturnContested;
    case 31:
    case 'wire.outgoing_transfer.initiated':
      return EventType.EvtWireOutgoingTransferInitiated;
    case 32:
    case 'wire.outgoing_transfer.submitted':
      return EventType.EvtWireOutgoingTransferSubmitted;
    case 33:
    case 'wire.outgoing_transfer.rejected':
      return EventType.EvtWireOutgoingTransferRejected;
    case 34:
    case 'wire.outgoing_transfer.completed':
      return EventType.EvtWireOutgoingTransferCompleted;
    case 35:
    case 'wire.incoming_transfer.completed':
      return EventType.EvtWireIncomingTransferCompleted;
    case 36:
    case 'wire.incoming_transfer.rejected':
      return EventType.EvtWireIncomingTransferRejected;
    case 37:
    case 'wire.outgoing_transfer.manual_review':
      return EventType.EvtWireOutgoingTransferManualReview;
    case 38:
    case 'wire.incoming_drawdown_request.received':
      return EventType.EvtWireIncomingDrawdownRequestReceived;
    case 39:
    case 'wire.incoming_drawdown_request.approved':
      return EventType.EvtWireIncomingDrawdownRequestApproved;
    case 40:
    case 'wire.incoming_drawdown_request.denied':
      return EventType.EvtWireIncomingDrawdownRequestDenied;
    case 41:
    case 'wire.outgoing_drawdown_request.initiated':
      return EventType.EvtWireOutgoingDrawdownRequestInitiated;
    case 42:
    case 'wire.outgoing_drawdown_request.sent':
      return EventType.EvtWireOutgoingDrawdownRequestSent;
    case 43:
    case 'wire.outgoing_drawdown_request.approved':
      return EventType.EvtWireOutgoingDrawdownRequestApproved;
    case 44:
    case 'wire.outgoing_drawdown_request.rejected':
      return EventType.EvtWireOutgoingDrawdownRequestRejected;
    case 51:
    case 'book.transfer.completed':
      return EventType.EvtBookTransferCompleted;
    case 52:
    case 'book.transfer.initiated':
      return EventType.EvtBookTransferInitiated;
    case 53:
    case 'book.transfer.hold_created':
      return EventType.EvtBookTransferHoldCreated;
    case 54:
    case 'book.transfer.updated':
      return EventType.EvtBookTransferUpdated;
    case 55:
    case 'book.transfer.canceled':
      return EventType.EvtBookTransferCanceled;
    case 61:
    case 'identity.verification.pending':
      return EventType.EvtIdentityVerificationPending;
    case 62:
    case 'identity.verification.manual_review':
      return EventType.EvtIdentityVerificationManualReview;
    case 63:
    case 'identity.verification.verified':
      return EventType.EvtIdentityVerificationVerified;
    case 64:
    case 'identity.verification.denied':
      return EventType.EvtIdentityVerificationDenied;
    case 65:
    case 'identity.created':
      return EventType.EvtIdentityCreated;
    case 81:
    case 'account.overdrafted':
      return EventType.EvtAccountOverdrafted;
    case 82:
    case 'account.created':
      return EventType.EvtAccountCreated;
    case 83:
    case 'account.overdraft_released':
      return EventType.EvtAccountOverdraftReleased;
    case 121:
    case 'loan.created':
      return EventType.EvtLoanCreated;
    case 122:
    case 'loan.updated':
      return EventType.EvtLoanUpdated;
    case 123:
    case 'loan.in_dispute':
      return EventType.EvtLoanInDispute;
    case 124:
    case 'loan.delinquent':
      return EventType.EvtLoanDelinquent;
    case 125:
    case 'loan.paid_off':
      return EventType.EvtLoanPaidOff;
    case 126:
    case 'loan.charged_off':
      return EventType.EvtLoanChargedOff;
    case 127:
    case 'loan.canceled':
      return EventType.EvtLoanCanceled;
    case 141:
    case 'loan.disbursement.hold_created':
      return EventType.EvtLoanDisbursementHoldCreated;
    case 142:
    case 'loan.disbursement.hold_updated':
      return EventType.EvtLoanDisbursementHoldUpdated;
    case 143:
    case 'loan.disbursement.hold_canceled':
      return EventType.EvtLoanDisbursementHoldCanceled;
    case 144:
    case 'loan.disbursement.completed':
      return EventType.EvtLoanDisbursementCompleted;
    case 151:
    case 'loan.payment.completed':
      return EventType.EvtLoanPaymentCompleted;
    case 161:
    case 'reporting.bank_account_summary.scheduled':
      return EventType.EvtBankAccountSummaryScheduled;
    case 162:
    case 'reporting.bank_account_summary.completed':
      return EventType.EvtBankAccountSummaryCompleted;
    case 163:
    case 'reporting.bank_account_summary.failed':
      return EventType.EvtBankAccountSummaryFailed;
    case 164:
    case 'reporting.bank_account_transaction.scheduled':
      return EventType.EvtBankAccountTransactionScheduled;
    case 165:
    case 'reporting.bank_account_transaction.completed':
      return EventType.EvtBankAccountTransactionCompleted;
    case 166:
    case 'reporting.bank_account_transaction.failed':
      return EventType.EvtBankAccountTransactionFailed;
    case 168:
    case 'reporting.bank_account_monthly_statement.completed':
      return EventType.EvtBankAccountMonthlyStatementCompleted;
    case 171:
    case 'reporting.bank_account_daily_statement.completed':
      return EventType.EvtBankAccountDailyStatementCompleted;
    case 173:
    case 'reporting.bank_account_interest.completed':
      return EventType.EvtBankAccountInterestCompleted;
    case 221:
    case 'swift.outgoing_transfer.initiated':
      return EventType.EvtSwiftOutgoingTransferInitiated;
    case 222:
    case 'swift.outgoing_transfer.submitted':
      return EventType.EvtSwiftOutgoingTransferSubmitted;
    case 238:
    case 'swift.outgoing_transfer.pending_return':
      return EventType.EvtSwiftOutgoingTransferPendingReturn;
    case 223:
    case 'swift.outgoing_transfer.returned':
      return EventType.EvtSwiftOutgoingTransferReturned;
    case 224:
    case 'swift.outgoing_transfer.completed':
      return EventType.EvtSwiftOutgoingTransferCompleted;
    case 225:
    case 'swift.outgoing_transfer.manual_review':
      return EventType.EvtSwiftOutgoingTransferManualReview;
    case 233:
    case 'swift.outgoing_transfer.cancellation_requested':
      return EventType.EvtSwiftOutgoingTransferCancellationRequested;
    case 234:
    case 'swift.outgoing_transfer.cancellation_accepted':
      return EventType.EvtSwiftOutgoingTransferCancellationAccepted;
    case 235:
    case 'swift.outgoing_transfer.cancellation_rejected':
      return EventType.EvtSwiftOutgoingTransferCancellationRejected;
    case 236:
    case 'swift.outgoing_transfer.tracking_updated':
      return EventType.EvtSwiftOutgoingTransferTrackingUpdated;
    case 229:
    case 'swift.incoming_transfer.initiated':
      return EventType.EvtSwiftIncomingTransferInitiated;
    case 226:
    case 'swift.incoming_transfer.completed':
      return EventType.EvtSwiftIncomingTransferCompleted;
    case 230:
    case 'swift.incoming_transfer.pending_return':
      return EventType.EvtSwiftIncomingTransferPendingReturn;
    case 227:
    case 'swift.incoming_transfer.returned':
      return EventType.EvtSwiftIncomingTransferReturned;
    case 228:
    case 'swift.incoming_transfer.cancellation_requested':
      return EventType.EvtSwiftIncomingTransferCancellationRequested;
    case 231:
    case 'swift.incoming_transfer.cancellation_accepted':
      return EventType.EvtSwiftIncomingTransferCancellationAccepted;
    case 232:
    case 'swift.incoming_transfer.cancellation_rejected':
      return EventType.EvtSwiftIncomingTransferCancellationRejected;
    case 237:
    case 'swift.incoming_transfer.tracking_updated':
      return EventType.EvtSwiftIncomingTransferTrackingUpdated;
    case 241:
    case 'check.incoming_debit.settled':
      return EventType.EvtCheckIncomingDebitSettled;
    case 270:
    case 'check.incoming_debit.pending_stop':
      return EventType.EvtCheckIncomingDebitPendingStop;
    case 242:
    case 'check.incoming_debit.stopped':
      return EventType.EvtCheckIncomingDebitStopped;
    case 277:
    case 'check.incoming_debit.initiated':
      return EventType.EvtCheckIncomingDebitInitiated;
    case 248:
    case 'check.incoming_debit.auto_return':
      return EventType.EvtCheckIncomingDebitAutoReturn;
    case 249:
    case 'check.incoming_debit.pending_first_return':
      return EventType.EvtCheckIncomingDebitPendingFirstReturn;
    case 274:
    case 'check.incoming_debit.first_return':
      return EventType.EvtCheckIncomingDebitFirstReturn;
    case 275:
    case 'check.incoming_debit.user_initiated_return_submitted':
      return EventType.EvtCheckIncomingDebitUserInitiatedReturnSubmitted;
    case 250:
    case 'check.incoming_debit.user_initiated_returned':
      return EventType.EvtCheckIncomingDebitUserInitiatedReturned;
    case 276:
    case 'check.incoming_debit.user_initiated_return_dishonored':
      return EventType.EvtCheckIncomingDebitUserInitiatedReturnDishonored;
    case 251:
    case 'check.incoming_debit.issued':
      return EventType.EvtCheckIncomingDebitIssued;
    case 252:
    case 'check.incoming_debit.manual_review':
      return EventType.EvtCheckIncomingDebitManualReview;
    case 259:
    case 'check.incoming_debit.pending_deposit':
      return EventType.EvtCheckIncomingDebitPendingDeposit;
    case 253:
    case 'check.incoming_debit.rejected':
      return EventType.EvtCheckIncomingDebitRejected;
    case 254:
    case 'check.incoming_debit.pending_reclear':
      return EventType.EvtCheckIncomingDebitPendingReclear;
    case 255:
    case 'check.incoming_debit.pending_second_return':
      return EventType.EvtCheckIncomingDebitPendingSecondReturn;
    case 256:
    case 'check.incoming_debit.second_return':
      return EventType.EvtCheckIncomingDebitSecondReturn;
    case 260:
    case 'check.incoming_debit.delivery.created':
      return EventType.EvtCheckIncomingDebitDeliveryCreated;
    case 261:
    case 'check.incoming_debit.delivery.mailed':
      return EventType.EvtCheckIncomingDebitDeliveryMailed;
    case 262:
    case 'check.incoming_debit.delivery.in_transit':
      return EventType.EvtCheckIncomingDebitDeliveryInTransit;
    case 263:
    case 'check.incoming_debit.delivery.in_local_area':
      return EventType.EvtCheckIncomingDebitDeliveryInLocalArea;
    case 264:
    case 'check.incoming_debit.delivery.processed_for_delivery':
      return EventType.EvtCheckIncomingDebitDeliveryProcessedForDelivery;
    case 265:
    case 'check.incoming_debit.delivery.delivered':
      return EventType.EvtCheckIncomingDebitDeliveryDelivered;
    case 266:
    case 'check.incoming_debit.delivery.failed':
      return EventType.EvtCheckIncomingDebitDeliveryFailed;
    case 267:
    case 'check.incoming_debit.delivery.rerouted':
      return EventType.EvtCheckIncomingDebitDeliveryRerouted;
    case 268:
    case 'check.incoming_debit.delivery.returned_to_sender':
      return EventType.EvtCheckIncomingDebitDeliveryReturnedToSender;
    case 243:
    case 'check.outgoing_debit.initiated':
      return EventType.EvtCheckOutgoingDebitInitiated;
    case 273:
    case 'check.outgoing_debit.pending_deposit':
      return EventType.EvtCheckOutgoingDebitPendingDeposit;
    case 271:
    case 'check.outgoing_debit.manual_review':
      return EventType.EvtCheckOutgoingDebitManualReview;
    case 272:
    case 'check.outgoing_debit.rejected':
      return EventType.EvtCheckOutgoingDebitRejected;
    case 244:
    case 'check.outgoing_debit.deposited':
      return EventType.EvtCheckOutgoingDebitDeposited;
    case 257:
    case 'check.outgoing_debit.pending_reclear':
      return EventType.EvtCheckOutgoingDebitPendingReclear;
    case 258:
    case 'check.outgoing_debit.recleared':
      return EventType.EvtCheckOutgoingDebitRecleared;
    case 245:
    case 'check.outgoing_debit.settled':
      return EventType.EvtCheckOutgoingDebitSettled;
    case 246:
    case 'check.outgoing_debit.returned':
      return EventType.EvtCheckOutgoingDebitReturned;
    case 291:
    case 'realtime.outgoing_transfer.initiated':
      return EventType.EvtRealtimeOutgoingTransferInitiated;
    case 292:
    case 'realtime.outgoing_transfer.blocked':
      return EventType.EvtRealtimeOutgoingTransferBlocked;
    case 293:
    case 'realtime.outgoing_transfer.rejected':
      return EventType.EvtRealtimeOutgoingTransferRejected;
    case 294:
    case 'realtime.outgoing_transfer.pending':
      return EventType.EvtRealtimeOutgoingTransferPending;
    case 295:
    case 'realtime.outgoing_transfer.accepted':
      return EventType.EvtRealtimeOutgoingTransferAccepted;
    case 296:
    case 'realtime.outgoing_transfer.completed':
      return EventType.EvtRealtimeOutgoingTransferCompleted;
    case 300:
    case 'realtime.outgoing_transfer.manual_review':
      return EventType.EvtRealtimeOutgoingTransferManualReview;
    case 301:
    case 'realtime.outgoing_transfer.manual_review_approved':
      return EventType.EvtRealtimeOutgoingTransferManualReviewApproved;
    case 302:
    case 'realtime.outgoing_transfer.manual_review_rejected':
      return EventType.EvtRealtimeOutgoingTransferManualReviewRejected;
    case 297:
    case 'realtime.incoming_transfer.accepted':
      return EventType.EvtRealtimeIncomingTransferAccepted;
    case 298:
    case 'realtime.incoming_transfer.rejected':
      return EventType.EvtRealtimeIncomingTransferRejected;
    case 299:
    case 'realtime.incoming_transfer.completed':
      return EventType.EvtRealtimeIncomingTransferCompleted;
    case 331:
    case 'interest.payout.completed':
      return EventType.EvtInterestPayoutCompleted;
    case 1000:
    case 'bank_account.daily_count':
      return EventType.EvtBankAccountDailyCount;
    case 1001:
    case 'identity.daily_count':
      return EventType.EvtEntityDailyCount;
    case 1002:
    case 'loan.active_daily_count':
      return EventType.EvtActiveLoanDailyCount;
    case 1003:
    case 'bank_account.180day_active_daily_count':
      return EventType.Evt180DayActiveBankAccountCount;
    case 1004:
    case 'identity.180day_active_daily_count':
      return EventType.Evt180DayActiveEntityCount;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return EventType.UNRECOGNIZED;
  }
};
