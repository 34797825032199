import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@column/column-ui-kit';
import { ModalBase } from './Base';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore, ModalType } from '~/stores/Modal';
import { useSessionStore } from '~/stores/Session';
import { CodeInput, NotificationList } from '~/components';
import { Headline, Paragraph, Hint } from '~/styles';
import { UserRepository } from '~/repositories';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

export const Wrapper = styled(ModalBase)`
  --modal-width: 244px;
  padding: 24px;
`;

export const IconCircle = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin: 0 auto 24px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};

  svg {
    --icon-size: 30px;
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

export const Text = styled.div`
  display: grid;
  grid-gap: 8px;
  margin-bottom: 24px;
  text-align: center;
`;

const StyledHint = styled(Hint)`
  text-align: center;
  margin-top: 16px;
`;

const Resend = styled.span`
  display: inline-block;
  cursor: pointer;
  color: ${({ theme }) => theme.primary.background};
`;

export const ModalAuthenticate: React.FC = () => {
  const { currentUser, authRequired, phoneNumber, setPhoneNumber, signIn } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const closeModal = useModalStore((state) => state.closeModal);
  const modalTypes: ModalType[] = ['Authenticate'];

  const [code, setCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const { handleReCaptcha } = reCaptchaCheck();

  const handleResend = (event: React.MouseEvent) => {
    event.preventDefault();

    handleReCaptcha(
      (reCaptchaToken: string) => {
        UserRepository.resendMfa(reCaptchaToken)
          .then(() => {
            log({
              name: 'User MFA resent',
              context: {
                phoneNumber,
              },
            });

            addSuccessNotification({
              content: 'Code resent',
              display: 'popup',
            });
          })
          .catch((error) => {
            addDangerNotification({
              content: error.message,
              display: 'popup',
            });
          });
      },
      (err) => {
        addDangerNotification({
          content: err.message,
          display: 'popup',
        });
      }
    );
  };

  const handleSubmit = () => {
    if (!currentUser) {
      return;
    }

    setLoading(true);

    handleReCaptcha(
      (reCaptchaToken) =>
        currentUser
          .verifyMfa(code, reCaptchaToken)
          .then(() => {
            if (authRequired()) {
              setPhoneNumber(undefined);
              signIn()
                .then(() => {
                  closeModal();
                  setLoading(false);
                })
                .catch((error) => {
                  addDangerNotification({
                    content: error.message,
                    display: 'popup',
                  });
                });
              return;
            }
            log({
              name: 'User enabled MFA',
              context: currentUser,
            });
            addSuccessNotification({
              content: 'Two-factor authentication enabled',
              display: 'page',
            });
            closeModal();
            setCode('');
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            addDangerNotification({
              content: error.message,
              display: 'popup',
            });
          }),
      (err) => {
        addDangerNotification({
          content: err.message,
          display: 'popup',
        });
      }
    );
  };

  useEffect(() => {
    if (code && code.length === 6) {
      handleSubmit();
    }
  }, [code]);

  return (
    <Wrapper modalTypes={modalTypes}>
      <IconCircle>
        <Icon.Phone />
      </IconCircle>
      <Text>
        <Headline size="small">Authenticate</Headline>
        <Paragraph>Please enter the 6-digit code we have sent to your phone.</Paragraph>
      </Text>
      <NotificationList display="popup" />
      <CodeInput
        placeholder="######"
        type="number"
        value={code}
        onChange={(value: string) => setCode(value)}
        isLoading={loading}
      />
      <StyledHint>
        Haven’t received the code? <Resend onClick={handleResend}>Resend</Resend>
      </StyledHint>
    </Wrapper>
  );
};
