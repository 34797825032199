import React from 'react';
import styled from 'styled-components';
import { ROUTE } from '~/app/routes';
import { Notification } from '~/components';
import { UnderlineLink } from '~/elements';
import { VerificationStatus } from '~/repositories';
import { useSessionStore } from '~/stores/Session';

interface EntityStatusProps {
  status?: VerificationStatus;
}

const Wrapper = styled.div`
  margin-top: 16px;
`;

export const EntityStatus: React.FC<EntityStatusProps> = ({ status }) => {
  const currentPlatform = useSessionStore((s) => s.currentPlatform);

  if (status === 'VERIFIED') {
    if (currentPlatform?.isLiveEnabled) {
      return (
        <Wrapper>
          <Notification color="success" variant="light" withClose={false}>
            You’ve been approved! You can now turn sandbox off and move real money.
          </Notification>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <Notification color="info" variant="light" withClose={false}>
          Your KYC has been approved. We'll be in touch shortly once you can start moving money.
        </Notification>
      </Wrapper>
    );
  }
  if (status === 'MANUAL_REVIEW') {
    return (
      <Wrapper>
        <Notification color="info" variant="light" withClose={false}>
          Our system has flagged that we need to manually review your request. We'll verify this as soon as possible,
          generally within 24 hours.
        </Notification>
      </Wrapper>
    );
  }
  if (status === 'PENDING') {
    return (
      <Wrapper>
        <Notification color="info" variant="light" withClose={false}>
          Your KYC approval is pending. We will email you with updates on your account approval status.
        </Notification>
      </Wrapper>
    );
  }
  if (status === 'DENIED') {
    return (
      <Wrapper>
        <Notification color="danger" variant="light" withClose={false}>
          Unfortunately, this registration was declined. Please{' '}
          <UnderlineLink to={ROUTE.CONTACT_FORM} withoutArrow>
            contact us
          </UnderlineLink>{' '}
          if you have any questions.
        </Notification>
      </Wrapper>
    );
  }
  return null;
};
