import { Tooltip, Textarea, Checkbox, Button } from '@column/column-ui-kit';
import styled, { css } from 'styled-components';

export const PlatformInfoHeader = styled.div<{ smallMargin?: boolean }>`
  display: grid;
  grid-gap: 8px;
  margin-bottom: 64px;

  ${({ smallMargin }) =>
    smallMargin &&
    css`
      margin-bottom: 32px;
    `}
`;

export const PlatformInfoHeaderButton = styled(Button)`
  margin-top: 12px;
`;

export const PlatformInfoDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.secondary.background};
  max-width: 560px;

  a {
    color: ${({ theme }) => theme.foreground};
  }

  strong {
    font-weight: 500;
    color: ${({ theme }) => theme.foreground};
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

export const PlatformInfoLine = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  margin: 36px 0;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(200)};
`;

export const PlatformInfoFormColumns = styled.div`
  display: grid;
  grid-template-columns: clamp(240px, 40%, 400px) auto;
  grid-gap: 44px;
  align-items: start;
`;

export const PlatformInfoFormLabel = styled.div`
  color: ${({ theme }) => theme.secondary.background};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  small {
    font-size: inherit;
    font-weight: 400;
    color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  }
`;

export const PlatformInfoFormLabelTooltip = styled(Tooltip)<{ largeMargin?: boolean }>`
  display: inline-block;
  vertical-align: top;
  margin: 2px 0 2px 2px;

  ${({ largeMargin }) =>
    largeMargin &&
    css`
      margin: 4px 0 4px 4px;
    `}

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(700)};
    --icon-size: 16px;
  }

  &:hover {
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(900)};
    }
  }
`;

export const PlatformInfoSectionHeader = styled.div`
  margin: 64px 0;
`;

export const PlatformInfoFormContent = styled.div<{ smallGap?: boolean }>`
  display: grid;
  grid-gap: 24px;

  ${({ smallGap }) =>
    smallGap &&
    css`
      grid-gap: 16px;
    `}
`;

export const PlatformInfoFormElement = styled.div`
  display: grid;
  grid-gap: 8px;
  text-align: left;
`;

export const PlatformInfoFormLabelInline = styled.div`
  color: ${({ theme }) => theme.foreground};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  small {
    font-size: inherit;
    font-weight: 400;
    color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  }
`;

export const PlatformInfoFormTextarea = styled(Textarea)`
  min-height: 100px;
`;

export const PlatformInfoCheckbox = styled(Checkbox)`
  margin: 1px 0;
`;

export const PlatformInfoCheckboxElement = styled.div`
  display: grid;
  grid-template-columns: 18px auto;
  grid-gap: 8px;
  align-items: start;
  font-weight: 500;
`;
