import React, { useRef, FC } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface IntlWireIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const IntlWireIcon: FC<IntlWireIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={20}>
        <path
          d="M10 17.25C14.0041 17.25 17.25 14.0041 17.25 10C17.25 5.99594 14.0041 2.75 10 2.75C5.99594 2.75 2.75 5.99594 2.75 10C2.75 14.0041 5.99594 17.25 10 17.25Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path
          d="M10 17.25C11.3807 17.25 12.5 14.0041 12.5 10C12.5 5.99594 11.3807 2.75 10 2.75C8.61929 2.75 7.5 5.99594 7.5 10C7.5 14.0041 8.61929 17.25 10 17.25Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
        <path d="M2.75 10H17.25" stroke="currentColor" strokeWidth="1.5" />
      </IconBase>
    </Wrapper>
  );
};
