import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Icon, Dropdown, Chip, Input, ButtonInner, DropdownEntryText } from '@column/column-ui-kit';
import chroma from 'chroma-js';
import isEqual from 'lodash.isequal';
import { shallow } from 'zustand/shallow';
import { TransferRepository, transfers, TransferListResponse, transferStatusTooltips } from '~/repositories';
import {
  EmptyState,
  TableColumn,
  PopoverFilterAmount,
  PopoverFilterAmountData,
  PopoverFilterTransferTypeData,
  PopoverFilterTransferType,
  PopoverFilterTransferStatus,
  PopoverFilterTransferDate,
  PopoverFilterTransferDateData,
  PopoverFilterWrapper,
  PageHeader,
} from '~/components';
import { ROUTE } from '~/app/routes';
import { useModalStore } from '~/stores/Modal';
import {
  checkIfRestrictedWrite,
  downloadBlob,
  formatNumber,
  formatNumberText,
  formatString,
  getDateFormat,
  isValidDate,
} from '~/util';
import { FormElement, FormLabel, Inner, Paragraph } from '~/styles';
import { useSessionStore } from '~/stores/Session';
import { AlertData, useAlertStore } from '~/stores/Alert';
import { useNotificationStore } from '~/stores/Notification';
import { IconTransfers } from '~/elements';
import { useBankAccounts } from '~/hooks/useBankAccounts';
import { useQueryParams } from '~/hooks';
import { OverviewTable, OverviewTableRefProps } from '~/components/OverviewTable';
import { Search } from '~/components/Search';
import { TransferFilterType, useCheckTransferId } from '~/hooks/useTransfers';
import { ButtonGroupDropdown } from '~/components/ButtonGroupDropdown';

export interface TransferLocationState {
  trigger?: 'delayedFetch';
}

export const TransferAmount = styled.div<{ $isAdding?: boolean }>`
  color: ${({ theme, $isAdding }) =>
    $isAdding === true
      ? chroma(theme.success.background)
          .darken(theme.id !== 'dark' ? 0.75 : 0)
          .hex()
      : theme.foreground};

  ${({ $isAdding }) =>
    $isAdding === false &&
    css`
      position: relative;

      &:before {
        content: '-';
        position: absolute;
        right: 100%;
        display: block;
        top: -1px;
        font: inherit;
        line-height: inherit;
        z-index: 10;
      }
    `}
`;

const Note = styled.div`
  line-height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const Description = styled.div`
  display: flex;
  align-items: baseline;
  grid-gap: 8px;

  ${Note} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ResetFilter = styled(Button)<{ $isHidden?: boolean }>`
  padding-left: 0;
  padding-right: 0;
  margin-left: 4px;
  margin-right: 8px;

  transition: opacity 0.2s;
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : 1)};
  pointer-events: ${({ $isHidden }) => ($isHidden ? 'none' : 'auto')};
`;

export const FilterLabel = styled.div`
  align-self: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  margin-right: 8px;
`;

const ExportWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  flex: auto;
`;

const Fields = styled.div`
  margin-top: 12px;
  display: grid;
  grid-gap: 8px;
`;

const FileNameElement = styled(FormElement)`
  margin-top: 12px;
  text-align: left;
`;

const FileTypeLabel = styled.span`
  position: absolute;
  right: 12px;
  top: 8px;
  line-height: 24px;
  pointer-events: none;
  font-size: 14px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const FilterDropdown = styled(Dropdown)<{ $isFilterActive?: boolean }>`
  button {
    --button-scale-pressed: 1;
    padding: 8px 10px 8px 12px;

    &::after {
      box-shadow:
        0 -1px 1px rgba(255, 255, 255, 0.75) inset,
        ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
          ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
          ${({ theme }) => theme.style.buttonSecondaryBorderColor},
        0 1px 1px rgba(0, 0, 0, 0.075),
        0 1px 4px 0 transparent inset;

      ${({ theme }) =>
        theme.id !== 'default' &&
        css`
          background-color: transparent;
          box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(100)};

          &:hover {
            background-color: ${({}) => theme.secondary.blendToBackground(25)};
            box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(150)};
          }
        `}

      ${({ $isFilterActive }) =>
        $isFilterActive &&
        css`
          box-shadow:
            ${({ theme }) => theme.style.buttonSecondaryBorderProperties}
              ${({ theme }) => theme.style.buttonSecondaryBorderWidth}
              ${({ theme }) => theme.style.buttonSecondaryBorderColor},
            0 0 0 var(--button-outline, 0px)
              var(--button-outline-color, ${({ theme }) => theme.style.buttonFocussedOutlineColor}),
            ${({ theme }) => theme.style.buttonSecondaryShadow};
        `}
    }

    &:hover {
      &::after {
        box-shadow:
          0 -1px 1px rgba(255, 255, 255, 0.75) inset,
          ${({ theme }) => theme.style.buttonSecondaryHoveredBorderProperties}
            ${({ theme }) => theme.style.buttonSecondaryHoveredBorderWidth}
            ${({ theme }) => theme.secondary.blendToBackground(1100, 150)},
          0 1px 1px rgba(0, 0, 0, 0),
          0 1px 4px 0 ${({ theme }) => theme.secondary.blendToBackground(120)} inset;

        ${({ theme }) =>
          theme.id !== 'default' &&
          css`
            background-color: ${({}) => theme.secondary.blendToBackground(25)};
            box-shadow: inset 0 0 0 1px ${theme.secondary.blendToBackground(150)};
          `}
      }
    }
  }

  ${ButtonInner} {
    transition: 0.2s ease;
    color: ${({ theme, $isFilterActive }) => ($isFilterActive ? theme.primary.background : 'inherit')};
  }

  &:after {
    content: '';
    border-radius: ${({ theme }) => theme.style.buttonSmallBorderRadius};
    z-index: 4;
    position: absolute;
    inset: 0;
    pointer-events: none;
    box-shadow: inset 0 0 0 1px
      ${({ $isFilterActive, theme }) => ($isFilterActive ? theme.primary.background : 'transparent')};
    transition: box-shadow 0.15s;
  }

  ${DropdownEntryText} {
    font-weight: 600;
  }
`;

interface PageTransferExportModalContentProps {
  text: string;
}

export const PageTransferExportModalContent: React.FC<PageTransferExportModalContentProps> = ({ text }) => {
  const { alertData, updateAlertData } = useAlertStore();

  return (
    <>
      <Paragraph>{text}</Paragraph>
      <Fields>
        <FileNameElement fullWidth>
          <FormLabel>File type</FormLabel>
          <Dropdown
            active="csv"
            variant="muted"
            fullWidth
            options={[
              {
                label: '.csv (Comma-separated values)',
                value: 'csv',
              },
              {
                label: '.json (JavaScript Object Notation)',
                value: 'json',
                isDisabled: true,
              },
              {
                label: '.txt (BAI2 format)',
                value: 'txt',
                isDisabled: true,
              },
            ]}
          />
        </FileNameElement>
        <FileNameElement fullWidth>
          <FormLabel>
            Custom file name <small>(optional)</small>
          </FormLabel>
          <Input
            value={alertData?.customFileName ?? ''}
            onChange={(value) => updateAlertData({ customFileName: value })}
            placeholder={`transfers-${getDateFormat(new Date())}`}
          >
            <FileTypeLabel>.csv</FileTypeLabel>
          </Input>
        </FileNameElement>
      </Fields>
    </>
  );
};

export const transferTableColumns: TableColumn[] = [
  {
    Header: 'Amount / Description',
    accessor: 'description',
    percent: 34,
    Cell: (current) => (
      <Description>
        {current.value[0]}
        {current.value[1] && <Note>{current.value[1]}</Note>}
      </Description>
    ),
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: (current) => <Chip>{current.value}</Chip>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (current) => (
      <Chip
        tooltip={
          transferStatusTooltips.has(current.value)
            ? {
                content: transferStatusTooltips.get(current.value),
                delay: 200,
                triggerClick: false,
              }
            : undefined
        }
        type={
          current.value.toLowerCase() === 'hold'
            ? 'info'
            : ['rejected', 'canceled', 'cancelled', 'returned', 'stopped'].includes(current.value.toLowerCase())
              ? 'danger'
              : ['completed', 'settled', 'submitted'].includes(current.value.toLowerCase())
                ? 'success'
                : 'default'
        }
      >
        {formatString(current.value)}
      </Chip>
    ),
  },
  {
    Header: 'Direction',
    accessor: 'incoming',
    Cell: (current) => (
      <Chip
        icon={
          current.value === 'Incoming' ? (
            <Icon.CircleArrowDown />
          ) : current.value === 'Outgoing' ? (
            <Icon.CircleArrowUp />
          ) : current.value === 'Deposit' ? (
            <Icon.CircleArrowDown />
          ) : current.value === 'Issue' ? (
            <Icon.CircleArrowUp />
          ) : current.value === 'Internal' ? (
            <Icon.Swap />
          ) : undefined
        }
      >
        {current.value}
      </Chip>
    ),
  },
  {
    Header: 'Created',
    Cell: (current) => (isValidDate(current.value) ? current.value.toLocaleDateString('en-US') : '-'),
    accessor: 'created',
    sortType: 'datetime',
    percent: 10,
  },
];

export const PageTransfers: React.FC = () => {
  const { response: bankAccounts, setQueryParams: searchBankAccounts } = useBankAccounts();
  const { isSandbox, currentPermission } = useSessionStore();
  const openModal = useModalStore((state) => state.openModal);
  const { openAlert, closeAlert, alertData, updateAlertData } = useAlertStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { addDangerNotification, addInfoNotification } = useNotificationStore();
  const overviewTableRef = useRef<OverviewTableRefProps>(null);

  const mountedRef = useRef<boolean>(false);

  const { queryParams, getQueryObject } = useQueryParams<TransferFilterType>();

  const locationState = location.state as TransferLocationState;

  const [totalResultsCount, setTotalResultsCount] = useState<number>(0);

  const handleResponse = useCallback((response?: TransferListResponse) => {
    setTotalResultsCount(response?.totalResultsCount ?? 0);
  }, []);

  const [filterByIdTooltipContent, setFilterByIdTooltipContent] = useState<string>('');
  const [filterByIdValue, setFilterByIdValue] = useState<string>('');

  useEffect(() => {
    if (!queryParams) {
      return undefined;
    }

    const { counterpartyId, accountNumberId } = queryParams;

    setFilterByIdValue(counterpartyId || accountNumberId || '');
  }, []);

  const handleFilterByIdValueChange = useCallback((value: string) => {
    setFilterByIdValue(value);
    setFilterByIdTooltipContent('');
  }, []);

  const [bankAccountIdFilterData, setBankAccountIdFilterData] = useState<string | undefined>(undefined);

  const [amountFilterData, setAmountFilterData] = useState<PopoverFilterAmountData | undefined>({});
  const isAmountFilterActive = useMemo(() => {
    if (
      (amountFilterData?.amount && amountFilterData?.amount > 0) ||
      (amountFilterData?.amountGte && amountFilterData?.amountGte > 0) ||
      (amountFilterData?.amountLte && amountFilterData?.amountLte > 0)
    ) {
      return true;
    }
    return false;
  }, [amountFilterData]);

  const [transferTypeFilterData, setTransferTypeFilterData] = useState<PopoverFilterTransferTypeData>({});
  const isTransferTypeFilterActive = useMemo(() => {
    if (
      (transferTypeFilterData && transferTypeFilterData.type && transferTypeFilterData.type.length > 0) ||
      (transferTypeFilterData && typeof transferTypeFilterData.isIncoming !== 'undefined')
    ) {
      return true;
    }
    return false;
  }, [transferTypeFilterData]);

  const [transferStatusFilterData, setTransferStatusFilterData] = useState<string[] | undefined>([]);

  const [transferDateFilterData, setTransferDateFilterData] = useState<Partial<PopoverFilterTransferDateData>>({});
  const isDateFilterActive = useMemo(() => {
    if (transferDateFilterData?.createdAtGte && transferDateFilterData?.createdAtLt) {
      return true;
    }
    return false;
  }, [transferDateFilterData]);

  const isFilterActive = useMemo(() => {
    return (
      filterByIdValue ||
      bankAccountIdFilterData ||
      isAmountFilterActive ||
      isTransferTypeFilterActive ||
      (transferStatusFilterData && transferStatusFilterData.length > 0) ||
      isDateFilterActive
    );
  }, [
    filterByIdValue,
    bankAccountIdFilterData,
    isAmountFilterActive,
    transferTypeFilterData,
    transferStatusFilterData,
    isDateFilterActive,
  ]);

  const checkTransferId = useCheckTransferId({
    onSuccess: (id) => {
      const url = transfers.find((transfer) => transfer.idString && id.startsWith(transfer.idString))?.id;

      navigate(`${ROUTE.TRANSFERS}/edit/${url}/${id}`);
    },
    onError: (message) => {
      setFilterByIdTooltipContent(message);
    },
  });

  const handleFilterById = useCallback(
    (value: string) => {
      if (value.length > 0 && transfers.some((transfer) => transfer.idString && value.startsWith(transfer.idString))) {
        checkTransferId(value);

        return;
      }

      if (value.startsWith('cpty_')) {
        overviewTableRef.current?.setQueryParams({
          counterpartyId: value,
          bankAccountId: undefined,
          accountNumberId: undefined,
        });

        return;
      }

      if (value.startsWith('bacc_')) {
        overviewTableRef.current?.setQueryParams({
          bankAccountId: value,
          counterpartyId: undefined,
          accountNumberId: undefined,
        });

        // setBankAccountIdFilterData(value);

        return;
      }

      if (value.startsWith('acno_')) {
        overviewTableRef.current?.setQueryParams({
          accountNumberId: value,
          counterpartyId: undefined,
          bankAccountId: undefined,
        });

        return;
      }

      if (value && value.length > 0) {
        setFilterByIdTooltipContent('Not a supported ID. Enter a Bank Account ID, Counterparty ID, or Transfer ID.');
      } else {
        setTimeout(() => overviewTableRef.current?.reset(), 0);
        setFilterByIdTooltipContent('');
      }
    },
    [overviewTableRef]
  );

  useLayoutEffect(() => {
    if (locationState?.trigger === 'delayedFetch' && overviewTableRef.current) {
      window.history.replaceState({}, '');
      setTimeout(() => {
        overviewTableRef.current?.fetch();
        setTimeout(() => {
          overviewTableRef.current?.fetch();
        }, 1000);
      }, 500);
    }
  }, [locationState?.trigger, overviewTableRef]);

  const [fetchFilters, setFetchFilters] = useState<TransferFilterType | null>({});

  useEffect(() => {
    const entries = Object.fromEntries(
      Object.entries({
        ...queryParams,
        ...amountFilterData,
        bankAccountId:
          bankAccountIdFilterData && bankAccountIdFilterData.length > 0 ? bankAccountIdFilterData : undefined,
        isIncoming: transferTypeFilterData.isIncoming,
        ...transferDateFilterData,
        type:
          transferTypeFilterData.type && transferTypeFilterData.type?.length > 0
            ? transferTypeFilterData.type?.join(',')
            : undefined,
        status:
          transferStatusFilterData && transferStatusFilterData?.length > 0
            ? transferStatusFilterData?.join(',')
            : undefined,
      }).filter(([_, value]) => {
        if (typeof value === 'object' && Object.keys(value).length === 0) {
          return false;
        }
        if (Array.isArray(value) && value.length < 1) {
          return false;
        }
        return true;
      })
    );

    setFetchFilters((s) => (isEqual(entries, s) ? s : entries));
  }, [
    queryParams,
    amountFilterData,
    transferTypeFilterData,
    transferStatusFilterData,
    transferDateFilterData,
    bankAccountIdFilterData,
  ]);

  useLayoutEffect(() => {
    if (typeof queryParams === 'undefined') {
      setFetchFilters(null);
      return;
    }

    const { amount, amountGte, amountLte, isIncoming, type, status, createdAtGte, createdAtLt, bankAccountId } =
      queryParams;

    setBankAccountIdFilterData(bankAccountId && bankAccountId.length > 0 ? bankAccountId : undefined);

    setAmountFilterData({
      amount: amount ? Number(amount) : undefined,
      amountGte: amountGte ? Number(amountGte) : undefined,
      amountLte: amountLte ? Number(amountLte) : undefined,
    });

    setTransferTypeFilterData({
      isIncoming: typeof isIncoming !== 'undefined' ? String(isIncoming) === 'true' : undefined,
      type: type ? (type.split(',') as any) : [],
    });

    setTransferStatusFilterData(status ? status.split(',') : []);

    setTransferDateFilterData({
      createdAtGte: createdAtGte && createdAtGte.length > 0 ? createdAtGte : undefined,
      createdAtLt: createdAtLt && createdAtLt.length > 0 ? createdAtLt : undefined,
    });

    setFetchFilters(queryParams);
  }, []);

  const handleFilterReset = useCallback(() => {
    setBankAccountIdFilterData('');
    setAmountFilterData({});
    setTransferTypeFilterData({});
    setTransferStatusFilterData([]);
    setTransferDateFilterData({});

    setFilterByIdValue('');
    setFilterByIdTooltipContent('');

    setFetchFilters(null);
  }, [queryParams]);

  const [exportAnimate, setExportAnimate] = useState<boolean>(false);

  const handleExport = (dataAlert?: AlertData) => {
    if (alertData?.submitLoading) {
      return;
    }

    updateAlertData({ submitLoading: true });
    setExportAnimate(true);

    const params = { ...getQueryObject };

    if (params?.page) {
      delete params.page;
    }

    if (params?.limit) {
      delete params.limit;
    }

    if (params?.startingAfter) {
      delete params.startingAfter;
    }

    if (params?.endingBefore) {
      delete params.endingBefore;
    }

    TransferRepository.export(params)
      .then((response) => {
        downloadBlob(
          response,
          `${
            dataAlert?.customFileName && dataAlert.customFileName.length > 0
              ? dataAlert.customFileName
              : `transfers-${getDateFormat(new Date())}`
          }.csv`
        );
      })
      .then(() => {
        closeAlert();
      })
      .catch((e) =>
        addDangerNotification({
          content: e.message,
          display: 'popup',
        })
      )
      .finally(() => {
        updateAlertData({ submitLoading: false });
      });
  };

  const handleExportClick = () => {
    let text = `Do you want to export & download the .csv file for ${formatNumberText(totalResultsCount)} transfers?`;

    if (!isFilterActive) {
      text = `Are you sure you want to export & download the .csv file for all transfers (${formatNumberText(
        totalResultsCount
      )}) without any filters applied?`;
    }

    if (totalResultsCount > 1000) {
      text = `Are you sure to export & download the .csv file for ${formatNumberText(
        totalResultsCount
      )} transfers? This may take a while.`;
    }

    openAlert(
      {
        headline: 'Export',
        text: <PageTransferExportModalContent text={text} />,
        submitText: 'Download',
        callback: handleExport,
        type: 'info',
        icon: <Icon.AnimationDownload running={exportAnimate} onDone={() => setExportAnimate(false)} />,
      },
      {
        submitStay: true,
        closeTimeoutMs: 500,
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      mountedRef.current = true;
    }, 2000);
  }, []);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => [state.isSandbox, state.currentUser?.defaultPlatformId],
        () => {
          if (!mountedRef.current) {
            return;
          }

          handleFilterReset();
        },
        {
          equalityFn: shallow,
        }
      ),
    [mountedRef.current]
  );

  const platformRole = useSessionStore((state) => state.currentPermissionName);

  return (
    <>
      <PageHeader text="Transfers" border={false}>
        {isSandbox && (
          <Button
            onClick={() =>
              openModal('SandboxTransfer', {
                callback: () => {
                  if (overviewTableRef.current) {
                    setTimeout(() => overviewTableRef.current?.fetch(), 200);
                  }
                },
              })
            }
            icon={<Icon.Sandbox />}
            size="small"
          >
            Simulate
          </Button>
        )}
        <ButtonGroupDropdown
          buttons={[
            {
              label: 'Create Transfer',
              icon: <Icon.Plus />,
              onClick: () =>
                navigate(`${ROUTE.TRANSFERS}/edit/${platformRole === 'Check Depositor' ? 'check' : 'ach'}`),
            },
          ]}
          options={transfers
            .filter(
              (v) =>
                !['intl-wire', 'realtime'].includes(v.id) &&
                !checkIfRestrictedWrite(currentPermission, v.permissionName)
            )
            .map((transfer) => {
              const IconElement = IconTransfers[transfer.icon];
              return {
                icon: <IconElement />,
                label: transfer.label,
                onClick: () => navigate(`${ROUTE.TRANSFERS}/edit/${transfer.id}`),
              };
            })}
        />
      </PageHeader>

      <OverviewTable
        tableId="transfers"
        ref={overviewTableRef}
        fetchFilter={fetchFilters}
        columns={transferTableColumns}
        onResponse={handleResponse}
        onLimitChange={() => handleFilterReset()}
        empty={
          <EmptyState headline="No transfers found" text="Add your first ACH, Wire or Book Transfer to get started.">
            <Button onClick={() => navigate(`${ROUTE.TRANSFERS}/edit/ach`)} size="small" icon={<Icon.Plus />}>
              ACH
            </Button>
            <Button onClick={() => navigate(`${ROUTE.TRANSFERS}/edit/wire`)} size="small" icon={<Icon.Plus />}>
              Wire
            </Button>
            <Button onClick={() => navigate(`${ROUTE.TRANSFERS}/edit/book`)} size="small" icon={<Icon.Plus />}>
              Book
            </Button>
            <Button onClick={() => navigate(`${ROUTE.TRANSFERS}/checks/issue`)} size="small" icon={<Icon.Plus />}>
              Check
            </Button>
          </EmptyState>
        }
        action={
          <>
            <FilterDropdown
              options={
                bankAccounts?.bankAccounts.map((bankAccount) => ({
                  label: `${bankAccount.displayName || bankAccount.description}`,
                  suffix: `${formatNumber(bankAccount.balances.availableAmount)}`,
                  small: bankAccount.id,
                  value: bankAccount.id,
                })) ?? []
              }
              size="small"
              active={bankAccountIdFilterData}
              $isFilterActive={bankAccounts?.bankAccounts.findIndex((b) => b.id === bankAccountIdFilterData) !== -1}
              label={
                bankAccounts?.bankAccounts.find((b) => b.id === bankAccountIdFilterData)?.displayName ||
                bankAccounts?.bankAccounts.find((b) => b.id === bankAccountIdFilterData)?.description ||
                'Account'
              }
              search
              searchLabel="Search for description"
              onSearchChange={(description: string) => searchBankAccounts({ description })}
              onChange={setBankAccountIdFilterData}
              maxWidth="480px"
            />
            <PopoverFilterWrapper label="Amount" onSubmit={setAmountFilterData} isActive={isAmountFilterActive}>
              <PopoverFilterAmount data={amountFilterData ?? {}} />
            </PopoverFilterWrapper>
            <PopoverFilterWrapper
              label="Type"
              onSubmit={setTransferTypeFilterData}
              isActive={isTransferTypeFilterActive}
            >
              <PopoverFilterTransferType data={transferTypeFilterData ?? {}} />
            </PopoverFilterWrapper>
            <PopoverFilterWrapper
              label="Status"
              onSubmit={setTransferStatusFilterData}
              isActive={transferStatusFilterData && transferStatusFilterData.length > 0}
            >
              <PopoverFilterTransferStatus data={transferStatusFilterData ?? []} />
            </PopoverFilterWrapper>
            <PopoverFilterWrapper label="Date" onSubmit={setTransferDateFilterData} isActive={isDateFilterActive}>
              <PopoverFilterTransferDate data={transferDateFilterData ?? {}} />
            </PopoverFilterWrapper>

            <Search
              value={filterByIdValue}
              onFilterSubmit={handleFilterById}
              placeholder="Filter by ID"
              tooltipContent={filterByIdTooltipContent}
              onValueChange={handleFilterByIdValueChange}
            />

            {isFilterActive && (
              <ResetFilter
                size="small"
                variant="subtle-primary"
                icon={<Icon.Reset />}
                iconRight
                onClick={handleFilterReset}
              >
                Reset filters
              </ResetFilter>
            )}

            <ExportWrapper>
              <Button
                icon={<Icon.AnimationDownload running={exportAnimate} onDone={() => setExportAnimate(false)} />}
                size="small"
                variant="secondary"
                onClick={handleExportClick}
              >
                Export
              </Button>
            </ExportWrapper>
          </>
        }
        rowClick={(row) => {
          if (row.original.type === 'Realtime') {
            addInfoNotification({
              content: 'Realtime transfer view will be available soon',
              display: 'page',
            });

            return;
          }

          navigate(
            `${ROUTE.TRANSFERS}/edit/${(row.original.type || 'ach')
              .toString()
              .toLowerCase()
              .replace('debit', '')
              .replace('credit', '')
              .replace('. ', '-')
              .trim()}/${row.original.id}`
          );
        }}
      />
    </>
  );
};
