import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@column/column-ui-kit';
import { useNavigate } from 'react-router-dom';

interface BreadcrumbEntry {
  label: ReactNode;
  path?: string;
}

export interface BreadcrumbProps {
  entries: BreadcrumbEntry[];
  className?: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Entry = styled.button<{ isLast: boolean; isLink: boolean }>`
  display: inline-block;
  outline: none;
  background: none;
  border: none;
  appearance: none;
  padding: 0;
  margin: 0;
  cursor: text;
  line-height: 16px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  transition: color 0.2s;
  max-width: 24ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ isLast }) =>
    isLast &&
    css`
      color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    `}

  ${({ isLink }) =>
    isLink &&
    css`
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.secondary.blendToBackground(800)};
      }
    `}

  svg {
    --icon-size: 16px;
    --icon-color: currentColor;
    display: inline-block;
    margin: 0 4px -3px 0;
  }
`;

const Divider = styled(Icon.ChevronRight)`
  --icon-size: 16px;
  --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

export const Breadcrumb: React.FC<BreadcrumbProps> = (props) => {
  const navigate = useNavigate();

  const handleClick = (entry: BreadcrumbEntry) => {
    if (entry.path) {
      navigate(entry.path);
    }
  };

  return (
    <Wrapper>
      {props.entries.map((entry: BreadcrumbEntry, index: number) => (
        <Wrapper key={index}>
          <Entry
            isLast={index === props.entries.length - 1}
            isLink={!!(entry.path && entry.path.length)}
            onClick={() => handleClick(entry)}
          >
            {entry.label}
          </Entry>
          {index !== props.entries.length - 1 && <Divider />}
        </Wrapper>
      ))}
    </Wrapper>
  );
};
