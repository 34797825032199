import React, { useRef, FC } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface ConnectIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const ConnectIcon: FC<ConnectIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={32}>
        <path d="M16 7V25" stroke="currentColor" strokeWidth="2" />
        <path
          d="M16 28.5C17.2426 28.5 18.25 27.4926 18.25 26.25C18.25 25.0074 17.2426 24 16 24C14.7574 24 13.75 25.0074 13.75 26.25C13.75 27.4926 14.7574 28.5 16 28.5Z"
          fill="currentColor"
        />
        <path
          d="M8 14.5C9.24264 14.5 10.25 13.4926 10.25 12.25C10.25 11.0074 9.24264 10 8 10C6.75736 10 5.75 11.0074 5.75 12.25C5.75 13.4926 6.75736 14.5 8 14.5Z"
          fill="currentColor"
        />
        <path d="M16 19.92L24 15V10" stroke="currentColor" strokeWidth="2" />
        <path d="M16 21.92L8 17V12" stroke="currentColor" strokeWidth="2" />
        <path d="M15.9984 2.5L13.3984 7H18.5984L15.9984 2.5Z" fill="currentColor" />
        <path d="M26 7H22V11H26V7Z" fill="currentColor" />
      </IconBase>
    </Wrapper>
  );
};
