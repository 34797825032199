import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { NumericFormat } from 'react-number-format';
import { currencies, Currency } from './currencies';

export const formatNumber = (cents?: number | string | null, currencyCode?: string) => {
  const value = typeof cents === 'string' ? parseInt(cents, 10) : cents === null ? 0 : cents;
  const currency = currencies.find((c) => c.code === (currencyCode ?? 'USD')) as Currency;

  return renderToStaticMarkup(
    <NumericFormat
      value={value ? value / currency.numberToBasic : 0}
      thousandSeparator
      decimalScale={currency.decimalDigits}
      fixedDecimalScale
      prefix={currency.symbolNative}
      displayType="text"
      renderText={(v) => v.toString()}
    />
  );
};

export const formatNumberText = (amount?: number | string) => {
  const value = typeof amount === 'string' ? parseInt(amount, 10) : amount;

  return renderToStaticMarkup(
    <NumericFormat
      value={value}
      thousandSeparator
      decimalScale={0}
      fixedDecimalScale
      displayType="text"
      renderText={(v) => v.toString()}
    />
  );
};
