import { DefaultTheme } from 'styled-components';
import { getThemeBase, Theme } from '@column/column-ui-kit';

const themeDarkColors = {
  foreground: '#FFFFFF',
  background: '#15171B',
  body: '#111317',
  primary: '#4575CD',
  secondary: '#73778C',
  success: '#44B48B',
  warning: '#EC8439',
  danger: '#D64260',

  black: '#000000',
  white: '#FFFFFF',
  gray: '#575A64',
  blue: '#1E4199',
  cyan: '#71D2F0',
  seafoam: '#44B48B',
  green: '#9CD95D',
  orange: '#EC8439',
  red: '#D64260',
  pink: '#AB4BB1',
  purple: '#845EDB',
};

export const themeDark: DefaultTheme = new Theme({
  name: 'Dark',
  ...themeDarkColors,
  style: {
    ...getThemeBase({
      raisedShadow:
        'inset 0 1px 0 rgba(255, 255, 255, 0.075), inset 0 2px 4px rgba(255, 255, 255, 0.025), 0 1px 2px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.25)',
      raisedShadowHovered:
        'inset 0 1px 0 rgba(255, 255, 255, 0.075), inset 0 2px 4px rgba(255, 255, 255, 0.025), 0 2px 3px rgba(0, 0, 0, 0.5), 0 3px 5px rgba(0, 0, 0, 0.25)',

      primaryBackgroundColor: 'Color: primary, background',
      primaryHoveredBackgroundColor: 'Color: primary, blendToForeground, 950',
      raisedBackgroundColor: 'Color: secondary, blendToBackground, 25',
      raisedHoveredBackgroundColor: 'Color: secondary, blendToBackground, 50',

      floatingShadow1ColorM: 'rgba(0, 0, 0, 0.1)',
      floatingShadow2ColorM: 'rgba(0, 0, 0, 0.05)',

      formElementBackgroundColor: 'Color: secondary, blendToBackground, 0',
      formElementHoveredBackgroundColor: 'Color: secondary, blendToBackground, 5',
      formElementFocusedBackgroundColor: 'Color: background',

      formElementBorderColor: 'Color: secondary, blendToBackground, 150',
      formElementHoveredBorderColor: 'Color: secondary, blendToBackground, 200',

      depressedShadow: 'inset 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 3px 4px rgba(0, 0, 0, 0.25)',
    }),
    buttonPrimaryShadow:
      'inset 0 0 0 1px rgba(255, 255, 255, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 2px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.25)',
    buttonPrimaryHoveredShadow:
      'inset 0 0 0 1px rgba(255, 255, 255, 0.15), inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 2px 3px rgba(0, 0, 0, 0.5), 0 3px 5px rgba(0, 0, 0, 0.25)',
    buttonSecondaryBackgroundInset: '0',
    buttonSecondaryBorderProperties: 'inset 0 0 0',
    buttonSecondaryHoveredBorderProperties: 'inset 0 0 0',
    buttonSecondaryBorderColor: 'Color: secondary, blendToBackground, 150',
    buttonSecondaryHoveredBorderColor: 'Color: secondary, blendToBackground, 200',

    segmentedControlBackgroundColor: 'transparent',
    segmentedControlOptionHoveredBackgroundColor: 'Color: secondary, blendToBackground, 25',
    segmentedControlBorderColor: 'Color: secondary, blendToBackground, 100',
    segmentedControlOptionActiveBackgroundColor: 'Color: secondary, blendToBackground, 1000, 150',
    segmentedControlOptionActiveShadow: '0 0 0 rgba(0, 0, 0, 0)',
    segmentedControlShadow: '0 0 0 rgba(0, 0, 0, 0)',

    toggleBackgroundColor: 'Color: secondary, blendToBackground, 1000, 250',
    toggleHoveredBackgroundColor: 'Color: secondary, blendToBackground, 1000, 150',
    toggleCheckedBackgroundColor: 'Color: primary, blendToBackground, 700',
    toggleCheckedHoveredBackgroundColor: 'Color: primary, blendToBackground, 800',
    toggleBorderColor: 'Color: secondary, blendToBackground, 1000, 150',
    toggleHoveredBorderColor: 'Color: secondary, blendToBackground, 300',
    toggleCheckedBorderColor: 'Color: primary, blendToBackground, 1150',
    toggleShadow: '0 0 0 rgba(0, 0, 0, 0)',

    dropdownBorderColor: 'Color: secondary, blendToBackground, 150',
  },
  colors: {},
});
