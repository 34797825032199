import React from 'react';
import { Button, Icon } from '@column/column-ui-kit';
import { useNavigate } from 'react-router-dom';
import { EmptyState, PageHeader } from '~/components';
import { ROUTE } from '~/app/routes';
import { ReportingTable } from '~/components/ReportingTable';

export const PageReporting: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageHeader text="Reporting" border={false}>
        <Button onClick={() => navigate(`${ROUTE.REPORTING}/schedule`)} size="small" icon={<Icon.Plus />}>
          Schedule Report
        </Button>
      </PageHeader>
      <ReportingTable
        tableId="reporting"
        filter={{ category: 'settlement' }}
        empty={
          <EmptyState headline="No reports found" text="Schedule your first report to get started.">
            <Button onClick={() => navigate(`${ROUTE.REPORTING}/schedule`)} size="small" icon={<Icon.Plus />}>
              Create Report
            </Button>
          </EmptyState>
        }
        onRowClick={(entry) => navigate(`${ROUTE.REPORTING}/${entry.id}`)}
        visibleFiles={['csv', 'json']}
      />
    </>
  );
};
