import React, { CSSProperties, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Icon, Tooltip } from '@column/column-ui-kit';
import { copyToClipboard } from '~/util';

export interface ApiKeyPreviewStyleProps {
  isEmpty: boolean;
}

export interface ApiKeyPreviewProps extends Partial<ApiKeyPreviewStyleProps> {
  className?: string;
  value?: string;
  placeholder?: string;
  style?: CSSProperties;
}

const StyledApiKeyPreview = styled.div<ApiKeyPreviewStyleProps>`
  color: ${({ theme }) => theme.secondary.background};
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  width: 132px;
  position: relative;

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      color: ${({ theme }) => theme.secondary.blendToBackground(400)};
    `}
`;

const Value = styled.div`
  width: 100%;
  overflow: hidden;
  mask-image: linear-gradient(90deg, black 30%, transparent 80%);
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
`;

export const ApiKeyPreview: React.FC<ApiKeyPreviewProps> = (props) => {
  const [isTooltipShow, setIsTooltipShow] = useState<boolean>(false);

  const styleProps: ApiKeyPreviewStyleProps = {
    isEmpty: !props.value,
  };

  const handleCopyClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    setIsTooltipShow(true);
    copyToClipboard(props.value ?? '');
    setTimeout(() => setIsTooltipShow(false), 2000);
  };

  return (
    <StyledApiKeyPreview style={props.style} className={props.className} {...styleProps}>
      <Value>{props.value || props.placeholder}</Value>
      <StyledTooltip content="Copied!" isOpen={isTooltipShow} offsetY={2}>
        <Button onClick={handleCopyClick} size="tiny" variant="secondary" icon={<Icon.CopyCode />} />
      </StyledTooltip>
    </StyledApiKeyPreview>
  );
};
