import React, { useRef, FC } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface ReloadIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const ReloadIcon: FC<ReloadIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={16}>
        <path
          d="M7.99892 2.66699C5.30975 2.6674 3.04153 4.66982 2.70767 7.33819C2.3738 10.0066 4.07875 12.5061 6.68492 13.169C9.2911 13.832 11.9832 12.451 12.9649 9.94743C13.9467 7.44387 12.911 4.60101 10.5489 3.31566"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5508 5.36515V3.00781H12.9081"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </IconBase>
    </Wrapper>
  );
};
