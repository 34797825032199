import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon, Dropdown, SegmentedControl } from '@column/column-ui-kit';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/github.css';
import { ModalBase } from './Base';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore, ModalType } from '~/stores/Modal';
import { useSessionStore } from '~/stores/Session';
import { NotificationList } from '~/components';
import { Headline } from '~/styles';
import { WebhookRepository, WebhookVerify, EventType } from '~/repositories';

hljs.registerLanguage('json', json);

const Wrapper = styled(ModalBase)`
  --modal-width: 740px;
  padding: 0;
`;

const Top = styled.div`
  display: grid;
  grid-gap: 20px;
  padding: 32px 32px 0 32px;
`;

const List = styled.div`
  display: flex;
  gap: 20px;
`;

const Check = styled(Icon.CircleCheck)`
  --icon-color: ${({ theme }) => theme.success.background};
`;

const Cross = styled(Icon.CircleCross)`
  --icon-color: ${({ theme }) => theme.danger.background};
`;

const Status = styled.div`
  display: flex;
  gap: 8px;
`;

const StatusLabel = styled.div`
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
`;

const Code = styled.div`
  padding: 24px 32px;
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-radius: 0 0 24px;
  margin-top: -1px;
  max-height: 560px;
  overflow-y: scroll;
  font-size: 13px;
  line-height: 1.6;

  pre {
    margin: 0;
    padding: 0;

    code.hljs {
      background: none;
      padding: 0;
      font-family: 'Roboto Mono', monospace;
    }
  }
`;

export const ModalWebhookResult: React.FC = () => {
  const { getModalData } = useModalStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const currentUser = useSessionStore((state) => state.currentUser);
  const modalTypes: ModalType[] = ['WebhookResult'];
  const [testEventType, setTestEventType] = useState<string>('');
  const [webhookResult, setWebhookResult] = useState<WebhookVerify | undefined>(getModalData()?.webhookResult);
  const [activeTab, setActiveTab] = useState<string>('request');

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.getModalData(),
        (modalData) => {
          setTestEventType(modalData?.testEventType ?? '');
          setWebhookResult(modalData?.webhookResult);
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  useEffect(() => {
    hljs.highlightAll();
  }, [webhookResult?.responseBody, activeTab]);

  const handleTestWebhook = () => {
    const modalData = getModalData();
    if (!currentUser || !modalData || !modalData?.id) {
      return;
    }
    WebhookRepository.verify(modalData.id, {
      eventType: testEventType as EventType,
    })
      .then((response) => {
        setWebhookResult(response);
        if (response.success) {
          addSuccessNotification({
            content: `${testEventType} successfully sent`,
            display: 'popup',
          });
          return;
        }
        addDangerNotification({
          content: `${response.responseCode} Error`,
          display: 'popup',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'popup',
        });
      });
  };

  return (
    <Wrapper modalTypes={modalTypes}>
      <Top>
        <Headline>Verify webhook</Headline>
        <div>
          <NotificationList display="popup" />
          <List>
            <Dropdown
              search
              active={testEventType ?? getModalData()?.testEventType}
              placeholder="Select event type"
              variant="muted"
              options={getModalData()?.testableEvents ?? []}
              onChange={(value: any) => setTestEventType(value)}
            />
            <Button onClick={handleTestWebhook} variant="secondary" icon={<Icon.Bell />}>
              Send
            </Button>
          </List>
        </div>
        <Status>
          {typeof webhookResult?.responseCode !== 'undefined' &&
          webhookResult.responseCode >= 200 &&
          webhookResult.responseCode < 300 ? (
            <Check />
          ) : (
            <Cross />
          )}
          <StatusLabel>
            {webhookResult?.responseCode}{' '}
            {typeof webhookResult?.responseCode !== 'undefined' &&
            webhookResult.responseCode >= 200 &&
            webhookResult.responseCode < 300
              ? 'Ok'
              : 'Error'}
          </StatusLabel>
        </Status>
        <SegmentedControl
          options={[
            { label: 'Request', value: 'request' },
            { label: 'Response', value: 'response' },
          ]}
          onOptionChange={setActiveTab}
          active={activeTab}
          line
        />
      </Top>
      <Code>
        <pre>
          <code className="language-json">
            {(activeTab === 'request' ? webhookResult?.requestBody : webhookResult?.responseBody) ?? ''}
          </code>
        </pre>
      </Code>
    </Wrapper>
  );
};
