import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

interface SectionHeaderProps {
  text: string;
  tooltip?: React.ReactNode;
  border?: boolean;
  isScrolled?: boolean;
  flush?: boolean;
  counter?: number;
  onClick?: () => void;
}

const Wrapper = styled.div<SectionHeaderProps>`
  padding: 16px 24px;
  display: flex;
  gap: 24px;
  align-items: center;
  position: relative;
  z-index: 1;

  ${({ border }) =>
    border &&
    css`
      border-top: 1px solid ${({ theme }) => theme.gray.blendToBackground(150)};
    `}

  ${({ flush }) =>
    flush &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const SectionTitle = styled.h2`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin: 0;
  flex: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Counter = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 14px;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.secondary.background};
  border: 1px solid ${({ theme }) => theme.gray.blendToBackground(150)};
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-end;
  max-height: 20px;
`;

export const SectionHeader: React.FC<PropsWithChildren<SectionHeaderProps>> = (props) => {
  return (
    <Wrapper {...props}>
      <SectionTitle>
        {props.counter && <Counter>{props.counter}</Counter>}
        {props.text}
        {props.tooltip}
      </SectionTitle>
      <Actions>{props.children}</Actions>
    </Wrapper>
  );
};
