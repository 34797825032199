import React, { useRef, FC } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface HelpIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const HelpIcon: FC<HelpIconProps> = ({ className }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      <IconBase viewportSize={20}>
        <path
          d="M16.3 5.93262C17.9 8.40762 17.8992 11.5943 16.3009 14.0685"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.9562 7.04363C14.5889 8.67635 14.5889 11.3235 12.9562 12.9562C11.3235 14.5889 8.67635 14.5889 7.04363 12.9562C5.41091 11.3235 5.41091 8.67635 7.04363 7.04363C8.67635 5.41091 11.3235 5.41091 12.9562 7.04363"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9341 6.29257L14.0874 3.67674C14.4007 3.2959 14.9741 3.2684 15.3232 3.61674L16.3841 4.67757C16.7332 5.02674 16.7049 5.60007 16.3241 5.9134L13.7082 8.06674"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06585 13.7075L5.91251 16.3233C5.59918 16.7042 5.02585 16.7317 4.67668 16.3833L3.61585 15.3225C3.26668 14.9733 3.29501 14.4 3.67585 14.0867L6.29168 11.9333"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.29243 8.06585L3.67576 5.91251C3.29493 5.59918 3.26743 5.02585 3.61576 4.67668L4.67659 3.61585C5.02576 3.26668 5.59909 3.29501 5.91243 3.67585L8.06576 6.29168"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.7075 11.9341L16.3233 14.0874C16.7042 14.4007 16.7317 14.9741 16.3833 15.3232L15.3225 16.3841C14.9733 16.7332 14.4 16.7049 14.0867 16.3241L11.9333 13.7082"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0685 16.3009C11.5943 17.8992 8.40678 17.9 5.93262 16.3"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.6992 5.93164C2.10087 8.40581 2.10004 11.5925 3.70004 14.0675"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0675 3.70004C11.5925 2.10004 8.40581 2.10087 5.93164 3.6992"
          stroke="var(--icon-color, currentColor)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </IconBase>
    </Wrapper>
  );
};
