import React, {
  ChangeEvent,
  FormEvent,
  forwardRef,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Button, Icon, mergeRefs, Tooltip, useDidMount } from '@column/column-ui-kit';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { useDebounce } from '~/hooks';

export type SearchProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  value?: string;
  tooltipContent?: string;
  onValueChange?: (value: string) => void;
  onFilterSubmit?: (value: string) => void;
  isLoading?: boolean;
};

const Container = styled.form`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-left: 1px;
`;

const Wrapper = styled.label<{ $isFilled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  transition:
    background-color 0.2s,
    box-shadow 0.2s;
  background-color: ${({ theme, $isFilled }) =>
    $isFilled
      ? theme.secondary.blendToBackground(theme.id === 'dark' ? 30 : 125)
      : theme.secondary.blendToBackground(theme.id === 'dark' ? 15 : 75)};
  box-shadow: 0 0 0 1px inset
    ${({ theme, $isFilled }) =>
      $isFilled
        ? theme.secondary.blendToBackground(theme.id === 'dark' ? 75 : 200)
        : theme.secondary.blendToBackground(theme.id === 'dark' ? 50 : 150)};
  border-radius: 8px;
  padding-right: 14px;
  padding-left: 32px;

  &:focus-within {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(theme.id === 'dark' ? 30 : 125)};
    box-shadow: 0 0 0 1px inset ${({ theme }) => theme.secondary.blendToBackground(theme.id === 'dark' ? 75 : 200)};
  }
`;

const Input = styled.input`
  appearance: none;
  outline: none;
  background-color: transparent;
  border: none;
  font-size: 14px;
  margin: 0;
  line-height: 16px;
  height: 16px;
  display: block;
  padding: 8px 0;
  width: 100%;
  flex-grow: 1;
  color: ${({ theme }) => theme.secondary.background};
  transition:
    color 0.2s,
    background-color 0.2s;
  width: auto;

  &::placeholder {
    opacity: 1;
    color: ${({ theme }) => theme.secondary.blendToBackground(500)};
    transition: color 0.2s;
  }

  &:focus-visible,
  &:hover {
    &::placeholder {
      color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    }
  }
`;

const SearchIcon = styled(Icon.AnimationSearch)<{ $isActive?: boolean }>`
  transform-origin: 0 0;
  transform: scale(0.8) translateZ(0);
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  position: absolute;
  top: 8px;
  left: 8px;
  pointer-events: none;
  transition: color 0.2s;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${({ theme }) => theme.primary.background};
    `}
`;

const Enter = styled(Button)<{ $isVisible?: boolean }>`
  --button-padding-h-xs: 6px;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transform: translateX(${({ $isVisible }) => ($isVisible ? 0 : -4)}px) translateZ(0);
  transition:
    opacity 0.2s,
    transform 0.2s,
    background-color 0.2s,
    box-shadow 0.2s;
  cursor: pointer;
  position: absolute;
  width: 24px;
  height: 24px;
  line-height: 17.5px;
  right: 4px;
  top: 4px;
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
`;

export const Search = forwardRef<HTMLInputElement, SearchProps>(
  ({ value, onValueChange, onFilterSubmit, className, isLoading, placeholder, tooltipContent, ...inputProps }, ref) => {
    const wrapperRef = useRef<HTMLLabelElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const gsapRef = useRef<Record<string, gsap.core.Tween>>({});

    const [showEnterKey, setShowEnterKey] = useState<boolean>(false);
    const [currentValue, setCurrentValue] = useState<string>(value ?? '');
    const [localTooltipContent, setLocalTooltipContent] = useState<string | undefined>(tooltipContent);
    const didMount = useDidMount();

    useLayoutEffect(() => {
      if (value !== currentValue) {
        setCurrentValue(value ?? '');
      }
    }, [value]);

    useEffect(() => {
      if (!tooltipContent || tooltipContent.length < 1 || tooltipContent === localTooltipContent) {
        return;
      }
      setLocalTooltipContent(tooltipContent);
    }, [tooltipContent]);

    useEffect(() => {
      if (!currentValue) {
        setShowEnterKey(false);
      }

      handleEnterShow(currentValue);
    }, [currentValue]);

    useLayoutEffect(() => {
      if (!inputRef.current) {
        return;
      }

      const spanSelected = inputRef.current.parentElement!.parentElement!.querySelector('& > .shadow-element');

      const span = (spanSelected || document.createElement('span')) as HTMLSpanElement;

      if (!spanSelected) {
        span.classList.add('shadow-element');
        span.style.font = getComputedStyle(inputRef.current).font;
        span.style.fontSize = '14px';
        span.style.letterSpacing = getComputedStyle(inputRef.current).letterSpacing;
        span.style.position = 'absolute';
        span.style.isolation = 'isolate';
        span.style.opacity = '0';
        span.style.pointerEvents = 'none';

        inputRef.current.parentElement!.parentElement!.appendChild(span);
      }

      span.textContent = (currentValue || placeholder) ?? '';

      inputRef.current.style.width = `${span?.offsetWidth + 2}px`;
    }, [placeholder, currentValue, inputRef]);

    const handleSubmit = useCallback(
      (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (onFilterSubmit) {
          onFilterSubmit((event.target as HTMLFormElement).search?.value ?? '');
        }
      },
      [value, onFilterSubmit]
    );

    const handleEnterShow = useDebounce((val: string) => {
      setShowEnterKey(val.length > 8);
    }, 500);

    useEffect(() => {
      const wrapper = wrapperRef.current;

      if (!wrapper || (typeof gsapRef.current?.enterKey !== 'undefined' && gsapRef.current?.enterKey.isActive())) {
        return;
      }

      const wrapperPaddingRight = parseFloat(getComputedStyle(wrapper).paddingRight);
      const paddingRight = showEnterKey ? 36 : 14;

      if (wrapperPaddingRight === paddingRight) {
        return;
      }

      gsapRef.current.enterKey = gsap.to(wrapperRef.current, {
        paddingRight,
        duration: didMount ? 0.25 : 0,
      });

      return () => {
        gsapRef.current.enterKey.kill();
      };
    }, [showEnterKey]);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (onValueChange) {
          onValueChange(event.target.value ?? '');
        }

        setCurrentValue(event.target.value ?? '');

        if (inputProps.onChange) {
          inputProps.onChange(event);
        }
      },
      [onValueChange, inputProps.onChange]
    );

    return (
      <Tooltip
        className={className}
        content={localTooltipContent}
        placement="top-start"
        offsetY={-4}
        isOpen={!!tooltipContent}
      >
        <Container onSubmit={handleSubmit} autoComplete="off">
          <Wrapper $isFilled={!!currentValue && currentValue.length > 8} ref={wrapperRef}>
            <SearchIcon running={isLoading} $isActive={isLoading} />
            <Input
              ref={mergeRefs([inputRef, ref])}
              {...inputProps}
              onChange={handleChange}
              value={value}
              placeholder={placeholder}
              name="search"
            />
            <Enter variant="secondary" size="tiny" type="submit" $isVisible={showEnterKey}>
              ↵
            </Enter>
          </Wrapper>
        </Container>
      </Tooltip>
    );
  }
);
