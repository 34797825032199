import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

export type ModalType =
  | 'AcceptPlatformInvite'
  | 'Authenticate'
  | 'Counterparty'
  | 'PasswordPrompt'
  | 'Alert'
  | 'Invite'
  | 'ClearTransfer'
  | 'ClearDisbursement'
  | 'UpdateDisbursement'
  | 'SandboxTransfer'
  | 'WebhookResult'
  | 'EventDetail'
  | 'Return'
  | 'CheckReturn'
  | 'EnableProduction'
  | 'CropImage'
  | 'EditCheck'
  | 'ErrorCheck';

export interface Modal {
  type: ModalType;
  data?: Record<string, any>;
}

interface ModalStoreState {
  openModals: ModalType[];
  openModalsData: Modal[];
}

interface ModalStoreComputed {
  getModalIsOpen: (modalType?: ModalType) => boolean;
  getModal: () => ModalType | undefined;
  getModalData: () => Record<string, any> | undefined;
}

interface ModalStoreAction {
  openModal: (modalType: ModalType, data?: Record<string, any>) => void;
  replaceModal: (modalType: ModalType, data?: Record<string, any>) => void;
  closeModal: (modalType?: ModalType) => void;
}

export const useModalStore = create<ModalStoreState & ModalStoreComputed & ModalStoreAction>()(
  subscribeWithSelector((set, get) => ({
    openModals: [],
    openModalsData: [],

    getModalIsOpen: (modalType?: ModalType) => {
      if (modalType) {
        return get()?.openModals.includes(modalType);
      }
      return get()?.openModals.length > 0;
    },
    getModal: () => (get()?.openModals.length > 0 ? get()?.openModals[0] : undefined),
    getModalData: () => (get()?.openModalsData.length > 0 ? get()?.openModalsData[0] : undefined),

    openModal: (modalType, data) => {
      set((state) => ({
        openModals: [...state.openModals, modalType],
        openModalsData: [
          ...state.openModalsData,
          {
            type: modalType,
            ...data,
          },
        ],
      }));
    },

    replaceModal: (modalType, data) => {
      set(() => ({
        openModals: [modalType],
        openModalsData: [
          {
            type: modalType,
            data,
          },
        ],
      }));
    },

    closeModal: (modalType) => {
      if (modalType) {
        set((state) => ({
          openModals: state.openModals.filter((m: ModalType) => m !== modalType),
        }));

        setTimeout(() => {
          set((state) => ({
            openModalsData: state.openModalsData.filter((m: Modal) => m.type !== modalType),
          }));
        }, 200);
        return;
      }

      set(() => ({
        openModals: [],
      }));

      setTimeout(() => {
        set(() => ({
          openModalsData: [],
        }));
      }, 200);
    },
  }))
);
