import { client } from './client';
import { convertValues } from '~/util';
import { useSessionStore } from '~/stores/Session';

export interface CreateAPIKeyRequest {
  platformId: string;
  name: string;
  description: string;
}

export interface ListAPIKeysRequest {
  platformId: string;
}

export interface APIKey {
  id: string;
  apiKeyToken: string;
  createdAt?: Date;
  createdBy: string;
  lastUsed?: Date;
  platformId: string;
  name: string;
  description: string;
}

export interface ListAPIKeysResponse {
  apiKeys: APIKey[];
}

export interface GetAPIKeyRequest {
  platformId: string;
  apiKeyId: string;
}

export interface UpdateAPIKeyRequest {
  platformId: string;
  apiKeyId: string;
  name: string;
  description: string;
}

export interface DeleteAPIKeyRequest {
  platformId: string;
  apiKeyId: string;
}

export class ApiKeyRepository {
  static async create(platformId: string, name: string, description: string, sandbox?: boolean) {
    const request: CreateAPIKeyRequest = {
      platformId,
      name,
      description,
    };

    return client
      .post<CreateAPIKeyRequest, APIKey>('/dashboard/api-keys', request, { sandbox })
      .then((response) => convertValues<APIKey>(response));
  }

  static async get(platformId: string, apiKeyId: string, sandbox?: boolean) {
    return client
      .get<GetAPIKeyRequest, APIKey>(`/dashboard/platforms/${platformId}/api-keys/${apiKeyId}`, { sandbox })
      .then((response) => convertValues<APIKey>(response));
  }

  static async update(platformId: string, apiKeyId: string, name: string, description: string, sandbox?: boolean) {
    const request: UpdateAPIKeyRequest = {
      platformId,
      apiKeyId,
      name,
      description,
    };

    return client
      .patch<UpdateAPIKeyRequest, APIKey>(`/dashboard/platforms/${platformId}/api-keys/${apiKeyId}`, request, {
        sandbox,
      })
      .then((response) => convertValues<APIKey>(response));
  }

  static async delete(platformId: string, apiKeyId: string, sandbox?: boolean) {
    return client.delete<DeleteAPIKeyRequest, object>(`/dashboard/platforms/${platformId}/api-keys/${apiKeyId}`, {
      sandbox,
    });
  }

  static async getAll(platformId: string, sandbox?: boolean) {
    return client
      .get<ListAPIKeysRequest, ListAPIKeysResponse>(`/dashboard/platforms/${platformId}/api-keys`, { sandbox })
      .then((response) => ({
        apiKeys: Array.isArray(response?.apiKeys) ? response.apiKeys.map((e) => convertValues<APIKey>(e)) : [],
      }));
  }

  static async getAllList(platformId: string) {
    if (useSessionStore.getState().currentPlatform?.isLiveEnabled) {
      const sandbox = await ApiKeyRepository.getAll(platformId, true);
      const production = await ApiKeyRepository.getAll(platformId, false);

      return {
        apiKeys: [
          ...sandbox.apiKeys.map((e) => ({ ...e, type: 'Sandbox' })),
          ...production.apiKeys.map((e) => ({ ...e, type: 'Production' })),
        ],
      };
    }

    const sandboxSec = await ApiKeyRepository.getAll(platformId, true);

    return {
      apiKeys: [...sandboxSec.apiKeys.map((e) => ({ ...e, type: 'Sandbox' }))],
    };
  }
}
