import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon } from '@column/column-ui-kit';
import isEqual from 'lodash.isequal';
import { shallow } from 'zustand/shallow';
import {
  PageTransferExportModalContent,
  TransferLocationState,
  transferTableColumns,
  ResetFilter,
} from '../Transfers/Overview';
import { BankAccountRouteProps } from './Route';
import { TransferRepository, TransferListResponse, transfers } from '~/repositories';
import {
  PopoverFilterAmount,
  PopoverFilterAmountData,
  PopoverFilterTransferTypeData,
  PopoverFilterTransferType,
  PopoverFilterTransferStatus,
  PopoverFilterTransferDate,
  PopoverFilterTransferDateData,
  PopoverFilterWrapper,
} from '~/components';
import { ROUTE } from '~/app/routes';
import { downloadBlob, formatNumberText, getDateFormat } from '~/util';
import { useSessionStore } from '~/stores/Session';
import { AlertData, useAlertStore } from '~/stores/Alert';
import { useNotificationStore } from '~/stores/Notification';
import { useQueryParams } from '~/hooks';
import { OverviewTable, OverviewTableRefProps } from '~/components/OverviewTable';
import { Search } from '~/components/Search';
import { TransferFilterType, useCheckTransferId } from '~/hooks/useTransfers';

const ExportWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
  width: 100%;
`;

export const PageBankAccountsTransfers: React.FC = () => {
  const { openAlert, closeAlert, alertData, updateAlertData } = useAlertStore();
  const { bankAccount } = useOutletContext<BankAccountRouteProps>();
  const navigate = useNavigate();
  const location = useLocation();
  const { addDangerNotification, addInfoNotification } = useNotificationStore();
  const overviewTableRef = useRef<OverviewTableRefProps>(null);

  const locationState = location.state as TransferLocationState;

  const mountedRef = useRef<boolean>(false);

  const { queryParams, getQueryObject } = useQueryParams<TransferFilterType>();

  const [totalResultsCount, setTotalResultsCount] = useState<number>(0);

  const handleResponse = useCallback((response?: TransferListResponse) => {
    setTotalResultsCount(response?.totalResultsCount ?? 0);
  }, []);

  const [filterByIdTooltipContent, setFilterByIdTooltipContent] = useState<string>('');
  const [filterByIdValue, setFilterByIdValue] = useState<string>('');

  useEffect(() => {
    if (!queryParams) {
      return undefined;
    }

    const { counterpartyId, accountNumberId } = queryParams;

    setFilterByIdValue(counterpartyId || accountNumberId || '');
  }, []);

  const handleFilterByIdValueChange = useCallback((value: string) => {
    setFilterByIdValue(value);
    setFilterByIdTooltipContent('');
  }, []);

  const [amountFilterData, setAmountFilterData] = useState<PopoverFilterAmountData | undefined>({});
  const isAmountFilterActive = useMemo(() => {
    if (
      (amountFilterData?.amount && amountFilterData?.amount > 0) ||
      (amountFilterData?.amountGte && amountFilterData?.amountGte > 0) ||
      (amountFilterData?.amountLte && amountFilterData?.amountLte > 0)
    ) {
      return true;
    }
    return false;
  }, [amountFilterData]);

  const [transferTypeFilterData, setTransferTypeFilterData] = useState<PopoverFilterTransferTypeData>({});
  const isTransferTypeFilterActive = useMemo(() => {
    if (
      (transferTypeFilterData && transferTypeFilterData.type && transferTypeFilterData.type.length > 0) ||
      (transferTypeFilterData && typeof transferTypeFilterData.isIncoming !== 'undefined')
    ) {
      return true;
    }
    return false;
  }, [transferTypeFilterData]);

  const [transferStatusFilterData, setTransferStatusFilterData] = useState<string[] | undefined>([]);

  const [transferDateFilterData, setTransferDateFilterData] = useState<Partial<PopoverFilterTransferDateData>>({});
  const isDateFilterActive = useMemo(() => {
    if (transferDateFilterData?.createdAtGte && transferDateFilterData?.createdAtLt) {
      return true;
    }
    return false;
  }, [transferDateFilterData]);

  const isFilterActive = useMemo(() => {
    return (
      filterByIdValue ||
      isAmountFilterActive ||
      isTransferTypeFilterActive ||
      (transferStatusFilterData && transferStatusFilterData.length > 0) ||
      isDateFilterActive
    );
  }, [filterByIdValue, isAmountFilterActive, transferTypeFilterData, transferStatusFilterData, isDateFilterActive]);

  const checkTransferId = useCheckTransferId({
    onSuccess: (id) => {
      const url = transfers.find((transfer) => transfer.idString && id.startsWith(transfer.idString))?.id;

      navigate(`${ROUTE.TRANSFERS}/edit/${url}/${id}`);
    },
    onError: (message) => {
      setFilterByIdTooltipContent(message);
    },
  });

  const handleFilterById = useCallback(
    (value: string) => {
      if (value.length > 0 && transfers.some((transfer) => transfer.idString && value.startsWith(transfer.idString))) {
        checkTransferId(value);

        return;
      }

      if (value.startsWith('cpty_')) {
        overviewTableRef.current?.setQueryParams({
          counterpartyId: value,
          accountNumberId: undefined,
        });

        return;
      }

      if (value.startsWith('acno_')) {
        overviewTableRef.current?.setQueryParams({
          accountNumberId: value,
          counterpartyId: undefined,
        });

        return;
      }

      if (value && value.length > 0) {
        setFilterByIdTooltipContent('Not a supported ID. Enter a Bank Account ID, Counterparty ID, or Transfer ID.');
      } else {
        setTimeout(() => overviewTableRef.current?.reset(), 0);
        setFilterByIdTooltipContent('');
      }
    },
    [overviewTableRef]
  );

  useLayoutEffect(() => {
    if (locationState?.trigger === 'delayedFetch' && overviewTableRef.current) {
      window.history.replaceState({}, '');

      setTimeout(() => {
        overviewTableRef.current?.fetch();

        setTimeout(() => {
          overviewTableRef.current?.fetch();
        }, 1000);
      }, 500);
    }
  }, [locationState?.trigger, overviewTableRef]);

  const [fetchFilters, setFetchFilters] = useState<TransferFilterType | null>({ bankAccountId: bankAccount.id });

  useEffect(() => {
    const entries = Object.fromEntries(
      Object.entries({
        ...queryParams,
        ...amountFilterData,
        isIncoming: transferTypeFilterData.isIncoming,
        ...transferDateFilterData,
        type:
          transferTypeFilterData.type && transferTypeFilterData.type?.length > 0
            ? transferTypeFilterData.type?.join(',')
            : undefined,
        status:
          transferStatusFilterData && transferStatusFilterData?.length > 0
            ? transferStatusFilterData?.join(',')
            : undefined,
      }).filter(([_, value]) => {
        if (typeof value === 'object' && Object.keys(value).length === 0) {
          return false;
        }

        if (Array.isArray(value) && value.length < 1) {
          return false;
        }

        return true;
      })
    );

    setFetchFilters((s) => ({ ...(isEqual(entries, s) ? s : entries), bankAccountId: bankAccount.id }));
  }, [queryParams, amountFilterData, transferTypeFilterData, transferStatusFilterData, transferDateFilterData]);

  useEffect(() => {
    if (typeof queryParams === 'undefined') {
      setFetchFilters({ bankAccountId: bankAccount.id });
      return;
    }

    const { amount, amountGte, amountLte, isIncoming, type, status, createdAtGte, createdAtLt } = queryParams;

    setAmountFilterData({
      amount: amount ? Number(amount) : undefined,
      amountGte: amountGte ? Number(amountGte) : undefined,
      amountLte: amountLte ? Number(amountLte) : undefined,
    });

    setTransferTypeFilterData({
      isIncoming: typeof isIncoming !== 'undefined' ? String(isIncoming) === 'true' : undefined,
      type: type ? (type.split(',') as any) : [],
    });

    setTransferStatusFilterData(status ? status.split(',') : []);

    setTransferDateFilterData({
      createdAtGte: createdAtGte && createdAtGte.length > 0 ? createdAtGte : undefined,
      createdAtLt: createdAtLt && createdAtLt.length > 0 ? createdAtLt : undefined,
    });

    setFetchFilters({
      ...queryParams,
      bankAccountId: bankAccount.id,
    });
  }, [queryParams]);

  const handleFilterReset = useCallback(() => {
    setAmountFilterData({});
    setTransferTypeFilterData({});
    setTransferStatusFilterData([]);
    setTransferDateFilterData({});

    setFilterByIdValue('');
    setFilterByIdTooltipContent('');

    setFetchFilters(null);

    setTimeout(() => overviewTableRef.current?.setQueryParams({ bankAccountId: bankAccount.id }), 0);
  }, []);

  const [exportAnimate, setExportAnimate] = useState<boolean>(false);

  const handleExport = (dataAlert?: AlertData) => {
    if (alertData?.submitLoading) {
      return;
    }

    updateAlertData({ submitLoading: true });
    setExportAnimate(true);

    const params = { ...getQueryObject };

    if (params?.page) {
      delete params.page;
    }

    if (params?.limit) {
      delete params.limit;
    }

    if (params?.startingAfter) {
      delete params.startingAfter;
    }

    if (params?.endingBefore) {
      delete params.endingBefore;
    }

    TransferRepository.export({ ...params, bankAccountId: bankAccount.id })
      .then((response) => {
        downloadBlob(
          response,
          `${
            dataAlert?.customFileName && dataAlert.customFileName.length > 0
              ? dataAlert.customFileName
              : `transfers-${getDateFormat(new Date())}`
          }.csv`
        );
      })
      .then(() => {
        closeAlert();
      })
      .catch((e) =>
        addDangerNotification({
          content: e.message,
          display: 'popup',
        })
      )
      .finally(() => {
        updateAlertData({ submitLoading: false });
      });
  };

  const handleExportClick = () => {
    let text = `Do you want to export & download the .csv file for ${formatNumberText(totalResultsCount)} transfers?`;

    if (!isFilterActive) {
      text = `Are you sure you want to export & download the .csv file for all transfers (${formatNumberText(
        totalResultsCount
      )}) without any filters applied?`;
    }

    if (totalResultsCount > 1000) {
      text = `Are you sure to export & download the .csv file for ${formatNumberText(
        totalResultsCount
      )} transfers? This may take a while.`;
    }

    openAlert(
      {
        headline: 'Export',
        text: <PageTransferExportModalContent text={text} />,
        submitText: 'Download',
        callback: handleExport,
        type: 'info',
        icon: <Icon.AnimationDownload running={exportAnimate} onDone={() => setExportAnimate(false)} />,
      },
      {
        submitStay: true,
        closeTimeoutMs: 500,
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      mountedRef.current = true;
    }, 2000);
  }, []);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => [state.isSandbox, state.currentUser?.defaultPlatformId],
        () => {
          if (!mountedRef.current) {
            return;
          }

          handleFilterReset();
        },
        {
          equalityFn: shallow,
        }
      ),
    []
  );

  return (
    <OverviewTable
      tableId="transfers"
      ref={overviewTableRef}
      fetchFilter={fetchFilters}
      staticFilters
      columns={transferTableColumns}
      onResponse={handleResponse}
      onLimitChange={() => handleFilterReset()}
      action={
        <>
          <PopoverFilterWrapper label="Amount" onSubmit={setAmountFilterData} isActive={isAmountFilterActive}>
            <PopoverFilterAmount data={amountFilterData ?? {}} />
          </PopoverFilterWrapper>
          <PopoverFilterWrapper label="Type" onSubmit={setTransferTypeFilterData} isActive={isTransferTypeFilterActive}>
            <PopoverFilterTransferType data={transferTypeFilterData ?? {}} />
          </PopoverFilterWrapper>
          <PopoverFilterWrapper
            label="Status"
            onSubmit={setTransferStatusFilterData}
            isActive={transferStatusFilterData && transferStatusFilterData.length > 0}
          >
            <PopoverFilterTransferStatus data={transferStatusFilterData ?? []} />
          </PopoverFilterWrapper>
          <PopoverFilterWrapper label="Date" onSubmit={setTransferDateFilterData} isActive={isDateFilterActive}>
            <PopoverFilterTransferDate data={transferDateFilterData ?? {}} />
          </PopoverFilterWrapper>

          <Search
            value={filterByIdValue}
            onFilterSubmit={handleFilterById}
            placeholder="Filter by ID"
            tooltipContent={filterByIdTooltipContent}
            onValueChange={handleFilterByIdValueChange}
          />

          {isFilterActive && (
            <ResetFilter
              size="small"
              variant="subtle-primary"
              icon={<Icon.Reset />}
              iconRight
              onClick={handleFilterReset}
            >
              Reset filters
            </ResetFilter>
          )}

          <ExportWrapper>
            <Button
              icon={<Icon.AnimationDownload running={exportAnimate} onDone={() => setExportAnimate(false)} />}
              size="small"
              variant="secondary"
              onClick={handleExportClick}
            >
              Export
            </Button>
          </ExportWrapper>
        </>
      }
      rowClick={(row) => {
        if (row.original.type === 'Realtime') {
          addInfoNotification({
            content: 'Realtime transfer view will be available soon',
            display: 'page',
          });

          return;
        }

        navigate(
          `${ROUTE.TRANSFERS}/edit/${(row.original.type || 'ach')
            .toString()
            .toLowerCase()
            .replace('debit', '')
            .replace('credit', '')
            .replace('. ', '-')
            .trim()}/${row.original.id}`
        );
      }}
    />
  );
};
