import { client } from './client';
import { convertValues } from '~/util';
import {
  ExternalCounterparty,
  ExternalCreateCounterpartyRequest,
  CounterpartiesList,
  CounterpartyServiceListCounterpartiesParams,
  ExternalFinancialInstitution,
} from '~/typings/API';

type Counterparty = ExternalCounterparty;
type CounterpartyType = Counterparty;
export type CounterpartyListParams = CounterpartyServiceListCounterpartiesParams;
export type { CounterpartyType, Counterparty, CounterpartiesList, ExternalFinancialInstitution };
export type CreateCounterparty = ExternalCreateCounterpartyRequest;

const counterpartyCast = ({ accountNumber, routingNumber }: Counterparty) => ({
  accountNumber,
  routingNumber,
});

const institutionCast = ({ routingNumber }: ExternalFinancialInstitution) => ({
  routingNumber,
});

export interface GetCounterpartyRequest {
  id: string;
}

export class CounterpartyRepository {
  static async create(request: CreateCounterparty) {
    return client
      .post<CreateCounterparty, CounterpartyType>('/counterparties', request)
      .then((response) => convertValues<CounterpartyType>(response, undefined, counterpartyCast(response)));
  }

  static async get(request: GetCounterpartyRequest) {
    return client
      .get<unknown, CounterpartyType>(`/counterparties/${request.id}`)
      .then((response) => convertValues<CounterpartyType>(response, undefined, counterpartyCast(response)));
  }

  static async institution(routingNumber: string) {
    return client
      .get<any, ExternalFinancialInstitution>(`/institutions/${routingNumber}`)
      .then((response) => convertValues<ExternalFinancialInstitution>(response, undefined, institutionCast(response)));
  }

  static async getAll(query?: Partial<CounterpartyListParams>) {
    return client
      .get<CounterpartyListParams, CounterpartiesList>('/counterparties', {
        query,
      })
      .then((response) => ({
        counterparties: Array.isArray(response?.counterparties)
          ? response.counterparties.map((e) => convertValues<CounterpartyType>(e, undefined, counterpartyCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async delete(id: string) {
    return client.delete<object, object>(`/counterparties/${id}`);
  }
}
