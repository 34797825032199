import React, { useRef, FC } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface EventsIconProps {
  className?: string;
  size?: number;
}

const Wrapper = styled.div``;

export const EventsIcon: FC<EventsIconProps> = ({ className, size }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapperRef} className={className}>
      {size === 16 ? (
        <IconBase viewportSize={16}>
          <rect x="2" y="2" width="12" height="12" rx="3" stroke="var(--icon-color, currentColor)" strokeWidth="1.5" />
          <path
            d="M1.99585 8H4.63273L6.00037 5.5L7.81802 10.5L9.1782 8H10.9958"
            stroke="var(--icon-color, currentColor)"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </IconBase>
      ) : (
        <IconBase viewportSize={20}>
          <rect
            x="2.5"
            y="2.5"
            width="15"
            height="15"
            rx="3"
            stroke="var(--icon-color, currentColor)"
            strokeWidth="1.5"
          />
          <path
            d="M2.49707 10H6.01291L7.83644 7L10.26 13L12.0735 10H14.4971"
            stroke="var(--icon-color, currentColor)"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </IconBase>
      )}
    </Wrapper>
  );
};
