export interface Currency {
  code: string;
  name: string;
  nameSingle: string;
  namePlural: string;
  symbol: string;
  symbolNative: string;
  decimalDigits: number;
  numberToBasic: number;
}

export const currencies: Currency[] = [
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
    nameSingle: 'UAE Dirham',
    namePlural: 'UAE Dirhams',
    symbol: 'د.إ.',
    symbolNative: 'د.إ.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'AFN',
    name: 'Afghan Afghani',
    nameSingle: 'Afghan Afghani',
    namePlural: 'Afghan Afghani',
    symbol: 'Af',
    symbolNative: '؋',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ALL',
    name: 'Albanian Lek',
    nameSingle: 'Albanian Lek',
    namePlural: 'Albanian Lekë',
    symbol: 'L',
    symbolNative: 'L',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'AMD',
    name: 'Armenian Dram',
    nameSingle: 'Armenian Dram',
    namePlural: 'Armenian Dram',
    symbol: '֏',
    symbolNative: 'դր',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ANG',
    name: 'Netherlands Antillean Guilder',
    nameSingle: 'Netherlands Antillean Guilder',
    namePlural: 'Netherlands Antillean Guilders',
    symbol: 'ƒ',
    symbolNative: 'ƒ',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'AOA',
    name: 'Angolan Kwanza',
    nameSingle: 'Angolan Kwanza',
    namePlural: 'Angolan Kwanza',
    symbol: 'Kz',
    symbolNative: 'Kz',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ARS',
    name: 'Argentine Peso',
    nameSingle: 'Argentine Peso',
    namePlural: 'Argentine Pesos',
    symbol: 'AR$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'AUD',
    name: 'Australian Dollar',
    nameSingle: 'Australian Dollar',
    namePlural: 'Australian Dollars',
    symbol: 'AU$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'AWG',
    name: 'Aruban Florin',
    nameSingle: 'Aruban Florin',
    namePlural: 'Aruban Florin',
    symbol: 'ƒ',
    symbolNative: 'ƒ',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'AZN',
    name: 'Azerbaijani Manat',
    nameSingle: 'Azerbaijani Manat',
    namePlural: 'Azerbaijani Manat',
    symbol: 'ман',
    symbolNative: '₼',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BAM',
    name: 'Bosnia and Herzegovina Convertible Mark',
    nameSingle: 'Bosnia-Herzegovina Convertible Mark',
    namePlural: 'Bosnia-Herzegovina Marks',
    symbol: 'KM',
    symbolNative: 'КМ',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BBD',
    name: 'Barbadian Dollar',
    nameSingle: 'Barbadian Dollar',
    namePlural: 'Barbadian Dollars',
    symbol: 'BBD$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BDT',
    name: 'Bangladeshi Taka',
    nameSingle: 'Bangladeshi Taka',
    namePlural: 'Bangladeshi Taka',
    symbol: '৳',
    symbolNative: '৳',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BGN',
    name: 'Bulgarian Lev',
    nameSingle: 'Bulgarian Lev',
    namePlural: 'Bulgarian Leva',
    symbol: 'лв.',
    symbolNative: 'лв.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BHD',
    name: 'Bahraini Dinar',
    nameSingle: 'Bahraini Dinar',
    namePlural: 'Bahraini Dinars',
    symbol: 'BD',
    symbolNative: 'د.ب.',
    decimalDigits: 3,
    numberToBasic: 1000,
  },
  {
    code: 'BIF',
    name: 'Burundian Franc',
    nameSingle: 'Burundian Franc',
    namePlural: 'Burundian Francs',
    symbol: 'FBu',
    symbolNative: 'FBu',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'BMD',
    name: 'Bermudian Dollar',
    nameSingle: 'Bermudian Dollar',
    namePlural: 'Bermudian Dollars',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BND',
    name: 'Brunei Dollar',
    nameSingle: 'Brunei Dollar',
    namePlural: 'Brunei Dollars',
    symbol: 'B$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BOB',
    name: 'Bolivian Boliviano',
    nameSingle: 'Bolivian Boliviano',
    namePlural: 'Bolivian Bolivianos',
    symbol: 'Bs.',
    symbolNative: 'Bs.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BRL',
    name: 'Brazilian Real',
    nameSingle: 'Brazilian Real',
    namePlural: 'Brazilian Reais',
    symbol: 'R$',
    symbolNative: 'R$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BSD',
    name: 'Bahamian Dollar',
    nameSingle: 'Bahamian Dollar',
    namePlural: 'Bahamian Dollars',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BTN',
    name: 'Bhutanese Ngultrum',
    nameSingle: 'Bhutanese Ngultrum',
    namePlural: 'Bhutanese Ngultrums',
    symbol: 'Nu.',
    symbolNative: 'Nu.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BWP',
    name: 'Botswana Pula',
    nameSingle: 'Botswana Pula',
    namePlural: 'Botswana Pula',
    symbol: 'P',
    symbolNative: 'P',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BYN',
    name: 'Belarusian Ruble',
    nameSingle: 'Belarusian Ruble',
    namePlural: 'Belarusian Rubles',
    symbol: 'Br',
    symbolNative: 'руб.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
    nameSingle: 'Belize Dollar',
    namePlural: 'Belize Dollars',
    symbol: 'BZ$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CAD',
    name: 'Canadian Dollar',
    nameSingle: 'Canadian Dollar',
    namePlural: 'Canadian Dollars',
    symbol: 'CA$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CDF',
    name: 'Congolese Franc',
    nameSingle: 'Congolese Franc',
    namePlural: 'Congolese Francs',
    symbol: 'FC',
    symbolNative: '₣',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CHF',
    name: 'Swiss Franc',
    nameSingle: 'Swiss Franc',
    namePlural: 'Swiss Francs',
    symbol: 'Fr.',
    symbolNative: '₣',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CKD',
    name: 'Cook Islands Dollar',
    nameSingle: 'Cook Islands Dollar',
    namePlural: 'Cook Islands Dollars',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CLP',
    name: 'Chilean Peso',
    nameSingle: 'Chilean Peso',
    namePlural: 'Chilean Pesos',
    symbol: 'CL$',
    symbolNative: '$',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'CNY',
    name: 'Chinese Yuan',
    nameSingle: 'Chinese Yuan',
    namePlural: 'Chinese Yuan',
    symbol: 'CN¥',
    symbolNative: '¥元',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'COP',
    name: 'Colombian Peso',
    nameSingle: 'Colombian Peso',
    namePlural: 'Colombian Pesos',
    symbol: 'CO$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CRC',
    name: 'Costa Rican Colon',
    nameSingle: 'Costa Rican Colón',
    namePlural: 'Costa Rican Colones',
    symbol: '₡',
    symbolNative: '₡',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CUC',
    name: 'Cuban convertible Peso',
    nameSingle: 'Cuban Convertible Peso',
    namePlural: 'Cuban Convertible Pesos',
    symbol: 'CUC$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CUP',
    name: 'Cuban Peso',
    nameSingle: 'Cuban Peso',
    namePlural: 'Cuban Pesos',
    symbol: '$MN',
    symbolNative: '₱',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CVE',
    name: 'Cabo Verdean Escudo',
    nameSingle: 'Cabo Verdean Escudo',
    namePlural: 'Cabo Verdean Escudo',
    symbol: 'CV$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'CZK',
    name: 'Czech Koruna',
    nameSingle: 'Czech Koruna',
    namePlural: 'Czech Koruny',
    symbol: 'Kč',
    symbolNative: 'Kč',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'DJF',
    name: 'Djiboutian Franc',
    nameSingle: 'Djiboutian Franc',
    namePlural: 'Djiboutian Francs',
    symbol: 'Fdj',
    symbolNative: 'ف.ج.',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'DKK',
    name: 'Danish Krone',
    nameSingle: 'Danish Krone',
    namePlural: 'Danish Kroner',
    symbol: 'kr.',
    symbolNative: 'kr.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'DOP',
    name: 'Dominican Peso',
    nameSingle: 'Dominican Peso',
    namePlural: 'Dominican Pesos',
    symbol: 'RD$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'DZD',
    name: 'Algerian Dinar',
    nameSingle: 'Algerian Dinar',
    namePlural: 'Algerian Dinars',
    symbol: 'DA',
    symbolNative: 'د.ج.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'EGP',
    name: 'Egyptian Pound',
    nameSingle: 'Egyptian Pound',
    namePlural: 'Egyptian Pounds',
    symbol: 'E£',
    symbolNative: 'ج.م.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'EHP',
    name: 'Sahrawi Peseta',
    nameSingle: 'Sahrawi Peseta',
    namePlural: 'Sahrawi Pesetas',
    symbol: 'Ptas.',
    symbolNative: 'Ptas.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ERN',
    name: 'Eritrean Nakfa',
    nameSingle: 'Eritrean Nakfa',
    namePlural: 'Eritrean Nakfa',
    symbol: 'Nkf',
    symbolNative: 'ناكفا',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ETB',
    name: 'Ethiopian Birr',
    nameSingle: 'Ethiopian Birr',
    namePlural: 'Ethiopian Birr',
    symbol: 'Br',
    symbolNative: 'ብር',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'EUR',
    name: 'Euro',
    nameSingle: 'Euro',
    namePlural: 'Euros',
    symbol: '€',
    symbolNative: '€',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'FJD',
    name: 'Fijian Dollar',
    nameSingle: 'Fijian Dollar',
    namePlural: 'Fijian Dollars',
    symbol: 'FJ$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'FKP',
    name: 'Falkland Islands Pound',
    nameSingle: 'Falkland Islands Pound',
    namePlural: 'Falkland Islands Pounds',
    symbol: 'FK£',
    symbolNative: '£',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'FOK',
    name: 'Faroese Króna',
    nameSingle: 'Faroese Króna',
    namePlural: 'Faroese Krónas',
    symbol: 'kr',
    symbolNative: 'kr',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'GBP',
    name: 'Pound Sterling',
    nameSingle: 'Pound Sterling Pound',
    namePlural: 'Pound Sterling Pounds',
    symbol: '£',
    symbolNative: '£',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'GEL',
    name: 'Georgian Lari',
    nameSingle: 'Georgian Lari',
    namePlural: 'Georgian Lari',
    symbol: '₾',
    symbolNative: '₾',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'GGP',
    name: 'Guernsey Pound',
    nameSingle: 'Guernsey Pound',
    namePlural: 'Guernsey Pounds',
    symbol: '£',
    symbolNative: '£',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'GHS',
    name: 'Ghanaian Cedi',
    nameSingle: 'Ghanaian Cedi',
    namePlural: 'Ghanaian Cedis',
    symbol: 'GH₵',
    symbolNative: '₵',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'GIP',
    name: 'Gibraltar Pound',
    nameSingle: 'Gibraltar Pound',
    namePlural: 'Gibraltar Pounds',
    symbol: '£',
    symbolNative: '£',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'GMD',
    name: 'Gambian Dalasi',
    nameSingle: 'Gambian Dalasi',
    namePlural: 'Gambian Dalasis',
    symbol: 'D',
    symbolNative: 'D',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'GNF',
    name: 'Guinean Franc',
    nameSingle: 'Guinean Franc',
    namePlural: 'Guinean Francs',
    symbol: 'FG',
    symbolNative: 'FG',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'GTQ',
    name: 'Guatemalan Quetzal',
    nameSingle: 'Guatemalan Quetzal',
    namePlural: 'Guatemalan Quetzales',
    symbol: 'Q',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'GYD',
    name: 'Guyanese Dollar',
    nameSingle: 'Guyanese Dollar',
    namePlural: 'Guyanese Dollars',
    symbol: 'G$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
    nameSingle: 'Hong Kong Dollar',
    namePlural: 'Hong Kong Dollars',
    symbol: 'HK$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'HNL',
    name: 'Honduran Lempira',
    nameSingle: 'Honduran Lempira',
    namePlural: 'Honduran Lempiras',
    symbol: 'L',
    symbolNative: 'L',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'HRK',
    name: 'Croatian Kuna',
    nameSingle: 'Croatian Kuna',
    namePlural: 'Croatian Kuna',
    symbol: 'kn',
    symbolNative: 'kn',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'HTG',
    name: 'Haitian Gourde',
    nameSingle: 'Haitian Gourde',
    namePlural: 'Haitian Gourdes',
    symbol: 'G',
    symbolNative: 'G',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'HUF',
    name: 'Hungarian Forint',
    nameSingle: 'Hungarian Forint',
    namePlural: 'Hungarian Forint',
    symbol: 'Ft',
    symbolNative: 'Ft',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'IDR',
    name: 'Indonesian Rupiah',
    nameSingle: 'Indonesian Rupiah',
    namePlural: 'Indonesian Rupiah',
    symbol: 'Rp',
    symbolNative: 'Rp',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ILS',
    name: 'Israeli new Shekel',
    nameSingle: 'Israeli Shekel',
    namePlural: 'Israeli Shekels',
    symbol: '₪',
    symbolNative: '₪',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'IMP',
    name: 'Manx Pound',
    nameSingle: 'Manx Pound',
    namePlural: 'Manx Pounds',
    symbol: '£',
    symbolNative: '£',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'INR',
    name: 'Indian Rupee',
    nameSingle: 'Indian Rupee',
    namePlural: 'Indian Rupees',
    symbol: 'Rs.',
    symbolNative: '₹',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'IQD',
    name: 'Iraqi Dinar',
    nameSingle: 'Iraqi Dinar',
    namePlural: 'Iraqi Dinars',
    symbol: 'د.ع.',
    symbolNative: 'د.ع.',
    decimalDigits: 3,
    numberToBasic: 1000,
  },
  {
    code: 'IRR',
    name: 'Iranian Rial',
    nameSingle: 'Iranian Rial',
    namePlural: 'Iranian Rials',
    symbol: '﷼',
    symbolNative: '﷼',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ISK',
    name: 'Icelandic Krona',
    nameSingle: 'Icelandic Krona',
    namePlural: 'Icelandic Krónur',
    symbol: 'kr',
    symbolNative: 'kr',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'JEP',
    name: 'Jersey Pound',
    nameSingle: 'Jersey Pound',
    namePlural: 'Jersey Pounds',
    symbol: '£',
    symbolNative: '£',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'JMD',
    name: 'Jamaican Dollar',
    nameSingle: 'Jamaican Dollar',
    namePlural: 'Jamaican Dollars',
    symbol: 'J$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'JOD',
    name: 'Jordanian Dinar',
    nameSingle: 'Jordanian Dinar',
    namePlural: 'Jordanian Dinars',
    symbol: 'JD',
    symbolNative: 'د.أ.',
    decimalDigits: 3,
    numberToBasic: 1000,
  },
  {
    code: 'JPY',
    name: 'Japanese Yen',
    nameSingle: 'Japanese Yen',
    namePlural: 'Japanese Yen',
    symbol: '¥',
    symbolNative: '¥',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'KES',
    name: 'Kenyan Shilling',
    nameSingle: 'Kenyan Shilling',
    namePlural: 'Kenyan Shillings',
    symbol: 'KSh',
    symbolNative: 'KSh',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'KGS',
    name: 'Kyrgyzstani Som',
    nameSingle: 'Kyrgyzstani Som',
    namePlural: 'Kyrgyzstani Som',
    symbol: 'с',
    symbolNative: 'с',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'KHR',
    name: 'Cambodian Riel',
    nameSingle: 'Cambodian Riel',
    namePlural: 'Cambodian Riels',
    symbol: '៛',
    symbolNative: '៛',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'KID',
    name: 'Kiribati Dollar',
    nameSingle: 'Kiribati Dollar',
    namePlural: 'Kiribati Dollars',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'KMF',
    name: 'Comorian Franc',
    nameSingle: 'Comorian Franc',
    namePlural: 'Comorian Francs',
    symbol: 'CF',
    symbolNative: 'CF',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'KPW',
    name: 'North Korean Won',
    nameSingle: 'North Korean Won',
    namePlural: 'North Korean Won',
    symbol: '₩',
    symbolNative: '₩',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'KRW',
    name: 'South Korean Won',
    nameSingle: 'South Korean Won',
    namePlural: 'South Korean Won',
    symbol: '₩',
    symbolNative: '₩',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'KWD',
    name: 'Kuwaiti Dinar',
    nameSingle: 'Kuwaiti Dinar',
    namePlural: 'Kuwaiti Dinars',
    symbol: 'KD',
    symbolNative: 'د.ك.',
    decimalDigits: 3,
    numberToBasic: 1000,
  },
  {
    code: 'KYD',
    name: 'Cayman Islands Dollar',
    nameSingle: 'Cayman Islands Dollar',
    namePlural: 'Cayman Islands Dollars',
    symbol: 'CI$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'KZT',
    name: 'Kazakhstani Tenge',
    nameSingle: 'Kazakhstani Tenge',
    namePlural: 'Kazakhstani Tenge',
    symbol: '₸',
    symbolNative: '₸',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'LAK',
    name: 'Lao Kip',
    nameSingle: 'Lao Kip',
    namePlural: 'Lao Kip',
    symbol: '₭N',
    symbolNative: '₭',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'LBP',
    name: 'Lebanese Pound',
    nameSingle: 'Lebanese Pound',
    namePlural: 'Lebanese Pounds',
    symbol: 'LL.',
    symbolNative: 'ل.ل.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'LKR',
    name: 'Sri Lankan Rupee',
    nameSingle: 'Sri Lankan Rupee',
    namePlural: 'Sri Lankan Rupees',
    symbol: 'Rs.',
    symbolNative: 'රු or ரூ',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'LRD',
    name: 'Liberian Dollar',
    nameSingle: 'Liberian Dollar',
    namePlural: 'Liberian Dollars',
    symbol: 'L$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'LSL',
    name: 'Lesotho Loti',
    nameSingle: 'Lesotho Loti',
    namePlural: 'Lesotho maLoti',
    symbol: 'L',
    symbolNative: 'L',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'LYD',
    name: 'Libyan Dinar',
    nameSingle: 'Libyan Dinar',
    namePlural: 'Libyan Dinars',
    symbol: 'LD',
    symbolNative: 'ل.د.',
    decimalDigits: 3,
    numberToBasic: 1000,
  },
  {
    code: 'MAD',
    name: 'Moroccan Dirham',
    nameSingle: 'Moroccan Dirham',
    namePlural: 'Moroccan Dirhams',
    symbol: 'DH',
    symbolNative: 'د.م.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MDL',
    name: 'Moldovan Leu',
    nameSingle: 'Moldovan Leu',
    namePlural: 'Moldovan Lei',
    symbol: 'L',
    symbolNative: 'L',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MGA',
    name: 'Malagasy Ariary',
    nameSingle: 'Malagasy Ariary',
    namePlural: 'Malagasy Ariary',
    symbol: 'Ar',
    symbolNative: 'Ar',
    decimalDigits: 2,
    numberToBasic: 5,
  },
  {
    code: 'MKD',
    name: 'Macedonian Denar',
    nameSingle: 'Macedonian Denar',
    namePlural: 'Macedonian Denars',
    symbol: 'den',
    symbolNative: 'ден',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MMK',
    name: 'Myanmar Kyat',
    nameSingle: 'Myanmar Kyat',
    namePlural: 'Myanmar Kyat',
    symbol: 'Ks',
    symbolNative: 'Ks',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MNT',
    name: 'Mongolian Tögrög',
    nameSingle: 'Mongolian Tögrög',
    namePlural: 'Mongolian Tögrög',
    symbol: '₮',
    symbolNative: '₮',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MOP',
    name: 'Macanese Pataca',
    nameSingle: 'Macanese Pataca',
    namePlural: 'Macanese Patacas',
    symbol: 'MOP$',
    symbolNative: 'MOP$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MRU',
    name: 'Mauritanian Ouguiya',
    nameSingle: 'Mauritanian Ouguiya',
    namePlural: 'Mauritanian Ouguiya',
    symbol: 'UM',
    symbolNative: 'أ.م.',
    decimalDigits: 2,
    numberToBasic: 5,
  },
  {
    code: 'MUR',
    name: 'Mauritian Rupee',
    nameSingle: 'Mauritian Rupee',
    namePlural: 'Mauritian Rupees',
    symbol: 'Rs.',
    symbolNative: 'रु ',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MVR',
    name: 'Maldivian Rufiyaa',
    nameSingle: 'Maldivian Rufiyaa',
    namePlural: 'Maldivian Rufiyaa',
    symbol: 'MRf',
    symbolNative: '.ރ',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MWK',
    name: 'Malawian Kwacha',
    nameSingle: 'Malawian Kwacha',
    namePlural: 'Malawian Kwacha',
    symbol: 'MK',
    symbolNative: 'MK',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MXN',
    name: 'Mexican Peso',
    nameSingle: 'Mexican Peso',
    namePlural: 'Mexican Pesos',
    symbol: 'MX$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MYR',
    name: 'Malaysian Ringgit',
    nameSingle: 'Malaysian Ringgit',
    namePlural: 'Malaysian Ringgit',
    symbol: 'RM',
    symbolNative: 'RM',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'MZN',
    name: 'Mozambican Metical',
    nameSingle: 'Mozambican Metical',
    namePlural: 'Mozambican Meticais',
    symbol: 'MTn',
    symbolNative: 'MT',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'NAD',
    name: 'Namibian Dollar',
    nameSingle: 'Namibian Dollar',
    namePlural: 'Namibian Dollars',
    symbol: 'N$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'NGN',
    name: 'Nigerian Naira',
    nameSingle: 'Nigerian Naira',
    namePlural: 'Nigerian Naira',
    symbol: '₦',
    symbolNative: '₦',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'NIO',
    name: 'Nicaraguan Córdoba',
    nameSingle: 'Nicaraguan Córdoba Oro',
    namePlural: 'Nicaraguan Córdoba Oro',
    symbol: 'C$',
    symbolNative: 'C$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'NOK',
    name: 'Norwegian Krone',
    nameSingle: 'Norwegian Krone',
    namePlural: 'Norwegian Kroner',
    symbol: 'kr',
    symbolNative: 'kr',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'NPR',
    name: 'Nepalese Rupee',
    nameSingle: 'Nepalese Rupee',
    namePlural: 'Nepalese Rupees',
    symbol: 'Rs.',
    symbolNative: 'रू',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
    nameSingle: 'New Zealand Dollar',
    namePlural: 'New Zealand Dollars',
    symbol: 'NZ$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'OMR',
    name: 'Omani Rial',
    nameSingle: 'Omani Rial',
    namePlural: 'Omani Rials',
    symbol: 'OR',
    symbolNative: 'ر.ع.',
    decimalDigits: 3,
    numberToBasic: 1000,
  },
  {
    code: 'PAB',
    name: 'Panamanian Balboa',
    nameSingle: 'Panamanian Balboa',
    namePlural: 'Panamanian Balboa',
    symbol: 'B/.',
    symbolNative: 'B/.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'PEN',
    name: 'Peruvian Sol',
    nameSingle: 'Peruvian Sol',
    namePlural: 'Peruvian Soles',
    symbol: 'S/.',
    symbolNative: 'S/.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'PGK',
    name: 'Papua New Guinean Kina',
    nameSingle: 'Papua New Guinean Kina',
    namePlural: 'Papua New Guinean Kina',
    symbol: 'K',
    symbolNative: 'K',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'PHP',
    name: 'Philippine Peso',
    nameSingle: 'Philippine Peso',
    namePlural: 'Philippine Pesos',
    symbol: '₱',
    symbolNative: '₱',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'PKR',
    name: 'Pakistani Rupee',
    nameSingle: 'Pakistani Rupee',
    namePlural: 'Pakistani Rupees',
    symbol: 'Rs.',
    symbolNative: 'Rs',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'PLN',
    name: 'Polish Zloty',
    nameSingle: 'Polish Zloty',
    namePlural: 'Polish Zlotys',
    symbol: 'zł',
    symbolNative: 'zł',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'PND',
    name: 'Pitcairn Islands Dollar',
    nameSingle: 'Pitcairn Islands Dollar',
    namePlural: 'Pitcairn Islands Dollars',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'PRB',
    name: 'Transnistrian Ruble',
    nameSingle: 'Transnistrian Ruble',
    namePlural: 'Transnistrian Rubles',
    symbol: 'р.',
    symbolNative: 'р.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'PYG',
    name: 'Paraguayan Guaraní',
    nameSingle: 'Paraguayan Guaraní',
    namePlural: 'Paraguayan Guaraníes',
    symbol: '₲',
    symbolNative: '₲',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'QAR',
    name: 'Qatari Riyal',
    nameSingle: 'Qatari Riyal',
    namePlural: 'Qatari Riyals',
    symbol: 'QR',
    symbolNative: 'ر.ق.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'RON',
    name: 'Romanian Leu',
    nameSingle: 'Romanian Leu',
    namePlural: 'Romanian Lei',
    symbol: 'L',
    symbolNative: 'L',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'RSD',
    name: 'Serbian Dinar',
    nameSingle: 'Serbian Dinar',
    namePlural: 'Serbian Dinars',
    symbol: 'din',
    symbolNative: 'дин',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'RUB',
    name: 'Russian Ruble',
    nameSingle: 'Russian Ruble',
    namePlural: 'Russian Rubles',
    symbol: '₽',
    symbolNative: '₽',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'RWF',
    name: 'Rwandan Franc',
    nameSingle: 'Rwandan Franc',
    namePlural: 'Rwandan Francs',
    symbol: 'FRw',
    symbolNative: 'R₣',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'SAR',
    name: 'Saudi Riyal',
    nameSingle: 'Saudi Riyal',
    namePlural: 'Saudi Riyals',
    symbol: 'SR',
    symbolNative: 'ر.س.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SBD',
    name: 'Solomon Islands Dollar',
    nameSingle: 'Solomon Islands Dollar',
    namePlural: 'Solomon Islands Dollars',
    symbol: 'SI$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SCR',
    name: 'Seychellois Rupee',
    nameSingle: 'Seychellois Rupee',
    namePlural: 'Seychellois Rupees',
    symbol: 'Rs.',
    symbolNative: 'Rs',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SDG',
    name: 'Sudanese Pound',
    nameSingle: 'Sudanese Pound',
    namePlural: 'Sudanese Pounds',
    symbol: '£SD',
    symbolNative: 'ج.س.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SEK',
    name: 'Swedish Krona',
    nameSingle: 'Swedish Krona',
    namePlural: 'Swedish Kronor',
    symbol: 'kr',
    symbolNative: 'kr',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
    nameSingle: 'Singapore Dollar',
    namePlural: 'Singapore Dollars',
    symbol: 'S$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SHP',
    name: 'Saint Helena Pound',
    nameSingle: 'Saint Helena Pound',
    namePlural: 'Saint Helena Pounds',
    symbol: '£',
    symbolNative: '£',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SLL',
    name: 'Sierra Leonean Leone',
    nameSingle: 'Sierra Leonean Leone',
    namePlural: 'Sierra Leonean Leones',
    symbol: 'Le',
    symbolNative: 'Le',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SLS',
    name: 'Somaliland Shilling',
    nameSingle: 'Somaliland Shilling',
    namePlural: 'Somaliland Shillings',
    symbol: 'Sl',
    symbolNative: 'Sl',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SOS',
    name: 'Somali Shilling',
    nameSingle: 'Somali Shilling',
    namePlural: 'Somali Shillings',
    symbol: 'Sh.So.',
    symbolNative: 'Ssh',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SRD',
    name: 'Surinamese Dollar',
    nameSingle: 'Surinamese Dollar',
    namePlural: 'Surinamese Dollars',
    symbol: 'Sr$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SSP',
    name: 'South Sudanese Pound',
    nameSingle: 'South Sudanese Pound',
    namePlural: 'South Sudanese Pounds',
    symbol: 'SS£',
    symbolNative: 'SS£',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'STN',
    name: 'Sao Tome and Príncipe Dobra',
    nameSingle: 'Sao Tome Dobra',
    namePlural: 'Sao Tome Dobras',
    symbol: 'Db',
    symbolNative: 'Db',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SVC',
    name: 'Salvadoran Colón',
    nameSingle: 'Salvadoran Colón',
    namePlural: 'Salvadoran Colones',
    symbol: '₡',
    symbolNative: '₡',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SYP',
    name: 'Syrian Pound',
    nameSingle: 'Syrian Pound',
    namePlural: 'Syrian Pounds',
    symbol: 'LS',
    symbolNative: 'ل.س.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'SZL',
    name: 'Swazi Lilangeni',
    nameSingle: 'Swazi Lilangeni',
    namePlural: 'Swazi Emalangeni',
    symbol: 'L',
    symbolNative: 'L',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'THB',
    name: 'Thai Baht',
    nameSingle: 'Thai Baht',
    namePlural: 'Thai Baht',
    symbol: '฿',
    symbolNative: '฿',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'TJS',
    name: 'Tajikistani Somoni',
    nameSingle: 'Tajikistani Somoni',
    namePlural: 'Tajikistani Somoni',
    symbol: 'SM',
    symbolNative: 'SM',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'TMT',
    name: 'Turkmenistan Manat',
    nameSingle: 'Turkmenistan Manat',
    namePlural: 'Turkmenistan Manat',
    symbol: 'm.',
    symbolNative: 'T',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'TND',
    name: 'Tunisian Dinar',
    nameSingle: 'Tunisian Dinar',
    namePlural: 'Tunisian Dinars',
    symbol: 'DT',
    symbolNative: 'د.ت.',
    decimalDigits: 3,
    numberToBasic: 1000,
  },
  {
    code: 'TOP',
    name: 'Tongan Paʻanga',
    nameSingle: "Tongan Pa'anga",
    namePlural: "Tongan Pa'anga",
    symbol: 'T$',
    symbolNative: 'PT',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'TRY',
    name: 'Turkish Lira',
    nameSingle: 'Turkish Lira',
    namePlural: 'Turkish Lira',
    symbol: 'TL',
    symbolNative: '₺',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
    nameSingle: 'Trinidad and Tobago Dollar',
    namePlural: 'Trinidad and Tobago Dollars',
    symbol: 'TT$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'TVD',
    name: 'Tuvaluan Dollar',
    nameSingle: 'Tuvaluan Dollar',
    namePlural: 'Tuvaluan Dollars',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'TWD',
    name: 'New Taiwan Dollar',
    nameSingle: 'New Taiwan Dollar',
    namePlural: 'New Taiwan Dollars',
    symbol: 'NT$',
    symbolNative: '圓',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'TZS',
    name: 'Tanzanian Shilling',
    nameSingle: 'Tanzanian Shilling',
    namePlural: 'Tanzanian Shillings',
    symbol: 'TSh',
    symbolNative: 'TSh',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'UAH',
    name: 'Ukrainian Hryvnia',
    nameSingle: 'Ukrainian Hryvnia',
    namePlural: 'Ukrainian Hryvnias',
    symbol: '₴',
    symbolNative: 'грн',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'UGX',
    name: 'Ugandan Shilling',
    nameSingle: 'Ugandan Shilling',
    namePlural: 'Ugandan Shillings',
    symbol: 'USh',
    symbolNative: 'Sh',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'USD',
    name: 'United States Dollar',
    nameSingle: 'US Dollar',
    namePlural: 'US Dollars',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'UYU',
    name: 'Uruguayan Peso',
    nameSingle: 'Uruguayan Peso',
    namePlural: 'Uruguayan Pesos',
    symbol: '$U',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'UZS',
    name: 'Uzbekistani Som',
    nameSingle: 'Uzbekistani Som',
    namePlural: 'Uzbekistani Som',
    symbol: 'сум',
    symbolNative: 'сум',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'VED',
    name: 'Venezuelan bolívar digital',
    nameSingle: 'Venezuelan Bolívar Digital',
    namePlural: 'Venezuelan Bolívars Digital',
    symbol: 'Bs.',
    symbolNative: 'Bs.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'VES',
    name: 'Venezuelan Bolívar Soberano',
    nameSingle: 'Venezuelan Bolívar',
    namePlural: 'Venezuelan Bolívares',
    symbol: 'Bs.F',
    symbolNative: 'Bs.F',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'VND',
    name: 'Vietnamese Dong',
    nameSingle: 'Vietnamese Dong',
    namePlural: 'Vietnamese Dong',
    symbol: '₫',
    symbolNative: '₫',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'VUV',
    name: 'Vanuatu Vatu',
    nameSingle: 'Vanuatu Vatu',
    namePlural: 'Vanuatu Vatu',
    symbol: 'VT',
    symbolNative: 'VT',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'WST',
    name: 'Samoan Tala',
    nameSingle: 'Samoan Tala',
    namePlural: 'Samoan Tala',
    symbol: 'T',
    symbolNative: 'ST',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'XAF',
    name: 'Central African CFA Franc BEAC',
    nameSingle: 'Central African CFA Franc',
    namePlural: 'Central African CFA Francs',
    symbol: 'Fr',
    symbolNative: 'Fr.',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
    nameSingle: 'East Caribbean Dollar',
    namePlural: 'East Caribbean Dollars',
    symbol: '$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'XOF',
    name: 'West African CFA Franc BCEAO',
    nameSingle: 'West African CFA Franc',
    namePlural: 'West African CFA Francs',
    symbol: '₣',
    symbolNative: '₣',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'XPF',
    name: 'CFP Franc (Franc Pacifique)',
    nameSingle: 'CFP Franc',
    namePlural: 'CFP Francs',
    symbol: '₣',
    symbolNative: '₣',
    decimalDigits: 0,
    numberToBasic: 1,
  },
  {
    code: 'YER',
    name: 'Yemeni Rial',
    nameSingle: 'Yemeni Rial',
    namePlural: 'Yemeni Rials',
    symbol: 'YR',
    symbolNative: 'ر.ي.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ZAR',
    name: 'South African Rand',
    nameSingle: 'South African Rand',
    namePlural: 'South African Rand',
    symbol: 'R',
    symbolNative: 'R',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ZMW',
    name: 'Zambian Kwacha',
    nameSingle: 'Zambian Kwacha',
    namePlural: 'Zambian Kwacha',
    symbol: 'ZK',
    symbolNative: 'ZK',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'ZWL',
    name: 'Zimbabwean Dollar',
    nameSingle: 'Zimbabwean Dollar',
    namePlural: 'Zimbabwean Dollars',
    symbol: 'Z$',
    symbolNative: '$',
    decimalDigits: 2,
    numberToBasic: 100,
  },
  {
    code: 'Artsakh',
    name: 'Artsakh Dram',
    nameSingle: 'Artsakh Dram',
    namePlural: 'Artsakh Dram',
    symbol: 'դր.',
    symbolNative: 'դր.',
    decimalDigits: 2,
    numberToBasic: 100,
  },
];

export const currenciesUSD = currencies.filter((c) => c.code === 'USD');

export const currenciesOrdered = currencies.sort((c) => (['USD', 'EUR'].includes(c.code) ? -1 : 0));
