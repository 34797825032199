import { DefaultTheme } from 'styled-components';
import { themeBase, Theme } from '@column/column-ui-kit';

const themeWarmColors = {
  foreground: '#272523',
  background: '#FDFCFC',
  body: '#FCFAF8',
  primary: '#5884F3',
  secondary: '#757575',
  success: '#7CE5A8',
  warning: '#F4DF69',
  danger: '#EC4439',
};

export const themeWarm: DefaultTheme = new Theme({
  name: 'Warm',
  ...themeWarmColors,
  style: themeBase,
  colors: {},
});
