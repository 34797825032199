import React, { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Icon, Chip, Input, Dropdown } from '@column/column-ui-kit';
import styled from 'styled-components';
import { useNotificationStore } from '~/stores/Notification';
import { Box, EditPage, Headline, TinyHeadline } from '~/styles';
import { useSessionStore } from '~/stores/Session';
import {
  DocumentRepository,
  documentsDropdownOptions,
  DocumentsType,
  DocumentsTypeEntry,
  UploadDocumentRequest,
  validDocumentExtensions,
} from '~/repositories';
import { convertByteToString, convertValues } from '~/util';
import { FileIconsUrlType, FILE_ICONS_URL } from '~/assets';

const UploadWrapper = styled.div`
  padding: 16px;
  border-radius: 12px;
  display: grid;
  grid-gap: 12px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(100)};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(1100, 75)};
`;

const UploadList = styled.ul`
  margin: 0 0 4px 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 8px;
`;

const UploadListEntry = styled.li`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  padding-left: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 132px auto;

  svg {
    --icon-size: 16px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};

    position: absolute;
    left: 0;
    top: 3px;
  }

  strong {
    font-weight: 600;
    color: ${({ theme }) => theme.secondary.blendToBackground(900)};
  }
`;

const UploadListEntryLabel = styled.div`
  line-height: 22px;
`;

const UploadListEntryList = styled.div`
  display: flex;
  gap: 4px;
`;

const UploadListChip = styled(Chip)`
  --chip-pale: ${({ theme }) => theme.secondary.blendToBackground(110)};

  padding: 3px 5px;
  font-family: 'Roboto Mono', monospace;
`;

const Upload = styled.form`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr)) 116px;
`;

const FileLabel = styled.label`
  position: relative;

  span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  input {
    display: none;
  }
`;

const FileButton = styled(Button)`
  text-align: left;
  font-weight: 500;
`;

const FileButtonLabel = styled.span<{ $isSelected?: boolean }>`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: ${({ $isSelected, theme }) => !$isSelected && theme.secondary.blendToBackground(800)};
`;

const FileList = styled(Box)`
  padding: 12px;
`;

const FileIcon = styled.img`
  width: 42px;
  height: 42px;
  display: block;
`;

const FileEntry = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  &:not(:first-child) {
    padding-top: 12px;
  }

  &:not(:last-child) {
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(200)};
  }
`;

const FileEntryText = styled.div`
  max-height: 42px;

  strong {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 6px;
    font-weight: 500;
    display: block;
    color: ${({ theme }) => theme.foreground};
  }
`;

const FileEntryChip = styled(Chip)``;

const FileEntryRight = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: auto;
`;

const FileEntrySize = styled(Chip)`
  font-family: 'Roboto Mono', monospace;
`;

export const PageDocuments: React.FC = () => {
  const { currentUser } = useSessionStore();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const [uploadRequest, setUploadRequest] = useState<Partial<UploadDocumentRequest>>({
    description: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState<string[]>([]);
  const [listDocuments, setListDocuments] = useState<DocumentsTypeEntry[]>([]);

  const handleSelectFileClick = useCallback(() => {
    inputFileRef.current?.click();
  }, [inputFileRef]);

  const handleFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUploadRequest((state) => ({
      ...state,
      file: event.target.files && event.target.files?.length > 0 ? event.target.files[0] : undefined,
    }));
  }, []);

  const handleDownloadClick = (id: string) => {
    if (!currentUser?.defaultPlatformId) {
      return;
    }

    setIsLoadingDownload((state) => [...state, id]);

    DocumentRepository.get(id)
      .then((response) => {
        window.open(response.url.replaceAll('\u0026', '&'));
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      })
      .finally(() => setIsLoadingDownload((state) => [...state.filter((f) => f !== id)]));
  };

  const fetchData = () => {
    if (!currentUser?.defaultPlatformId) {
      return;
    }

    DocumentRepository.getAllPlatform(currentUser.defaultPlatformId)
      .then((response) => setListDocuments(response.documents))
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    if (!uploadRequest.description || uploadRequest.description.length < 1) {
      addDangerNotification({
        display: 'page',
        content: 'Please provide a description',
      });

      setIsLoading(false);

      return;
    }

    if (!uploadRequest.file) {
      addDangerNotification({
        display: 'page',
        content: 'Please select a file',
      });

      setIsLoading(false);

      return;
    }

    const extension = uploadRequest.file.name.split('.')?.pop()?.toLowerCase();

    if (!extension || !validDocumentExtensions.includes(`.${extension}`)) {
      addDangerNotification({
        display: 'page',
        content: 'Please select a valid extension',
      });

      setIsLoading(false);

      return;
    }

    if (!uploadRequest.type || uploadRequest.type.length < 1) {
      addDangerNotification({
        display: 'page',
        content: 'Please select a type',
      });

      setIsLoading(false);

      return;
    }

    DocumentRepository.upload(uploadRequest as UploadDocumentRequest, currentUser?.defaultPlatformId ?? '')
      .then<DocumentsType>((response) => convertValues<DocumentsType>(response))
      .then((response) => {
        addSuccessNotification({
          content: `Document "${uploadRequest.description}" was uploaded successfully`,
          display: 'page',
          actionLabel: 'View',
          onActionClick: () => window.open(response.url),
        });
        fetchData();
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [currentUser?.defaultPlatformId]);

  return (
    <EditPage fullWidth smallGap>
      <Headline>Documents</Headline>

      <UploadWrapper>
        <TinyHeadline>Upload Document</TinyHeadline>
        <UploadList>
          <UploadListEntry>
            <Icon.CircleCheck />
            <UploadListEntryLabel>Valid Extensions</UploadListEntryLabel>
            <UploadListEntryList>
              {validDocumentExtensions.map((ext: string) => (
                <UploadListChip key={ext}>{ext}</UploadListChip>
              ))}
            </UploadListEntryList>
          </UploadListEntry>
          <UploadListEntry>
            <Icon.CircleCheck />
            <UploadListEntryLabel>Maximum File Size</UploadListEntryLabel>
            <UploadListEntryList>
              <UploadListChip>16MB</UploadListChip>
            </UploadListEntryList>
          </UploadListEntry>
        </UploadList>

        <Upload onSubmit={handleSubmit}>
          <Input
            variant="light"
            value={uploadRequest.description}
            onChange={(value) => setUploadRequest((state) => ({ ...state, description: value }))}
            placeholder="Description"
          />
          <Dropdown
            active={uploadRequest.type}
            onChange={(value) => setUploadRequest((state) => ({ ...state, type: value }))}
            fullWidth
            placeholder="Select type..."
            options={documentsDropdownOptions}
          />
          <FileLabel htmlFor="upload">
            <input ref={inputFileRef} id="upload" type="file" name="file" onChange={handleFileChange} />
            <FileButton
              icon={<Icon.Folder />}
              fullWidth
              type="button"
              variant="secondary"
              onClick={handleSelectFileClick}
            >
              <FileButtonLabel $isSelected={!!(uploadRequest.file && uploadRequest.file.name)}>
                {uploadRequest.file && uploadRequest.file.name ? uploadRequest.file.name : 'Select file...'}
              </FileButtonLabel>
            </FileButton>
          </FileLabel>
          <Button isLoading={isLoading} fullWidth icon={<Icon.ArrowUp />}>
            Upload
          </Button>
        </Upload>
      </UploadWrapper>

      {listDocuments.length > 0 && (
        <FileList>
          {listDocuments.map((file) => (
            <FileEntry key={file.id}>
              <FileIcon
                src={
                  Object.keys(FILE_ICONS_URL).includes(file.ext)
                    ? FILE_ICONS_URL[file.ext as FileIconsUrlType]
                    : FILE_ICONS_URL.unknown
                }
              />
              <FileEntryText>
                <strong>{file.description}</strong>
                <FileEntryChip size="small" type="info">
                  {documentsDropdownOptions.find((o) => o.value === file.type)?.label ?? 'Unkown'}
                </FileEntryChip>
              </FileEntryText>
              <FileEntryRight>
                <FileEntrySize>{convertByteToString(file.size)}</FileEntrySize>
                <Button
                  variant="secondary"
                  icon={<Icon.ArrowDown />}
                  size="small"
                  onClick={() => handleDownloadClick(file.id)}
                  isLoading={isLoadingDownload.includes(file.id)}
                >
                  Download
                </Button>
              </FileEntryRight>
            </FileEntry>
          ))}
        </FileList>
      )}
    </EditPage>
  );
};
