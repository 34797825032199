import React, { useRef, FC } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface RealtimeIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const RealtimeIcon: FC<RealtimeIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={20}>
        <path d="M16.5 14V6.5C16.5 4.84 15.16 3.5 13.5 3.5H6" stroke="currentColor" strokeWidth="1.5" />
        <path d="M12 16V10C12 8.9 11.1 8 10 8H4" stroke="currentColor" strokeWidth="1.5" />
        <path d="M7.5 18V13.5C7.5 12.95 7.05 12.5 6.5 12.5H2" stroke="currentColor" strokeWidth="1.5" />
      </IconBase>
    </Wrapper>
  );
};
