import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BusinessForm, PersonForm } from './Info/Partial';
import { Headline, PlatformInfoDescription, Inner } from '~/styles';
import {
  EntityType,
  EntityTypes,
  EntityTypeOption,
  EntityTypeOptionCircle,
  EntityTypeOptionLabel,
  EntityTypeIcons,
} from '~/app/pages/Entities/Edit';
import { useSessionStore } from '~/stores/Session';
import { ROUTE } from '~/app/routes';
import { PageHeader, SectionHeader } from '~/components';

const EntityTypeSelection = styled.div`
  display: grid;
  grid-gap: 24px;
  margin-bottom: 32px;
`;

export const PageRootEntity: React.FC = () => {
  const rootEntitySandbox = useSessionStore((state) => state.rootEntitySandbox);
  const [entityType, setEntityType] = useState<EntityTypes>('business');
  const navigate = useNavigate();

  useEffect(() => {
    setEntityType(rootEntitySandbox?.personDetails ? 'person' : 'business');
  }, [rootEntitySandbox]);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.isSandbox,
        (isSandbox) => {
          if (!isSandbox) {
            navigate(ROUTE.PLATFORM);
          }
        },
        { fireImmediately: true }
      ),
    []
  );

  return (
    <>
      <PageHeader text="Root Entity (Sandbox)" />

      <Inner pt={0}>
        <PlatformInfoDescription>
          Root entities have special properties - such as the ability to be parents of special account types. Accounts
          under a root entity have special use cases - they hold your company's money and are used for purposes like
          reserve accounts for fraud, holding accounts for overdrafts, and operating accounts for funding customer
          rewards.
        </PlatformInfoDescription>
      </Inner>

      {!rootEntitySandbox && (
        <EntityTypeSelection>
          <EntityType>
            {['Person', 'Business'].map((name: string) => {
              const nameLower = name.toLowerCase() as EntityTypes;
              return (
                <EntityTypeOption
                  key={name}
                  isChecked={entityType === nameLower}
                  onCheckedChange={(value: boolean) => value && setEntityType(nameLower)}
                >
                  <EntityTypeOptionCircle isChecked={entityType === nameLower}>
                    {EntityTypeIcons[nameLower]}
                  </EntityTypeOptionCircle>
                  <EntityTypeOptionLabel isChecked={entityType === nameLower}>{name}</EntityTypeOptionLabel>
                </EntityTypeOption>
              );
            })}
          </EntityType>
        </EntityTypeSelection>
      )}
      {entityType === 'person' && (
        <PersonForm personEntity={rootEntitySandbox?.personDetails} id={rootEntitySandbox?.id} sandbox />
      )}
      {entityType === 'business' && (
        <BusinessForm businessEntity={rootEntitySandbox?.businessDetails} id={rootEntitySandbox?.id} sandbox />
      )}
    </>
  );
};
