import React, { PropsWithChildren } from 'react';
import { Icon, Button } from '@column/column-ui-kit';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { SmallHeadline } from '~/styles';
import { useSessionStore } from '~/stores/Session';
import { useHelpSidebarStore } from '~/stores/HelpSidebar';

export interface RestrictedStateProps {
  className?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  max-width: 380px;
  margin: 0 auto;
  padding: 10vh 0;

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 16px;
    color: ${({ theme }) => theme.secondary.background};

    strong {
      font-weight: 500;
      color: ${({ theme }) => theme.secondary.blendToBackground(1050)};
    }
  }
`;

const Heading = styled.div`
  display: grid;
`;

const StyledIcon = styled(Icon.Lock)`
  --icon-size: 24px;
  --icon-color: ${({ theme }) => theme.secondary.background};
  margin-bottom: 16px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

export const RestrictedState: React.FC<PropsWithChildren<RestrictedStateProps>> = ({ className }) => {
  const navigate = useNavigate();
  const currentPermissionName = useSessionStore((s) => s.currentPermissionName);
  const openHelpSidebar = useHelpSidebarStore((s) => s.openHelpSidebar);

  return (
    <Wrapper className={className}>
      <Heading>
        <StyledIcon />
        <SmallHeadline>Restricted access</SmallHeadline>
      </Heading>

      <div>
        <p>You don't have permissions to view this page.</p>

        <p>
          If you believe you were mistakenly assigned to the <strong>{currentPermissionName}</strong> role or need
          special permissions, please contact your administrator.
        </p>
      </div>

      <Buttons>
        <Button size="small" variant="secondary" icon={<Icon.ArrowLeft />} onClick={() => navigate(-1)}>
          Back
        </Button>
        <Button
          size="small"
          variant="subtle"
          icon={<Icon.CircleQuestionmark />}
          onClick={() => openHelpSidebar('Roles')}
        >
          Roles
        </Button>
      </Buttons>
    </Wrapper>
  );
};
