import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, DropdownElement } from '@column/column-ui-kit';
import { AddressSection, FormSection } from './BusinessForm';
import { AddressField, CopyInput, SectionHeader } from '~/components';
import { AddressType, CreatePersonEntity, EntityRepository } from '~/repositories';
import { Divider, FormElement, FormLabel, Grid, Inner, Line } from '~/styles';
import { generateFields } from '~/util';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { useAddress } from '~/hooks';

interface PersonFormProps {
  personEntity?: DeepPartial<CreatePersonEntity>;
  id?: string;
  sandbox?: boolean;
  disableForm?: boolean;
}

const Action = styled.div`
  margin: 32px 0 0 0;
  display: flex;
  justify-content: flex-end;
`;

export const PersonForm: React.FC<PersonFormProps> = (props) => {
  const { currentPlatform, setRootEntity, setRootEntitySandbox } = useSessionStore();
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const [person, setPerson] = useState<DeepPartial<CreatePersonEntity>>({});
  const { states, countries } = useAddress({ types: ['state', 'country'], countryCodes: ['US'] });
  const stateRef = useRef<DropdownElement>(null);

  const handleSave = () => {
    if (!currentPlatform?.id || !props.sandbox) {
      return;
    }

    if (props.id) {
      EntityRepository.updatePerson(props.id, {
        firstName: person.firstName,
        lastName: person.lastName,
        middleName: person.middleName,
        dateOfBirth: person.dateOfBirth,
        email: person.email,
        address: person.address,
      })
        .then((response) => {
          if (props.sandbox) {
            setRootEntitySandbox(response);
          } else {
            setRootEntity(response);
          }

          addSuccessNotification({
            content: `${response.personDetails?.firstName} ${response.personDetails?.lastName} updated`,
            display: 'page',
          });
        })
        .catch((error) => {
          addDangerNotification({
            content: error.message,
            display: 'page',
          });
        });
      return;
    }

    EntityRepository.createRootPerson(
      currentPlatform.id,
      person as CreatePersonEntity,
      props.sandbox ? 'sandbox' : 'live'
    )
      .then((response) => {
        if (props.sandbox) {
          setRootEntitySandbox(response);
        } else {
          setRootEntity(response);
        }

        addSuccessNotification({
          content: `${response.personDetails?.firstName} ${response.personDetails?.lastName} created`,
          display: 'page',
        });
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(() => {
    if (!props.id || !props.personEntity) {
      return;
    }
    setPerson(props.personEntity);
  }, [props.personEntity]);

  return (
    <>
      <Inner pt={0}>
        <Grid>
          {props.id && (
            <FormElement fullWidth>
              <FormLabel>ID</FormLabel>
              <CopyInput value={props.id} />
            </FormElement>
          )}
          {generateFields<CreatePersonEntity>(
            [
              {
                label: 'First Name',
                value: 'firstName',
                newRow: true,
                isDisabled: props.disableForm,
              },
              {
                label: 'Last Name',
                value: 'lastName',
                isDisabled: props.disableForm,
              },
              {
                label: 'Middle Name',
                value: 'middleName',
                isDisabled: props.disableForm,
              },
              {
                label: 'Social Security Number',
                value: 'ssn',
                newRow: true,
                hide: !!props.id,
                isDisabled: props.disableForm,
              },
              {
                type: 'date',
                label: 'Date of Birth',
                value: 'dateOfBirth',
                disableBefore: new Date('1900-01-01T00:00:00'),
                disableAfter: new Date(),
                isDisabled: props.disableForm,
              },
              {
                label: 'Email',
                value: 'email',
                isDisabled: props.disableForm,
              },
            ],
            person,
            setPerson
          )}
        </Grid>
      </Inner>

      <SectionHeader text="Address" border />
      <Inner pt={16}>
        <Grid>
          {!props.id && (
            <>
              <AddressField
                onChange={(a) => {
                  setPerson({ ...person, address: a });
                  if (!a.state) {
                    stateRef.current?.open();
                  }
                }}
                justUS
                isDisabled={props.disableForm}
              />
              <Line />
            </>
          )}

          {generateFields<CreatePersonEntity, AddressType>(
            [
              {
                label: 'Line 1',
                value: 'line1',
                isDisabled: props.disableForm,
              },
              {
                label: 'Line 2',
                value: 'line2',
                isDisabled: props.disableForm,
              },
              {
                label: 'City',
                value: 'city',
                isDisabled: props.disableForm,
              },
              {
                type: 'country',
                label: 'Country',
                value: 'countryCode',
                countries,
                isDisabled: props.disableForm,
              },
              {
                label: 'Postal Code',
                value: 'postalCode',
                isDisabled: props.disableForm,
              },
              {
                type: 'state',
                typeValue: person?.address?.countryCode,
                label: 'State',
                value: 'state',
                states,
                isDisabled: props.disableForm,
                fieldRef: stateRef,
              },
            ],
            person,
            setPerson,
            'address'
          )}
        </Grid>
        {props.sandbox && (
          <Action>
            <Button onClick={handleSave} isDisabled={props.disableForm} size="small">
              {props.id ? 'Update' : 'Create'}
            </Button>
          </Action>
        )}
      </Inner>
    </>
  );
};
