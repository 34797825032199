import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Chip, Fade, Icon } from '@column/column-ui-kit';
import styled from 'styled-components';
import { ModalBase } from './Base';
import { useModalStore, ModalType } from '~/stores/Modal';
import { Headline, Paragraph } from '~/styles';
import { useAcceptPlatformInvite, useListPlatformInvites } from '~/hooks/useUsers';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { IconCircle, Notification } from '~/components';
import { LogoLoading } from '~/elements';
import { userGroups } from '~/repositories';
import { Buttons } from '~/components/Modal/Alert';

const Mailbox = styled(Icon.AnimationMailbox)`
  --icon-background: ${({ theme }) => theme.primary.blendToBackground(100)};
`;

const StyledLoading = styled.div`
  height: 60px;
  padding: 24px;
  position: relative;
`;

const StyledBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  justify-content: center;
  text-align: center;
  vertical-align: middle;

  & > * {
    margin-right: 6px;
  }
`;

const StyledFailureHeader = styled.div`
  margin-bottom: 18px;
  text-align: center;
`;

const StyledHeader = styled.div`
  margin-bottom: 10px;
  text-align: center;
`;

const StyledNotification = styled(Notification)`
  margin-bottom: 20px;
`;

const StyledWrapper = styled(ModalBase)`
  --modal-width: 360px;
  padding: 24px;
`;

export const ModalAcceptPlatformInvite: React.FC = () => {
  const { currentUser, triggerEvent: triggerSessionEvent } = useSessionStore();
  const closeModal = useModalStore((state) => state.closeModal);
  const modalTypes: ModalType[] = ['AcceptPlatformInvite'];
  const [inviteCode, setInviteCode] = useState<string>();
  const [platformId, setPlatformId] = useState<string>();
  const [isAcceptingInvite, setAcceptingInvite] = useState<boolean>(false);
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const addSuccessNotification = useNotificationStore((s) => s.addSuccessNotification);

  const listPlatformInvites = useListPlatformInvites({
    shouldNotLoad: currentUser === undefined,
  });
  useEffect(() => {
    if (currentUser) {
      listPlatformInvites.createRequest({ dashboardUserId: currentUser.id });
    }
  }, [currentUser]);

  const acceptPlatformInvite = useAcceptPlatformInvite({
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'popup',
      });
      setAcceptingInvite(false);
    },
    onSuccess: () => {
      addSuccessNotification({
        content: 'Invite accepted',
        display: 'page',
      });
      setAcceptingInvite(false);
      triggerSessionEvent('accept_platform_invite');
      closeModal();
    },
  });
  const handleAcceptClick = useCallback(() => {
    if (platformId) {
      acceptPlatformInvite.createRequest({ inviteCode, platformId });
      setAcceptingInvite(true);
    }
  }, [acceptPlatformInvite, inviteCode, platformId]);

  const invite = useMemo(() => {
    const invites = listPlatformInvites.response?.invites;
    if (!invites) {
      return;
    }

    return invites.find((i) => i.platformId === platformId);
  }, [listPlatformInvites.response, platformId]);

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.getModalData(),
        (modalData) => {
          setInviteCode(modalData?.inviteCode);
          setPlatformId(modalData?.platformId);
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  return (
    <StyledWrapper modalTypes={modalTypes}>
      <IconCircle>
        <Mailbox />
      </IconCircle>
      {!invite && listPlatformInvites.response && (
        <>
          <StyledFailureHeader>
            <Headline size="middle">Join Platform</Headline>
          </StyledFailureHeader>
          <StyledNotification color={'danger'} withClose={false}>
            Invite not found
          </StyledNotification>
        </>
      )}
      {!invite && (listPlatformInvites.isLoading || !listPlatformInvites.response) && (
        <StyledLoading>
          <LogoLoading />
        </StyledLoading>
      )}
      <Fade show={!!invite}>
        <StyledHeader>
          <Headline size="middle">Join {invite?.platformName}?</Headline>
        </StyledHeader>
        <StyledBody>
          <Paragraph>Your role will be set as </Paragraph>
          <Chip>{invite && userGroups[invite?.platformRole]}</Chip>
        </StyledBody>
      </Fade>
      <Buttons grow>
        <Button variant="secondary" onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          icon={<Icon.CircleCheck />}
          isDisabled={!invite || isAcceptingInvite}
          isLoading={isAcceptingInvite}
          onClick={handleAcceptClick}
        >
          Accept
        </Button>
      </Buttons>
    </StyledWrapper>
  );
};
