import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Icon } from '@column/column-ui-kit';
import styled from 'styled-components';
import { BusinessForm, PersonForm, EntityStatus } from './Partial';
import { EmptyState, PageHeader, SectionHeader } from '~/components';
import { EntityTypes } from '~/app/pages/Entities/Edit';
import { useSessionStore } from '~/stores/Session';
import { ROUTE } from '~/public/routes';
import { Inner } from '~/styles';

export const PagePlatformInfo: React.FC = () => {
  const rootEntity = useSessionStore((state) => state.rootEntity);
  const location = useLocation();
  const navigate = useNavigate();
  const [entityType, setEntityType] = useState<EntityTypes>('business');

  useEffect(() => {
    setEntityType(rootEntity?.personDetails ? 'person' : 'business');
  }, [rootEntity]);

  return (
    <>
      <PageHeader text="Info" />
      <Inner py={0}>
        <EntityStatus status={rootEntity?.verificationStatus} />

        {!rootEntity && (
          <EmptyState headline="No root entity" text="Create your Root Entity by opening a Commercial Account.">
            <Button onClick={() => navigate(`${ROUTE.REGISTER_OPEN_BANK_ACCOUNT}`)} size="small" icon={<Icon.Plus />}>
              Create Commercial Account
            </Button>
          </EmptyState>
        )}
      </Inner>

      {rootEntity && entityType === 'person' && (
        <>
          <SectionHeader text="Root Entity" />
          <PersonForm personEntity={rootEntity?.personDetails} id={rootEntity?.id} disableForm />
        </>
      )}
      {rootEntity && entityType === 'business' && (
        <>
          <SectionHeader text="Root Entity" />
          <BusinessForm businessEntity={rootEntity?.businessDetails} id={rootEntity?.id} disableForm />
        </>
      )}
    </>
  );
};
