import { gsap } from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Button, Input, PasswordInput, Tooltip } from '@column/column-ui-kit';
import styled, { DefaultTheme, ThemeContext } from 'styled-components';
import { NotificationList } from '~/components';
import { Logo } from '~/elements';
import { ROUTE } from '~/public/routes';
import { ROUTE as APP_ROUTE } from '~/app/routes';
import { handlePlaidRedirectUrl, PlaidParams, PlaidParamsType, PlaidRepository, UserRepository } from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { Headline, Hint, Paragraph } from '~/styles';
import { log, triggerEvent, validateEmail } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';
import { useQueryParams } from '~/hooks';

interface PageLoginProps {
  isAuthFlow?: boolean;
}

export type LoginQueryParams = {
  [key: string]: string;
  redirect: string;
};

interface LocationState {
  action?: string;
}

export const Panel = styled.div`
  display: grid;
  grid-gap: 32px;
  text-align: center;
`;

export const StyledPanel = styled(Panel)`
  --panel-y: 0px;
  --panel-s: 1;
  --panel-o: 1;
  transform: translateY(var(--panel-y)) scale(var(--panel-s)) translateZ(0);
  opacity: var(--panel-o);
`;

export const StyledLogo = styled(Logo)`
  margin: auto;
`;

export const HiddenLogo = styled(StyledLogo)`
  opacity: 0;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
`;

export const AdditionalLogo = styled(Logo)`
  margin-left: -12px;
  transform-origin: 0 0;
  overflow: visible;

  & > path {
    --logo-path-opacity: 0;
    --logo-path-x: 16px;
    --logo-path-y: 24px;
    opacity: var(--logo-path-opacity);
    transform: translate(var(--logo-path-x), var(--logo-path-y)) translateZ(0);
  }
`;

export const Form = styled.form``;

export const Rows = styled.div`
  display: grid;
  grid-gap: 16px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const HintLink = styled(Hint)`
  text-decoration: none;
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

const StyledNotificationList = styled(NotificationList)`
  --toggle-height-padding: 0 0 16px 0;
`;

export const animateLogo = (
  logo: SVGElement,
  logoWrapper: HTMLDivElement,
  panel: HTMLDivElement,
  themeContext: DefaultTheme,
  gs: any,
  pathPlugin: any,
  callback: () => void
) => {
  gs.registerPlugin(pathPlugin);

  const element = logo.getBoundingClientRect();
  const letters = logo.querySelectorAll(':scope > path');
  const shape = logo.querySelector('g path');

  const sidebarPlaceholder = document.createElement('div');

  sidebarPlaceholder.style.position = 'absolute';
  sidebarPlaceholder.style.right = '100%';
  sidebarPlaceholder.style.top = '0';
  sidebarPlaceholder.style.bottom = '0';
  sidebarPlaceholder.style.opacity = '0';
  sidebarPlaceholder.style.backgroundColor = themeContext.gray.blendToBackground(75);
  sidebarPlaceholder.style.width = '248px';
  sidebarPlaceholder.style.borderRightWidth = '1px';
  sidebarPlaceholder.style.borderRightStyle = 'solid';
  sidebarPlaceholder.style.borderColor = themeContext.gray.blendToBackground(150);

  document.querySelector('.wrapper')?.appendChild(sidebarPlaceholder);

  if (element && letters) {
    gs.to(logoWrapper, {
      x: '0px',
      duration: 1.2,
    });
    gs.to(shape, {
      opacity: 0,
      duration: 0.6,
    });
    gs.to(logo, {
      motionPath: {
        path: [
          { x: -element.left / 1.9, y: -(element.top - 24) / 1.4 },
          { x: -(element.left + 5), y: -(element.top - 14) },
        ],
        alignOrigin: [0.5, 0.5],
        curviness: 0.75,
      },
      duration: 1,
    });
    gs.to(logo, {
      rotate: '5deg',
      repeat: 1,
      yoyo: true,
      duration: 0.5,
    });
    gs.to(logo, {
      scale: 0.6666,
      duration: 0.9,
    });
    gs.to(letters, {
      stagger: -0.08,
      '--logo-path-opacity': 1,
      '--logo-path-x': '0px',
      '--logo-path-y': '0px',
      duration: 1,
      onComplete: callback,
    });
    gs.to(panel, {
      '--panel-o': 0,
      '--panel-y': '0px',
      '--panel-s': 0.9,
      duration: 0.35,
    });
    gs.to(sidebarPlaceholder, {
      x: '100%',
      opacity: 1,
      duration: 0.8,
      delay: 0.2,
    });
  }
};

export const PageLogin: React.FC<PageLoginProps> = ({ isAuthFlow }) => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const { showSandboxBanner, setSession, signIn, setPhoneNumber, addParam, params, getRedirectUrlOrRedirect } =
    useSessionStore();
  const { getQueryParam, hasQueryParam } = useQueryParams<LoginQueryParams>();
  const logoWrapperRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<SVGSVGElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleReCaptcha } = reCaptchaCheck();

  const state = location.state as LocationState;

  const { control, getValues, handleSubmit } = useForm();

  const onSuccess = () => {
    if (isLoading) {
      return;
    }

    const logo = logoRef.current;
    const logoWrapper = logoWrapperRef.current;
    const panel = panelRef.current;

    setIsLoading(true);

    handleReCaptcha(
      (reCaptchaToken: string) => {
        UserRepository.login(getValues('email'), getValues('password'), reCaptchaToken)
          .then((response) => {
            log({
              name: 'User logged in',
              context: {
                email: getValues('email'),
              },
            });

            triggerEvent({
              category: 'User',
              action: 'Login',
            });

            setSession(response.sessionId, response.dashboardUserId);

            if (response.mfaStatus === 'NOT_REQUIRED') {
              signIn()
                .then(() => {
                  if (isAuthFlow && params && params?.redirect_uri) {
                    PlaidRepository.getTempCode()
                      .then((resp) => {
                        handlePlaidRedirectUrl(params as PlaidParamsType, {
                          code: resp.tempCode,
                        });
                      })
                      .catch(() => {
                        handlePlaidRedirectUrl(params as PlaidParamsType, {
                          error: 'temporarily_unavailable',
                        });
                      });
                    return;
                  }

                  const url = getRedirectUrlOrRedirect();
                  if (url) {
                    navigate(url);
                    return;
                  }

                  if (!logo || !logoWrapper || !panel) {
                    return;
                  }

                  animateLogo(logo, logoWrapper, panel, themeContext, gsap, MotionPathPlugin, () =>
                    navigate(APP_ROUTE.ROOT, {
                      state: { from: 'auth' },
                    })
                  );
                })
                .catch((error) => {
                  addDangerNotification({
                    content: error.message,
                    display: 'page',
                  });
                });
            } else {
              setPhoneNumber(response.phoneNumber);

              if (isAuthFlow) {
                navigate(ROUTE.PLAID_AUTH);
                return;
              }

              navigate(ROUTE.AUTH);
            }
          })
          .catch((error) => {
            setIsLoading(false);

            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          });
      },
      (err) => {
        setIsLoading(false);

        addDangerNotification({
          content: err.message,
          display: 'popup',
        });
      }
    );
  };

  useEffect(() => {
    Object.keys(PlaidParams).forEach((key: string) => {
      if (hasQueryParam(key)) {
        addParam(key, getQueryParam(key) as string | undefined);
      }
    });
  }, [location.search]);

  useEffect(() => {
    if (state && state?.action === 'loginToViewThisPage') {
      addDangerNotification({
        content: 'Sign in to view this page',
        display: 'page',
      });
    }
  }, [state?.action]);

  return (
    <>
      {!isAuthFlow && (
        <LogoWrapper ref={logoWrapperRef}>
          <AdditionalLogo size="24px" variant="full" ref={logoRef} />
        </LogoWrapper>
      )}

      <StyledPanel ref={panelRef}>
        {!isAuthFlow && <HiddenLogo size="24px" />}
        <Headline size="small">Sign in</Headline>
        <Form onSubmit={handleSubmit(onSuccess)}>
          <StyledNotificationList display="page" />
          <Rows>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: true,
                validate: validateEmail,
              }}
              render={({ field, fieldState: { error, isTouched } }) => (
                <Input placeholder="Email address" autoComplete="username" hasError={isTouched && !!error} {...field} />
              )}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState: { error, isTouched } }) => (
                <PasswordInput placeholder="Password" hasError={isTouched && !!error} {...field} />
              )}
            />
          </Rows>
          <Rows>
            <Button fullWidth isLoading={isLoading}>
              Sign in
            </Button>
            {!isAuthFlow && (
              <HintLink as={Link} to={ROUTE.FORGOT_PASSWORD}>
                Forgot your password?
              </HintLink>
            )}
          </Rows>
        </Form>
        {!isAuthFlow && (
          <Rows>
            <Paragraph size="small">Don’t have a Column account?</Paragraph>
            <Button variant="secondary" onClick={() => navigate(ROUTE.REGISTER)}>
              Create New Account
            </Button>
          </Rows>
        )}
      </StyledPanel>
    </>
  );
};
