import React, { MouseEvent, ReactNode, useEffect, useMemo, useState, useRef } from 'react';
import { Cell, Column, Row, useRowSelect, useTable, TableProps as ReactTableProps } from 'react-table';
import styled, { css } from 'styled-components';
import { Button, Icon, Tooltip } from '@column/column-ui-kit';
import { PopoverFilter, PopoverFilterEntry, PopoverList } from './Popover';
import { SearchBar } from './SearchBar';
import { LogoLoading } from '~/elements';
import { Box } from '~/styles';

export type { Cell };

export interface TableStyleProps {
  scrollable: boolean;
  maxHeight: string;
  isLoading: boolean;
}

export type TableColumn = Column & {
  percent?: number;
};

export interface TableProps extends Partial<TableStyleProps> {
  columns: TableColumn[];
  data: any[];
  filter?: PopoverFilterEntry[];
  onFilterChange?: (filter: PopoverFilterEntry[]) => void;
  onSearchSubmit?: (value: string) => void;
  searchTooltip?: ReactNode;
  searchShowReset?: boolean;
  searchLoading?: boolean;
  showColumnsFilter?: boolean;
  className?: string;
  action?: ReactNode;
  hasNextPage?: boolean;
  onRowClick?: (row: Row) => void;
  staticFilters?: boolean;
}

const TableWrapper = styled.div<TableStyleProps>`
  position: relative;
  margin-bottom: 24px;
`;

const Toolbar = styled.div<{ staticFilters?: boolean }>`
  display: flex;
  gap: 16px;
  padding: 0px 24px 16px;
  justify-content: space-between;
  background: ${({ theme }) => theme.background};
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  position: sticky;
  top: 52px;
  z-index: 10;

  ${({ staticFilters }) =>
    staticFilters &&
    css`
      top: auto;
      position: relative;
    `}
`;

const StyledPopoverFilter = styled(PopoverFilter)`
  --popover-top: 36px;
  --popover-left: 24px;
  z-index: 10;
`;

const ShowColumnFilter = styled.div`
  position: relative;
`;

const StyledPopoverList = styled(PopoverList)`
  --popover-left: auto;
  --popover-right: 0;
  --popover-top: 36px;
`;

const Count = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.primary.background};
  color: ${({ theme }) => theme.primary.foreground};
  font-size: 9px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  border-radius: 3px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;

const StyledTable = styled.table<ReactTableProps>`
  padding: 0 24px;
  border-spacing: 0;
  border: none;
  width: 100%;
  will-change: height;
  transition: height 0.2s;
`;

const StyledHead = styled.thead`
  display: table;
`;

const StyledHeadRow = styled.tr`
  width: 100%;
  display: table;
  table-layout: fixed;
`;

const TableHeader = styled.td`
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 600;
  display: table-cell;
  text-align: left;
  line-height: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const StyledHeadCell = styled.th<{ showToolbar: boolean; isAction: boolean }>`
  padding: 0;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  & > div {
    padding: 12px 10px 12px;
  }

  &:first-child {
    & > div {
      padding-left: 0;
    }
  }

  &:last-child {
    & > div {
      padding-right: 0;
    }
  }

  ${({ isAction }) =>
    isAction &&
    css`
      & > div > svg {
        display: none;
      }
    `}
`;

const StyledBody = styled.tbody<TableStyleProps>`
  display: block;
  transition: opacity 0.3s;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0;
      transition-duration: 0.1s;
    `}

  ${({ scrollable }) =>
    scrollable &&
    css<TableStyleProps>`
      max-height: ${({ maxHeight }) => maxHeight};
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      flex-grow: 1;

      &::-webkit-scrollbar {
        display: none;
      }
    `}
`;

const EmptyRow = styled.td`
  padding: 16px 0;
  font-size: 14px;
  font-style: italic;
  display: table-cell;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
`;

const StyledBodyRow = styled.tr<{ isClickable?: boolean }>`
  width: 100%;
  display: table;
  table-layout: fixed;
  position: relative;

  &:hover {
    td {
      position: relative;

      &:after,
      &:before {
        background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};

        ${({ theme }) =>
          theme.id !== 'default' &&
          css`
            background-color: ${theme.secondary.blendToBackground(25)};
          `}
      }

      &:first-child,
      &:last-child {
        &:before {
          background-color: ${({ theme }) => theme.secondary.blendToBackground(50)};

          ${({ theme }) =>
            theme.id !== 'default' &&
            css`
              background-color: ${theme.secondary.blendToBackground(25)};
            `}
        }
      }
    }
  }

  &:not(:last-child) {
    td {
      border-bottom: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};
    }
  }

  &:last-child {
    td {
      border-bottom: 1px solid transparent;
    }
  }

  ${({ isClickable }) =>
    isClickable &&
    css`
      &:hover {
        td {
          background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};

          ${({ theme }) =>
            theme.id !== 'default' &&
            css`
              background-color: ${theme.secondary.blendToBackground(15)};
            `}
        }
      }
    `}
`;

const Padding = styled.div``;

const StyledBodyCell = styled.td<{
  isTitle: boolean;
  isAction: boolean;
  isClickable: boolean;
}>`
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  display: table-cell;
  color: ${({ theme }) => theme.secondary.background};
  transition: background-color 0.1s;
  position: relative;

  & > div {
    padding: 12px 10px;
    position: relative;
    z-index: 1;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    transition: background-color 0.2s;
    background-color: transparent;
  }

  &:after {
    inset: -1px;
  }

  &:before {
    width: 16px;
    top: -1px;
    bottom: -1px;
  }

  &:first-child {
    & > div {
      padding-left: 0;
    }

    &:before {
      right: 100%;
      border-radius: 8px 0 0 8px;
    }
  }

  &:last-child {
    & > div {
      padding-right: 0;

      > div {
        display: flex;
        justify-content: flex-end;
      }
    }

    &:before {
      left: 100%;
      border-radius: 0 8px 8px 0;
    }
  }

  ${({ isTitle }) =>
    isTitle &&
    css`
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.foreground};
    `}

  ${({ isAction }) =>
    isAction &&
    css`
      & > div {
        display: flex;
        justify-content: flex-end;
      }
    `}

  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}
`;

const FullRow = styled.tr`
  width: 100%;
  display: table;
  text-align: center;
`;

const StyledLogoLoading = styled(LogoLoading)`
  top: calc(50% + 49px);
`;

export const Table: React.FC<TableProps> = (props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const headRef = useRef<HTMLTableSectionElement>(null);
  const bodyRef = useRef<HTMLTableSectionElement>(null);
  const [customList, setCustomList] = useState<string[] | boolean>(true);
  const [showFilterPopover, setShowFilterPopover] = useState<boolean>(false);
  const [showListPopover, setShowListPopover] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [prev, setPrev] = useState<number | null>(null);

  const styleProps: TableStyleProps = {
    scrollable: props.scrollable ?? false,
    maxHeight: props.maxHeight ?? '544px',
    isLoading: loading,
  };

  const data = useMemo(() => props.data, [props.data]);

  const columns = useMemo(
    () =>
      props.columns.map((column: Column) => {
        if (typeof column.accessor === 'string') {
          column.id = column.accessor;
        }
        return column;
      }),
    [props.columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setHiddenColumns,
    toggleHideAllColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useRowSelect
  );

  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>, row: Row) => {
    e.stopPropagation();

    if (props.onRowClick) {
      props.onRowClick(row);
    }
  };

  useEffect(() => {
    if (typeof customList === 'boolean') {
      toggleHideAllColumns(!customList);
    } else {
      setHiddenColumns(
        allColumns
          .filter((e: any) => !customList.includes(e.id))
          .map((e: any) => {
            if (e.id !== 'action') {
              return e.id;
            }
          })
      );
    }
  }, [customList]);

  useEffect(() => {
    if (!tableRef.current || !headRef.current || !bodyRef.current) {
      return;
    }

    const table = tableRef.current;
    const head = headRef.current;
    const body = bodyRef.current;

    if (props.isLoading) {
      setPrev(table.offsetHeight);
    }

    if (!props.isLoading) {
      if (!loading) {
        return;
      }

      if (prev && prev !== table.offsetHeight) {
        table.style.height = `${prev}px`;
      }

      setTimeout(() => {
        table.style.height = `${head.offsetHeight + body.offsetHeight}px`;

        setTimeout(() => {
          setLoading(false);
        }, 150);
      }, 0);

      return;
    }

    table.style.height = `${prev || table.offsetHeight}px`;

    setLoading(true);
  }, [props.isLoading]);

  const showToolbar = (props.filter && props.filter.length > 0) || props.showColumnsFilter || props.action;

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
  };

  const handleSearchSubmit = (value: string) => {
    if (props.onSearchSubmit) {
      props.onSearchSubmit(value);
    }
  };

  return (
    <TableWrapper className={props.className} {...styleProps}>
      {showToolbar && (
        <Toolbar {...props}>
          {props.filter && props.filter.length > 0 && (
            <Button variant="secondary" size="small" icon={<Icon.Filter />} onClick={() => setShowFilterPopover(true)}>
              Add Filter
              {props.filter.filter((e: PopoverFilterEntry) => e.show === true).length > 0 && (
                <Count>{props.filter.filter((e: PopoverFilterEntry) => e.show === true).length}</Count>
              )}
            </Button>
          )}
          {props.filter && (
            <StyledPopoverFilter
              show={props.filter && props.filter.length > 0 && showFilterPopover}
              options={props.filter}
              onOptionChange={(filter: PopoverFilterEntry[]) => {
                if (props.onFilterChange) {
                  props.onFilterChange(filter);
                }
              }}
              onClose={() => setShowFilterPopover(false)}
              onDone={() => setShowFilterPopover(false)}
              onReset={() => {
                if (props.filter && props.onFilterChange) {
                  props.onFilterChange(
                    props.filter.map((e: PopoverFilterEntry) => {
                      e.show = false;
                      return e;
                    })
                  );
                }
              }}
            />
          )}
          {props.onSearchSubmit &&
            (props.searchTooltip ? (
              <Tooltip
                offsetX={-22}
                offsetArrowX={-92}
                placement="top-start"
                content={props.searchTooltip}
                isOpen={searchFocus && searchTerm.length < 1}
              >
                <SearchBar
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onSearchSubmit={handleSearchSubmit}
                  onFocusChange={(value: boolean) => setSearchFocus(value)}
                  searchShowReset={props.searchShowReset}
                  placeholder="Search"
                  isLoading={props.searchLoading}
                />
              </Tooltip>
            ) : (
              <SearchBar
                value={searchTerm}
                onChange={handleSearchChange}
                onSearchSubmit={handleSearchSubmit}
                searchShowReset={props.searchShowReset}
                placeholder="Search"
                isLoading={props.searchLoading}
              />
            ))}
          <Actions>
            {props.showColumnsFilter && (
              <ShowColumnFilter>
                <Button
                  variant="subtle"
                  size="small"
                  icon={<Icon.TableEdit />}
                  onClick={() => setShowListPopover(true)}
                >
                  Settings
                </Button>
                <StyledPopoverList
                  show={showListPopover}
                  options={allColumns
                    .filter((e: any) => e.Header.length > 0 && e.id && e.id !== 'selection')
                    .map((entry: any) => ({
                      label: entry.Header,
                      id: entry.id,
                    }))}
                  active={customList}
                  onActiveChange={(options: string[] | boolean) => setCustomList(options)}
                  onClose={() => setShowListPopover(false)}
                  onDone={() => setShowListPopover(false)}
                  onReset={() => setCustomList(true)}
                />
              </ShowColumnFilter>
            )}
            {props.action}
          </Actions>
        </Toolbar>
      )}
      <StyledTable {...getTableProps()} ref={tableRef}>
        <StyledHead ref={headRef}>
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <StyledHeadRow {...headerGroup.getHeaderGroupProps()}>
              {(headerGroup.headers as any).map((column: any, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <StyledHeadCell
                  showToolbar={showToolbar}
                  isAction={index === headerGroup.headers.length}
                  {...column.getHeaderProps()}
                  style={{ width: column?.percent ? `${column.percent}%` : undefined }}
                >
                  <Padding>{column.render('Header')}</Padding>
                </StyledHeadCell>
              ))}
            </StyledHeadRow>
          ))}
        </StyledHead>
        <StyledBody {...styleProps} {...getTableBodyProps()} ref={bodyRef}>
          {rows.map((row: Row) => {
            prepareRow(row);
            const original = row.original as any;
            if (original?.type === 'header') {
              return (
                // eslint-disable-next-line react/jsx-key
                <FullRow {...row.getRowProps()}>
                  <TableHeader colSpan={columns.length}>{original.name}</TableHeader>
                </FullRow>
              );
            }
            return (
              // eslint-disable-next-line react/jsx-key
              <StyledBodyRow
                {...row.getRowProps()}
                isClickable={typeof props.onRowClick !== 'undefined'}
                onClick={(e: MouseEvent<HTMLTableRowElement>) => handleRowClick(e, row)}
              >
                {row.cells.map((cell, index) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <StyledBodyCell
                      isTitle={index === 0}
                      isAction={index === row.cells.length}
                      isClickable={typeof props.onRowClick !== 'undefined'}
                      {...cell.getCellProps()}
                      style={{
                        width: (columns[index] as any)?.percent ? `${(columns[index] as any).percent}%` : undefined,
                      }}
                    >
                      <Padding>{cell.render('Cell')}</Padding>
                    </StyledBodyCell>
                  );
                })}
              </StyledBodyRow>
            );
          })}
          {rows.length < 1 && (
            <FullRow>
              <EmptyRow colSpan={columns.length}>No entries found</EmptyRow>
            </FullRow>
          )}
        </StyledBody>
      </StyledTable>
      {loading && <StyledLogoLoading />}
    </TableWrapper>
  );
};
