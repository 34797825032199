import { client } from './client';
import { convertValues } from '~/util';
import {
  BankAccountListResponse,
  BankAccountCreateRequest,
  BankAccountWithDetails,
  BankAccountServiceListBankAccountsParams,
  BankAccountServiceUpdateBankAccountPayload,
  AccountNumberAccountNumber,
  AccountNumberListAccountNumbersResponse,
  AccountNumberServiceCreateAccountNumberPayload,
  AccountNumberServiceListAccountNumbersParams,
} from '~/typings/API';

type BankAccount = BankAccountWithDetails;
type AccountNumber = AccountNumberAccountNumber;

export type { BankAccount, AccountNumber, BankAccountListResponse };
export type CreateBankAccount = BankAccountCreateRequest;
export type UpdateBankAccount = BankAccountServiceUpdateBankAccountPayload;
export type CreateAccountNumber = AccountNumberServiceCreateAccountNumberPayload;
export type BankAccountFilterParams = BankAccountServiceListBankAccountsParams;

export interface GetBankAccountRequest {
  id: string;
}

export interface GetAccountNumberRequest {
  id: string;
}
const bankAccountCast = ({ defaultAccountNumber, routingNumber }: BankAccount) => ({
  defaultAccountNumber,
  routingNumber,
});

const accountNumberCast = ({ accountNumber, routingNumber }: AccountNumber) => ({
  accountNumber,
  routingNumber,
});

export class BankAccountRepository {
  static async create(request: CreateBankAccount) {
    return client
      .post<CreateBankAccount, BankAccount>('/bank-accounts', request)
      .then((response) => convertValues<BankAccount>(response, undefined, bankAccountCast(response)));
  }

  static async update(id: string, request: UpdateBankAccount) {
    return client
      .patch<UpdateBankAccount, BankAccount>(`/bank-accounts/${id}`, request)
      .then((response) => convertValues<BankAccount>(response, undefined, bankAccountCast(response)));
  }

  static async get(request: GetBankAccountRequest) {
    return client
      .get<unknown, BankAccount>(`/bank-accounts/${request.id}`)
      .then((response) => convertValues<BankAccount>(response, undefined, bankAccountCast(response)));
  }

  static async getAll(query?: Partial<BankAccountFilterParams>) {
    return client
      .get<BankAccountFilterParams, BankAccountListResponse>('/bank-accounts', { query })
      .then((response) => ({
        bankAccounts: Array.isArray(response?.bankAccounts)
          ? response.bankAccounts.map((e) => convertValues<BankAccount>(e, undefined, bankAccountCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }

  static async delete(id: string) {
    return client.delete<object, object>(`/bank-accounts/${id}`);
  }

  static async createAccountNumber(bankAccountId: string, accountNumber: CreateAccountNumber) {
    return client
      .post<CreateAccountNumber, AccountNumber>(`/bank-accounts/${bankAccountId}/account-numbers`, accountNumber)
      .then((response) => convertValues<AccountNumber>(response, undefined, accountNumberCast(response)));
  }

  static async getAccountNumber(request: GetAccountNumberRequest) {
    return client
      .get<unknown, AccountNumber>(`/account-numbers/${request.id}`)
      .then((response) => convertValues<AccountNumber>(response, undefined, accountNumberCast(response)));
  }

  static async getAllAccountNumber(
    bankAccountId: string,
    query?: Partial<AccountNumberServiceListAccountNumbersParams>
  ) {
    return client
      .get<AccountNumberServiceListAccountNumbersParams, AccountNumberListAccountNumbersResponse>(
        `/bank-accounts/${bankAccountId}/account-numbers`,
        {
          query,
        }
      )
      .then((response) => ({
        accountNumbers: Array.isArray(response?.accountNumbers)
          ? response.accountNumbers.map((e) => convertValues<AccountNumber>(e, undefined, accountNumberCast(e)))
          : [],
        hasMore: Boolean(response?.hasMore),
      }));
  }
}
