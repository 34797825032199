import chroma from 'chroma-js';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@column/column-ui-kit';

export interface ChartToggleStyleProps {
  color: string;
}

export interface ChartToggleProps extends Partial<ChartToggleStyleProps> {
  label: string;
  amount: number;
  className?: string;
  isChecked?: boolean;
  onCheckedChange?: (value: boolean) => void;
}

const Wrapper = styled.div<ChartToggleStyleProps>`
  padding: 8px;
  border-radius: 5px;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(200)};
  width: 100%;
  display: grid;
  grid-gap: 4px;
  font-size: 12px;
  box-sizing: border-box;
  letter-spacing: -0.125px;
  background-color: ${({ theme }) => theme.background};
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Check = styled(Checkbox)<ChartToggleStyleProps>`
  --checkbox-primary: ${({ color }) => color};
  --checkbox-primary-active: ${({ color }) => color};
  --checkbox-primary-pale: ${({ color }) => chroma(color).alpha(0.15).toString()};
`;

const Title = styled.div`
  color: ${({ theme }) => theme.secondary.background};
  font-weight: 500;
  line-height: 18px;
`;

const Amount = styled.div`
  line-height: 16px;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const AmountVar = styled.div<ChartToggleStyleProps>`
  color: ${({ color }) => color};
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
`;

export const ChartToggle: React.FC<ChartToggleProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(props.isChecked ?? false);

  const styleProps: ChartToggleStyleProps = {
    color: props.color ?? '#5884F3',
  };

  useEffect(() => {
    if (props.onCheckedChange) {
      props.onCheckedChange(checked);
    }
  }, [checked]);

  return (
    <Wrapper {...styleProps}>
      <Top>
        <Title>{props.label}</Title>
        <Check {...styleProps} isChecked={checked} onCheckedChange={(value: boolean) => setChecked(value)} />
      </Top>
      <Amount>
        <AmountVar {...styleProps}>{props.amount}</AmountVar>Total
      </Amount>
    </Wrapper>
  );
};
