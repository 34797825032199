import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { ReactNode } from 'react';
import { useModalStore } from './Modal';

type AlertDataType = 'danger' | 'info';
export type AlertSize = 'small' | 'medium';

export interface AlertData {
  [customKey: string]: any;
  closeTimeoutMs?: number;
  submitStay?: boolean;
  submitLoading?: boolean;
}

export interface AlertType {
  headline: string;
  text: string | ReactNode;
  submitText: string;
  size?: AlertSize;
  type?: AlertDataType;
  icon?: ReactNode;
  callback: (alertData?: AlertData) => void;
}

interface AlertStoreState {
  alert?: AlertType;
  alertData?: AlertData;
}

interface AlertStoreAction {
  openAlert: (alert: AlertType, alertData?: AlertData) => void;
  closeAlert: () => void;
  updateAlertData: (alertData: AlertData) => void;
}

export const useAlertStore = create<AlertStoreState & AlertStoreAction>()(
  subscribeWithSelector((set) => ({
    openAlert: (alert, alertData) => {
      set((state) => ({
        alert,
        alertData: { ...state.alertData, ...alertData },
      }));

      useModalStore.getState().openModal('Alert');
    },

    closeAlert: () => {
      setTimeout(() => {
        set(() => ({
          alert: undefined,
          alertData: {},
        }));
      }, 200);

      useModalStore.getState().closeModal('Alert');
    },

    updateAlertData: (alertData) => {
      set((state) => ({
        alertData: { ...state.alertData, ...alertData },
      }));
    },
  }))
);
