import React from 'react';
import styled from 'styled-components';
import { HelpSidebarBase } from './Base';
import { HelpSidebarType, useHelpSidebarStore } from '~/stores/HelpSidebar';
import { UnderlineLink } from '~/elements';

const CustomSidebarBase = styled(HelpSidebarBase)`
  width: 420px;
`;

const HelpSidebarContent = styled.div`
  margin-top: -8px;
  color: ${({ theme }) => theme.secondary.background};

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const Bottom = styled.div`
  margin-top: auto;
  padding-top: 24px;
`;

export const HelpSidebarCustom: React.FC = () => {
  const helpSidebar: HelpSidebarType[] = ['Custom'];
  const contentHelpSidebarCustom = useHelpSidebarStore((state) => state.contentHelpSidebarCustom);

  return (
    <CustomSidebarBase helpSidebars={helpSidebar} headline={contentHelpSidebarCustom?.headline}>
      <HelpSidebarContent>{contentHelpSidebarCustom?.content}</HelpSidebarContent>
      <Bottom>
        Need help? Contact <UnderlineLink href="mailto:support@column.com">support@column.com</UnderlineLink>
      </Bottom>
    </CustomSidebarBase>
  );
};
