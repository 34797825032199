import { create } from 'zustand';
import { subscribeWithSelector, persist, createJSONStorage } from 'zustand/middleware';
import { log, setCookie, deleteCookie, triggerEvent, deepMerge } from '~/util';
import { PlatformModel, PlatformType, UserModel } from '~/models';
import {
  DepgraphNodeValues,
  EntityWithDetails,
  PlatformMeta,
  PlatformRepository,
  PlatformRole,
  PlatformRolePermissions,
  Settings,
  userGroups,
} from '~/repositories';

const DEFAULT_SETTINGS: Partial<Settings> = {
  themeType: 'default',
  tableSettings: {
    loanPayments: {
      limit: 5,
    },
    loanDisbursements: {
      limit: 5,
    },
  },
  uploadSettings: {
    rdcFrontImageCrop: true,
    rdcBackImageCrop: true,
  },
};

class PlatformError extends Error {
  constructor(message?: string | undefined, context?: any, consoleLog?: boolean) {
    super(message ?? 'Platform error');

    if (consoleLog !== false) {
      console.error('PlatformError:', message, { context }, this);
    }

    this.name = 'PlatformError';
  }
}

type SessionStoreListener = () => void;
type SessionStoreEvent = 'accept_platform_invite' | 'signin' | 'signout' | 'change';

interface CurrentState {
  currentUser?: UserModel;
  currentPlatform?: PlatformModel;
  currentPermission?: PlatformRolePermissions;
  currentPermissionName?: string;
  platformRole?: PlatformRole;
}

interface SessionStoreState extends CurrentState {
  userId?: string;
  loggedIn?: boolean;
  phoneNumber?: string;
  redirectUrl?: string;
  params?: Record<string, string | undefined>;
  isLoading?: boolean;
  isSandbox?: boolean;
  showSandboxBanner?: boolean;
  rootEntity?: EntityWithDetails;
  rootEntitySandbox?: EntityWithDetails;
  eventListeners: Map<SessionStoreEvent, SessionStoreListener[]>;
  settings: Partial<Settings>;
}

interface SessionStoreComputed {
  isSignedIn: () => boolean;
  authRequired: () => boolean;
  readyForTestWithMoney: () => boolean | undefined;
  rootEntityVerified: () => boolean;
  currentRootEntity: () => EntityWithDetails | undefined;
}

interface SessionStoreAction {
  clearCookies: () => void;
  setLoading: (isLoading: boolean) => void;
  setSandbox: (isSandbox: boolean) => void;
  setSession: (sessionId: string, userId: string) => void;
  setRedirectUrl: (redirectUrl: string) => void;
  getRedirectUrlOrRedirect: () => string | undefined;
  setParams: (params?: Record<string, string | undefined>) => void;
  addParam: (key: string, value?: string) => void;
  setRootEntity: (rootEntity?: EntityWithDetails) => void;
  setRootEntitySandbox: (rootEntitySandbox?: EntityWithDetails) => void;
  setPhoneNumber: (phoneNumber?: string) => void;
  setPlatform: (platform: PlatformType & PlatformMeta, platformRole?: PlatformRole) => void;
  setSettings: (settings: Partial<Settings>) => void;
  updateSettings: (settings: Partial<Settings>) => Promise<Partial<Settings>>;
  changeTheme: () => Promise<Partial<Settings> | undefined>;
  loadPlatform: ({ fetchMeta, user }: { fetchMeta?: boolean; user?: UserModel }) => Promise<PlatformModel | undefined>;
  signIn: () => Promise<CurrentState | false>;
  signOut: () => void;
  triggerEvent: (event: SessionStoreEvent) => void;
  addEventListener: (event: SessionStoreEvent, listener: SessionStoreListener) => void;
}

export const useSessionStore = create<SessionStoreState & SessionStoreComputed & SessionStoreAction>()(
  subscribeWithSelector(
    persist(
      (set, get) => ({
        isLoading: true,
        isSandbox: true,
        showSandboxBanner: true,
        eventListeners: new Map<SessionStoreEvent, SessionStoreListener[]>(),
        settings: DEFAULT_SETTINGS,

        isSignedIn: () => {
          return get().loggedIn === true && get().userId !== undefined;
        },

        authRequired: () => {
          return !!get().phoneNumber && get().loggedIn === undefined;
        },

        readyForTestWithMoney: () => {
          return (
            get().rootEntity &&
            get().rootEntityVerified() &&
            get().currentUser?.isMfaVerified &&
            get().currentUser?.isEmailVerified
          );
        },

        rootEntityVerified: () => {
          return !!get().rootEntity && get().rootEntity?.verificationStatus === 'VERIFIED';
        },

        currentRootEntity: () => {
          return get().currentPlatform?.isLiveEnabled ? get().rootEntity : get().rootEntitySandbox;
        },

        clearCookies: () => {
          set(() => ({
            userId: undefined,
            loggedIn: undefined,
            phoneNumber: undefined,
          }));

          deleteCookie('session_id');
        },

        setLoading: (isLoading: boolean) => {
          set(() => ({
            isLoading,
          }));
        },

        setSandbox: (isSandbox: boolean) => {
          set(() => ({
            isSandbox,
            showSandboxBanner: isSandbox && get().currentPermission?.platformInfo !== 'none',
          }));
        },

        setSession: (sessionId: string, userId: string) => {
          set(() => ({
            userId,
          }));
          setCookie({
            key: 'session_id',
            value: sessionId,
          });
        },

        setRedirectUrl: (redirectUrl: string) => {
          set(() => ({
            redirectUrl,
          }));
        },

        setParams: (params?: Record<string, string | undefined>) => {
          set(() => ({
            params,
          }));
        },

        addParam: (key: string, value?: string) => {
          set((state) => ({
            params: {
              ...(state.params ?? {}),
              [key]: value,
            },
          }));
        },

        setRootEntity: (rootEntity?: EntityWithDetails) => {
          set(() => ({
            rootEntity,
          }));
        },

        setRootEntitySandbox: (rootEntitySandbox?: EntityWithDetails) => {
          set(() => ({
            rootEntitySandbox,
          }));
        },

        setPhoneNumber: (phoneNumber?: string) => {
          set(() => ({
            phoneNumber,
          }));
        },

        setPlatform: (platform: (PlatformType & PlatformMeta) | PlatformType, platformRole?: PlatformRole) => {
          const userId = get().currentUser?.id;

          if (userId && get().currentPlatform === platform) {
            return true;
          }

          if (!userId) {
            throw new PlatformError("Can't set platform", { userId, platform, platformRole });
          }

          set(() => ({
            currentPermission: platformRole?.platformLevelPermissions,
            currentPlatform: new PlatformModel(platform),
            currentPermissionName: platformRole?.name ? userGroups[platformRole.name] : undefined,
          }));

          if (!platform.isLiveEnabled) {
            get().setSandbox(true);
          }

          return true;
        },

        setSettings: (settings: Partial<Settings>) => {
          set((state) => ({
            settings: deepMerge(state.settings, settings),
          }));
        },

        updateSettings: async (settings: Partial<Settings>) => {
          const { UserRepository } = await import('~/repositories');

          const updatedSettings: Partial<Settings> = { ...DEFAULT_SETTINGS, ...deepMerge(get().settings, settings) };

          get().setSettings(updatedSettings);

          const userSettings = await UserRepository.updateSettings(updatedSettings);

          return userSettings;
        },

        changeTheme: async () => {
          triggerEvent({
            category: 'Theme',
            action: 'Switch mode',
          });

          switch (get().settings.themeType) {
            case 'default': {
              return get().updateSettings({ themeType: 'dark' });
            }
            case 'dark': {
              return get().updateSettings({ themeType: 'default' });
            }
          }
        },

        getRedirectUrlOrRedirect: () => {
          const redirectUrl = get().redirectUrl;

          if (!redirectUrl) {
            return undefined;
          }

          log({
            name: `Redirected to ${redirectUrl}`,
            context: get().currentUser,
          });

          const isAbsoluteUrl = (testUrl: string): boolean => /^(https?:\/\/)/i.test(testUrl);

          const getRelativeUrl = (testUrl: string): string => (testUrl.startsWith('/') ? testUrl : `/${testUrl}`);

          if (isAbsoluteUrl(redirectUrl)) {
            window.location.href = redirectUrl;
            get().setRedirectUrl('');
            return;
          }

          const url = getRelativeUrl(redirectUrl);
          get().setRedirectUrl('');
          return url;
        },

        loadPlatform: async (args?: { fetchMeta?: boolean; user?: UserModel }) => {
          const { fetchMeta, user } = args ?? {};
          const currentUserState = user ?? get().currentUser;
          const currentPlatformRole = get().platformRole;

          if (typeof currentUserState?.defaultPlatformId === 'undefined' || !currentUserState?.defaultPlatformId) {
            throw new PlatformError('Default platform id not found', { currentUserState, currentPlatformRole });
          }

          let platformType: PlatformType;
          try {
            platformType = await PlatformRepository.get(currentUserState.defaultPlatformId);
          } catch (error) {
            if (error === 'Unauthorized') {
              return undefined;
            }

            throw error;
          }

          let platformMeta: PlatformMeta = {};
          let platformDependencyGraph: DepgraphNodeValues = {};
          if (fetchMeta) {
            try {
              platformMeta = await PlatformRepository.getMeta(currentUserState.defaultPlatformId);
              platformDependencyGraph = await PlatformRepository.getDependencyGraph(currentUserState.defaultPlatformId);
            } catch (error) {
              log({
                name: 'failed to load platform metadata',
                context: { error },
                type: 'warn',
              });
            }
          }

          const platform = {
            ...platformType,
            ...platformMeta,
            depgraphNodeValues: platformDependencyGraph,
          };

          get().setPlatform(platform, currentPlatformRole);
          return new PlatformModel(platform);
        },

        signIn: async () => {
          const userId = get().userId;

          if (!userId) {
            throw new Error('Please try to login again');
          }

          get().setLoading(true);

          try {
            const { UserRepository } = await import('~/repositories');
            const user = await UserRepository.fetchSelf();
            const userSettings = await UserRepository.getSettings();
            const currentUser = new UserModel(user);

            const platformRolesResponse = await UserRepository.platformRoles({ dashboardUserId: userId });

            const platformRole = platformRolesResponse.platformRoles.find(
              (role) => userId === role.dashboardUserId && user.defaultPlatformId === role.platformId
            );

            if (!currentUser?.id || !platformRole) {
              throw new Error('Unauthorized');
            }

            set(() => ({
              currentUser,
              platformRole,
              currentPermission: platformRole?.platformLevelPermissions,
              loggedIn: true,
            }));

            const platform = await get().loadPlatform({ user: currentUser });

            if (typeof platform === 'undefined') {
              return false;
            }

            get().setSettings(userSettings);

            get().triggerEvent('signin');
            get().triggerEvent('change');

            setTimeout(() => get().setLoading(false), 0);

            return { currentUser };
          } catch (error) {
            get().signOut();

            if (error === 'Unauthorized') {
              return false;
            }

            if (error instanceof PlatformError) {
              return error;
            }

            log({
              name: 'useSessionStore.signIn',
              context: { error, userId, currentUser: get().currentUser, currentPlatform: get().currentPlatform },
            });

            throw new Error('User error, contact support@column.com');
          } finally {
            get().setLoading(false);
          }
        },

        signOut: () => {
          log({
            name: 'Signing out user',
            context: get().currentUser,
          });

          get().clearCookies();

          set(() => ({
            currentUser: undefined,
            currentPlatform: undefined,
            userId: undefined,
            loggedIn: false,
          }));

          get().setLoading(false);

          get().triggerEvent('signout');
          get().triggerEvent('change');
        },

        triggerEvent: (event: SessionStoreEvent) => {
          if (get().eventListeners.has(event)) {
            for (const listener of get().eventListeners.get(event)!) {
              listener();
            }
          }
        },

        addEventListener: (event: SessionStoreEvent, listener: SessionStoreListener) => {
          if (!get().eventListeners.has(event)) {
            get().eventListeners.set(event, []);
          }
          get().eventListeners.get(event)?.push(listener);
        },
      }),
      {
        name: 'column-dashboard',
        version: 1.2,
        storage: createJSONStorage(() => localStorage),
        partialize: (state) => ({
          userId: state.userId,
          loggedIn: state.loggedIn,
          redirectUrl: state.redirectUrl,
          phoneNumber: state.phoneNumber,
          isSandbox: state.isSandbox,
          showSandboxBanner: state.showSandboxBanner,
          params: state.params,
          settings: state.settings,
        }),
      }
    )
  )
);
