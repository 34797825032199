import React from 'react';
import { ToggleHeight } from '@column/column-ui-kit';

interface AccordionEntry {
  label: React.ReactNode | string;
  content: React.ReactNode | string;
  onClick?: (index: number) => void;
  kyc?: boolean;
}

interface AccordionProps {
  className?: string;
  entries: AccordionEntry[];
  active?: number[] | number;
  devider?: React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = (props) => {
  const entries = props.entries.map((entry: AccordionEntry, entryIndex: number) => {
    const handleClick = (index: number) => {
      if (entry.onClick) {
        entry.onClick(index);
      }
    };

    return (
      <div key={entryIndex}>
        <div className={props.className}>
          <div onClick={() => handleClick(entryIndex)}>{entry.label}</div>
          <ToggleHeight
            isClose={
              !(props.active === entryIndex) || (Array.isArray(props.active) && props.active.includes(entryIndex))
            }
          >
            {entry.content}
          </ToggleHeight>
        </div>
        {entryIndex + 1 !== props.entries.length && props.devider}
      </div>
    );
  });

  return <div>{entries}</div>;
};
