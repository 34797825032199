import React, { FC, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, Dropdown, AmountInput, Input, Tooltip, Icon } from '@column/column-ui-kit';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Wrapper } from './Authenticate';
import { Form } from './Counterparty';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore, ModalType } from '~/stores/Modal';
import { ImageLoading, NotificationList, FormField } from '~/components';
import { FormElement, FormLabel, Headline } from '~/styles';
import { currenciesUSD, formatNumber, preventSpaceInput, validateNumber } from '~/util';
import { useBankAccounts, useDebounce, useInstitutions } from '~/hooks';
import { CreateCheckDepositScanner } from '~/app/pages/Transfers/Add';

const EditCheck = styled(Wrapper)`
  --modal-width: 480px;
`;

const Fields = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 -20px -20px -20px;
  padding: 20px;
  border-radius: 0 0 12px 12px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}
`;

const RoutingInput = styled(Input)`
  display: grid;
  grid-template-columns: auto 16px;
  grid-gap: 8px;
`;

const Institution = styled(Tooltip)<{ isChecked?: boolean }>`
  margin: -1px;

  pointer-events: ${({ isChecked }) => (isChecked ? 'auto' : 'none')};
`;

const Check = styled(Icon.CircleCheck)<{ isChecked?: boolean }>`
  --icon-size: 18px;
  --icon-color: ${({ theme }) => theme.success.blendToBackground(1100)};

  path {
    &:first-child {
      stroke-dashoffset: ${({ isChecked }) => (isChecked ? '0px' : '48px')};
      stroke-dasharray: 48px;
      transition: stroke-dashoffset 0.15s ease;
    }

    &:last-child {
      stroke-dashoffset: ${({ isChecked }) => (isChecked ? '0px' : '9px')};
      stroke-dasharray: 9px;
      transition: stroke-dashoffset 0.25s ease ${({ isChecked }) => (isChecked ? '0.15s' : '0s')};
    }
  }
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 32px;
`;

const Submit = styled(Button)`
  margin-left: auto;
`;

const CheckImage = styled(ImageLoading)`
  max-height: 100px;
`;

export const ModalEditCheck: FC = () => {
  const { response: bankAccounts, setQueryParams: searchBankAccounts } = useBankAccounts();
  const { createRequest: fetchInstitutions } = useInstitutions();

  const { closeModal } = useModalStore();
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const modalTypes: ModalType[] = ['EditCheck'];
  const [modalDataState, setModalDataState] = useState<
    Partial<CreateCheckDepositScanner> & {
      callback?: (data: CreateCheckDepositScanner) => void;
      deleteCallback?: () => void;
    }
  >({});

  const methods = useForm<CreateCheckDepositScanner>();
  const { handleSubmit, setValue, trigger } = methods;

  const [institutionName, setInstitutionName] = useState<string>('');

  const getInstitutions = useDebounce((routingNumber: string) => {
    fetchInstitutions(routingNumber)
      .then((data) => {
        setInstitutionName(data?.fullName ?? '');
      })
      .catch(() => {
        setInstitutionName('');
      })
      .finally(() => {
        setTimeout(() => trigger('micrLine.payorBankRoutingNumber'), 0);
      });
  }, 200);

  const handleDeleteCheckEntry = useCallback(() => {
    setTimeout(() => {
      closeModal('EditCheck');

      if (modalDataState.deleteCallback) {
        modalDataState.deleteCallback();
      }
    }, 200);
  }, [modalDataState]);

  useEffect(
    () =>
      useModalStore.subscribe(
        (state) => state.getModalData(),
        (modalData) => {
          const data = modalData;

          if (!data) {
            return;
          }

          const routingNumber = data.micrLine?.payorBankRoutingNumber;

          setModalDataState(data);

          Object.keys(data).map((key) => {
            if (key === 'type' || key === 'callback' || key === 'deleteCallback') {
              return;
            }

            const val = data[key as keyof CreateCheckDepositScanner];

            if (key === 'depositedAmount') {
              setValue('depositedAmount', Number(data.depositedAmount), { shouldValidate: true, shouldTouch: true });
              return;
            }

            setValue(key as keyof CreateCheckDepositScanner, val, { shouldValidate: true, shouldTouch: true });
          });

          if (routingNumber) {
            getInstitutions(routingNumber);
          }
        },
        {
          fireImmediately: true,
        }
      ),
    []
  );

  const onError: SubmitHandler<FieldValues> = useCallback((errors) => {
    const errorMessages: string[] = [];

    const extractMessages = (errorObject: any) => {
      if (errorObject.hasOwnProperty('message') && errorObject.message.length > 0) {
        errorMessages.push(errorObject.message);
      } else {
        Object.values(errorObject).forEach((value) => {
          if (typeof value === 'object' && value !== null) {
            extractMessages(value);
          }
        });
      }
    };

    Object.values(errors).forEach(extractMessages);

    if (errorMessages.length === 1) {
      addDangerNotification({
        display: 'popup',
        content: errorMessages[0],
      });
    }

    if (errorMessages.length > 1) {
      addDangerNotification({
        display: 'popup',
        content: (
          <>
            <h4>Please fix the following errors:</h4>
            <ul>
              {errorMessages.map((text) => (
                <li key={text}>{text}</li>
              ))}
            </ul>
          </>
        ),
      });
    }
  }, []);

  const onSuccess = (data: CreateCheckDepositScanner) => {
    if (modalDataState.callback) {
      modalDataState.callback(data);
    }

    closeModal('EditCheck');
  };

  return (
    <EditCheck modalTypes={modalTypes}>
      <StyledHeadline>Edit Check Info</StyledHeadline>
      <NotificationList display="popup" />
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSuccess, onError)}>
          <Fields>
            <FormElement>
              <FormLabel>Front</FormLabel>
              <CheckImage base64String={modalDataState?.imageFrontJPG} errorMessage="Front image could not be loaded" />
            </FormElement>
            <FormElement>
              <FormLabel>Back</FormLabel>
              <CheckImage base64String={modalDataState?.imageBackJPG} errorMessage="Back image could not be loaded" />
            </FormElement>
            <FormField<CreateCheckDepositScanner>
              id="depositedAmount"
              label="Amount"
              oneColumn
              rules={{ required: true, validate: (v) => (Number(v) > 0 ? true : 'Please input a valid amount') }}
            >
              {({ value, onChange, onBlur }, { isTouched, error }) => (
                <AmountInput
                  hasError={isTouched && !!error}
                  value={Number(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  currencyList={currenciesUSD}
                  fixedCurrencyCode="USD"
                />
              )}
            </FormField>
            <FormField<CreateCheckDepositScanner>
              id="bankAccountId"
              label="Bank Account"
              oneColumn
              rules={{ required: true }}
            >
              {({ value, onChange, onBlur }, { error }) => (
                <Dropdown
                  options={
                    bankAccounts?.bankAccounts.map((bankAccount) => ({
                      label: `${
                        bankAccount?.displayName && bankAccount?.description
                          ? `${bankAccount.displayName} – ${bankAccount.description}`
                          : bankAccount?.description || 'Unnamed'
                      } (${formatNumber(bankAccount?.balances?.availableAmount)})`,
                      small: bankAccount.id,
                      value: bankAccount.id,
                    })) ?? []
                  }
                  active={value}
                  fullWidth
                  maxWidth="640px"
                  search
                  positionRight
                  searchLabel="Search for description"
                  onSearchChange={(description: string) => searchBankAccounts({ description })}
                  onChange={onChange}
                  onOpenChange={(open) => !open && onBlur()}
                  hasError={!!error}
                />
              )}
            </FormField>
            <FormField<CreateCheckDepositScanner>
              id="micrLine.payorBankRoutingNumber"
              label="Routing #"
              oneColumn
              rules={{
                required: true,
                validate: (value) =>
                  validateNumber(value) && !!institutionName ? true : 'Please input a valid routing number',
              }}
            >
              {({ value, onChange, onBlur }, { isTouched, error }) => (
                <RoutingInput
                  placeholder="123456789"
                  hasError={isTouched && !!error}
                  value={String(value)}
                  onKeyDown={preventSpaceInput}
                  onChange={(v: string) => {
                    onChange(v);
                    getInstitutions(v);
                  }}
                  onBlur={onBlur}
                >
                  <Institution content={institutionName} isChecked={!!institutionName}>
                    <Check isChecked={!!institutionName} />
                  </Institution>
                </RoutingInput>
              )}
            </FormField>
            <FormField<CreateCheckDepositScanner>
              id="accountNumber"
              label="Account Number"
              oneColumn
              rules={{
                required: true,
                validate: (value) => (validateNumber(value) ? true : 'Please input a valid account number'),
              }}
            >
              {({ value, onChange, onBlur }, { isTouched, error }) => (
                <Input
                  hasError={isTouched && !!error}
                  value={(value ?? '') as string}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            <FormField<CreateCheckDepositScanner>
              id="checkNumber"
              label="Check Number"
              oneColumn
              rules={{
                validate: (value) => (!value || validateNumber(value) ? true : 'Please input a valid check number'),
              }}
            >
              {({ value, onChange, onBlur }, { isTouched, error }) => (
                <Input
                  hasError={isTouched && !!error}
                  value={(value ?? '') as string}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            </FormField>
            <FormField<CreateCheckDepositScanner> id="description" label="Payee Name" oneColumn>
              {({ value, onChange, onBlur }, { isTouched, error }) => (
                <Input
                  hasError={isTouched && !!error}
                  value={(value ?? '') as string}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              )}
            </FormField>
          </Fields>
          <Buttons>
            <Button variant="secondary" type="button" onClick={() => closeModal()}>
              Cancel
            </Button>
            <Button variant="danger" type="button" onClick={handleDeleteCheckEntry}>
              Delete
            </Button>
            <Submit>Confirm</Submit>
          </Buttons>
        </Form>
      </FormProvider>
    </EditCheck>
  );
};
