const ROUTE_PREFIX = '';

export const ROUTE = {
  ROOT: `${ROUTE_PREFIX}/`,
  LOGIN: `${ROUTE_PREFIX}/login`,
  JOIN: `${ROUTE_PREFIX}/join`,
  AUTH: `${ROUTE_PREFIX}/auth`,
  PLAID_LOGIN: `${ROUTE_PREFIX}/plaid/oauth2/login`,
  PLAID_AUTH: `${ROUTE_PREFIX}/plaid/oauth2/auth`,
  PLAID_SELECT: `${ROUTE_PREFIX}/plaid/oauth2/select`,
  REGISTER: `${ROUTE_PREFIX}/register`,
  REGISTER_QUESTIONS: `${ROUTE_PREFIX}/register/questions`,
  REGISTER_FINANCIAL_PRODUCTS: `${ROUTE_PREFIX}/register/questions/financial-products`,
  REGISTER_COMPANY_EMPLOYEES: `${ROUTE_PREFIX}/register/questions/company-employees`,
  REGISTER_CUSTOMERS: `${ROUTE_PREFIX}/register/questions/customers`,
  REGISTER_COMPLIANCE: `${ROUTE_PREFIX}/register/questions/compliance`,
  REGISTER_OPEN_BANK_ACCOUNT: `${ROUTE_PREFIX}/register/open-bank-account`,
  REGISTER_BUSINESS: `${ROUTE_PREFIX}/register/business`,
  REGISTER_BUSINESS_INFORMATION: `${ROUTE_PREFIX}/register/business/information`,
  REGISTER_BUSINESS_LOCATION: `${ROUTE_PREFIX}/register/business/location`,
  REGISTER_BUSINESS_CUSTOMER_TYPES: `${ROUTE_PREFIX}/register/business/customer-types`,
  REGISTER_BUSINESS_YOUR_INFORMATION: `${ROUTE_PREFIX}/register/business/your-information`,
  REGISTER_BUSINESS_SUBMIT: `${ROUTE_PREFIX}/register/business/submit`,
  FORGOT_PASSWORD: `${ROUTE_PREFIX}/forgot-password`,
  VERIFY: `${ROUTE_PREFIX}/email_verifications/verify/:code`,
  NEW_PASSWORD: `${ROUTE_PREFIX}/new_password/:code`,
  CONTACT: `${ROUTE_PREFIX}/contact`,
} as const;
