import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { LogoLoading } from '~/elements';
import { ROUTE as APP_ROUTE } from '~/app/routes';
import { ROUTE as PUBLIC_ROUTE } from '~/public/routes';

interface RegisteredUserSearchParams {
  inviteCode: string;
  platformId: string;
  promptRegister: false;
}

interface UnregisteredUserSearchParams {
  email: string;
  inviteCode: string;
  platformId: string;
  promptRegister: true;
}

type SearchParams = RegisteredUserSearchParams | UnregisteredUserSearchParams;

export const PageJoin: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const params = parseSearchParams(searchParams);
    if (!params) {
      navigate(PUBLIC_ROUTE.LOGIN);
      return;
    }

    if (params.promptRegister) {
      navigate(
        getUnregisteredUserRoute({ email: params.email, inviteCode: params.inviteCode, platformId: params.platformId })
      );
      return;
    }

    navigate(getRegisteredUserRoute({ inviteCode: params.inviteCode, platformId: params.platformId }));
  }, []);

  return <LogoLoading />;
};

const getRegisteredUserRoute = ({ inviteCode, platformId }: { inviteCode: string; platformId: string }) => {
  const params = new URLSearchParams({ code: inviteCode, invite: platformId });
  return `${APP_ROUTE.PLATFORMS}?${params.toString()}`;
};

const getUnregisteredUserRoute = ({
  email,
  inviteCode,
  platformId,
}: {
  email: string;
  inviteCode: string;
  platformId: string;
}) => {
  const params = new URLSearchParams({ code: inviteCode, email, invite: platformId });
  return `${PUBLIC_ROUTE.REGISTER}?${params.toString()}`;
};

const parseSearchParams = (searchParams: URLSearchParams): SearchParams | null => {
  const email = searchParams.get('email');
  const inviteCode = searchParams.get('code');
  const platformId = searchParams.get('invite');
  const promptRegisterStr = searchParams.get('prompt_register');
  const promptRegister = promptRegisterStr ? promptRegisterStr.toLowerCase() === 'true' : null;

  if (!inviteCode) {
    return null;
  }

  if (!platformId) {
    return null;
  }

  if (promptRegister) {
    if (!email) {
      return null;
    }
    return { email, inviteCode, platformId, promptRegister: true };
  }

  return { inviteCode, platformId, promptRegister: false };
};
