import React, { ElementType, FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { SegmentedControlOptionProps } from '@column/column-ui-kit';

interface Entry extends Partial<SegmentedControlOptionProps> {
  path: string;
  hide?: boolean;
  activePath?: string;
}

interface CustomPageProps {
  entries?: Entry[];
  Component: ElementType;
}

export type CustomPageContext = { entries?: Entry[] };

const PageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const CustomPage: FC<CustomPageProps> = ({ Component, entries }) => {
  return (
    <PageWrapper>
      <Component entries={entries}>
        <Outlet />
      </Component>
    </PageWrapper>
  );
};
