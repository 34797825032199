import { Tooltip } from '@column/column-ui-kit';
import styled, { css } from 'styled-components';

export const FormElement = styled.div<{ fullWidth?: boolean; newRow?: boolean }>`
  display: grid;
  grid-gap: 4px;
  position: relative;
  align-content: start;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      grid-column: 1 / 3;
    `}

  ${({ newRow }) =>
    newRow &&
    css`
      grid-column-start: 1;
    `}
`;

export const FormFields = styled.div`
  display: grid;
  grid-gap: 24px;
`;

export const FormLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.background};
  position: relative;

  a {
    color: ${({ theme }) => theme.primary.background};
    text-decoration: none;
    display: table;
    position: absolute;
    right: 0;
    top: 0;
  }

  small {
    line-height: 16px;
    font-size: 12px;
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }
`;

export const FormLabelTooltip = styled(Tooltip)`
  display: inline-block;
  vertical-align: top;
  margin: 2px 0 2px 4px;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(700)};
    --icon-size: 16px;
  }

  &:hover {
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(900)};
    }
  }
`;

export const FormText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 0;
  color: ${({ theme }) => theme.foreground};
  word-break: break-all;
`;

export const FormParagraphLight = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 8px 0;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

export const Divider = styled(FormElement)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 11px;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.secondary.blendToBackground(500)};

  &:before,
  &:after {
    content: '';
    height: 1px;
    flex: 1 1;
    background-color: ${({ theme }) => theme.secondary.blendToBackground(150)};
  }
`;

export const FormGroup = styled.div`
  display: grid;
  gap: 24px;
  padding-bottom: 24px;
  grid-template-columns: 1fr;
`;

export const FormElementAction = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto min-content;
`;
