import React, { useRef, FC } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface AchIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const AchIcon: FC<AchIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={20}>
        <path d="M2.75 13V17.25H7" stroke="currentColor" strokeWidth="1.5" />
        <path d="M2.75 17.25L7 13" stroke="currentColor" strokeWidth="1.5" />
        <path d="M17.25 7V2.75H13" stroke="currentColor" strokeWidth="1.5" />
        <path d="M17.25 2.75L13 7" stroke="currentColor" strokeWidth="1.5" />
        <path
          d="M12 6H8C6.89543 6 6 6.89543 6 8V12C6 13.1046 6.89543 14 8 14H12C13.1046 14 14 13.1046 14 12V8C14 6.89543 13.1046 6 12 6Z"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </IconBase>
    </Wrapper>
  );
};
