import React, { useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { PopoverEvents } from '~/components';
import { EventsIcon } from '~/elements/icons';
import { useSessionStore } from '~/stores/Session';

const Wrapper = styled.div`
  position: relative;
`;

const Action = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  gap: 4px;
  cursor: pointer;
  align-items: center;
  position: relative;
  align-items: center;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: transparent;
  box-shadow:
    0 0 0 1px ${({ theme }) => theme.gray.blendToBackground(150, 0)},
    0 0 0 var(--button-outline, 0px)
      var(--button-outline-color, ${({ theme }) => theme.primary.blendToBackground(1000, 0)}) inset,
    0 0 0 0 transparent;
  transition:
    box-shadow 0.2s,
    background-color 0.2s;

  svg {
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    --icon-size: 16px;
    transition: stroke 0.2s;
  }

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(75)};
    box-shadow:
      0 0 0 1px ${({ theme }) => theme.gray.blendToBackground(150)} inset,
      0 0 0 var(--button-outline, 0px)
        var(--button-outline-color, ${({ theme }) => theme.primary.blendToBackground(1000, 150)}),
      0 0 0 0 transparent;

    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(1000)};
    }

    ${({ theme }) =>
      theme.id !== 'default' &&
      css`
        background-color: ${() => theme.secondary.blendToBackground(20)};
        box-shadow:
          0 0 0 1px ${() => theme.gray.blendToBackground(100)} inset,
          0 0 0 var(--button-outline, 0px)
            var(--button-outline-color, ${() => theme.primary.blendToBackground(1000, 150)}),
          0 0 0 0 transparent;
      `};
  }
`;

const ActionLabel = styled.div`
  display: flex;
  flex: auto;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  transition: color 0.2s;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.secondary.background};

  & > svg {
    --icon-size: 16px;
  }
`;

export const Events: React.FC = () => {
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const actionRef = useRef<HTMLDivElement>(null);
  const { currentPlatform } = useSessionStore();

  const handleTriggerClick = useCallback(() => {
    setShowPopover((s) => !s);
  }, []);

  const handlePopoverClose = useCallback(
    (event: MouseEvent) => {
      if (!(event.target instanceof Element)) {
        return;
      }
      if (actionRef.current && (event.target === actionRef.current || actionRef.current.contains(event.target))) {
        return;
      }

      setShowPopover(false);
    },
    [actionRef, currentPlatform?.isLiveEnabled]
  );

  return (
    <Wrapper>
      <Action ref={actionRef} onClick={handleTriggerClick}>
        <EventsIcon size={16} />
        <ActionLabel>Events</ActionLabel>
      </Action>
      <PopoverEvents show={showPopover} onClose={handlePopoverClose} />
    </Wrapper>
  );
};
