import { gsap } from 'gsap';
import MotionPathPlugin from 'gsap/MotionPathPlugin';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { Button } from '@column/column-ui-kit';
import { HiddenLogo, AdditionalLogo, StyledPanel, LogoWrapper, animateLogo } from './Login';
import { ROUTE } from '~/app/routes';
import { Headline, Paragraph } from '~/styles';
import { useSessionStore } from '~/stores/Session';
import { log } from '~/util';
import { useNotificationStore } from '~/stores/Notification';
import { NotificationList } from '~/components';

const Text = styled.div`
  display: grid;
  grid-gap: 8px;
`;

interface Params {
  code: string;
}

export const PageVerify: React.FC = () => {
  const { showSandboxBanner } = useSessionStore();
  const addDangerNotification = useNotificationStore((state) => state.addDangerNotification);
  const logoWrapperRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<SVGSVGElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { code } = useParams<keyof Params>() as Params;
  const themeContext = useContext(ThemeContext);

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentUser,
        (currentUser) => {
          if (!currentUser) {
            return;
          }

          currentUser
            .verifyEmail(code)
            .then(() => {
              log({
                name: 'User email verified',
                context: {
                  email: currentUser.email,
                },
              });
            })
            .catch((error) => {
              log({
                name: 'User email verification failed',
                context: {
                  error,
                },
              });

              addDangerNotification({
                content: 'Verification, failed. Please try again.',
                display: 'page-verify',
              });
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        {
          fireImmediately: true,
        }
      ),
    [code]
  );

  const handleRedirect = () => {
    if (!logoRef.current || !logoWrapperRef.current || !panelRef.current) {
      return;
    }

    animateLogo(
      logoRef.current,
      logoWrapperRef.current,
      panelRef.current,
      themeContext,
      gsap,
      MotionPathPlugin,
      () =>
        navigate(ROUTE.ROOT, {
          state: { from: 'auth' },
        }),
      showSandboxBanner
    );
  };

  return (
    <>
      <LogoWrapper ref={logoWrapperRef}>
        <AdditionalLogo size="24px" variant="full" ref={logoRef} />
      </LogoWrapper>
      <StyledPanel ref={panelRef}>
        <HiddenLogo size="24px" />
        <Text>
          <div>
            <NotificationList display="page-verify" />
            <Headline size="small">Verified!</Headline>
          </div>
          <Paragraph>Email address successfully verified.</Paragraph>
        </Text>
        <Button onClick={handleRedirect} isLoading={isLoading}>
          Back to Dashboard
        </Button>
      </StyledPanel>
    </>
  );
};
