import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon, PasswordInput } from '@column/column-ui-kit';
import { Wrapper, IconCircle, Text } from './Authenticate';
import { Form } from './Counterparty';
import { useNotificationStore } from '~/stores/Notification';
import { useModalStore, ModalType } from '~/stores/Modal';
import { useSessionStore } from '~/stores/Session';
import { ROUTE } from '~/public/routes';
import { NotificationList } from '~/components';
import { Headline, Inner, Paragraph } from '~/styles';
import { UserRepository } from '~/repositories';
import { reCaptchaCheck } from '~/util/reCaptcha';

const Logout = styled.a`
  color: ${({ theme }) => theme.secondary.blendToBackground(500)};
  transition: color 0.2s;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: table;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.secondary.background};
  }
`;

const StyledNotificationList = styled(NotificationList)`
  margin: 0 0 16px;
`;

export const ModalPasswordPrompt: React.FC = () => {
  const { closeModal, replaceModal } = useModalStore();
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const { currentUser, setSession, signIn, setPhoneNumber, triggerEvent } = useSessionStore();
  const modalTypes: ModalType[] = ['PasswordPrompt'];

  const { control, getValues, handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { handleReCaptcha } = reCaptchaCheck();

  const onSuccess = () => {
    if (currentUser?.email) {
      setLoading(true);

      handleReCaptcha(
        (reCaptchaToken: string) => {
          UserRepository.login(currentUser?.email, getValues('password'), reCaptchaToken)
            .then((response) => {
              setSession(response.sessionId, response.dashboardUserId);
              if (response.mfaStatus === 'NOT_REQUIRED') {
                signIn()
                  .then(() => {
                    closeModal('PasswordPrompt');
                  })
                  .catch((error) => {
                    addDangerNotification({
                      content: error.message,
                      display: 'popup',
                    });
                  });
              } else {
                setPhoneNumber(response.phoneNumber);
                replaceModal('Authenticate');
              }
              reset();
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              addDangerNotification({
                content: error.message,
                display: 'popup',
              });
            });
        },
        (err) => {
          setLoading(false);

          addDangerNotification({
            content: err.message,
            display: 'popup',
          });
        }
      );

      return;
    }
    navigate({ pathname: ROUTE.LOGIN });
  };

  const handleLogout = (e: React.MouseEvent) => {
    e.preventDefault();

    navigate({ pathname: ROUTE.LOGIN });
  };

  return (
    <Wrapper withClose={false} modalTypes={modalTypes}>
      <IconCircle>
        <Icon.AnimationLock />
      </IconCircle>
      <Text>
        <Headline fullWidth size="small">
          Session expired
        </Headline>
        <Paragraph>Enter password to log back in.</Paragraph>
      </Text>

      <StyledNotificationList display="popup" />
      <Form onSubmit={handleSubmit(onSuccess)}>
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field, fieldState: { error, isTouched } }) => (
            <PasswordInput placeholder="Password" hasError={isTouched && !!error} {...field} />
          )}
        />
        <Button fullWidth isLoading={loading}>
          Sign in
        </Button>
        <Logout onClick={handleLogout}>Logout</Logout>
      </Form>
    </Wrapper>
  );
};
