import { Button, Radio } from '@column/column-ui-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import {
  Option,
  OptionLabel,
  OptionList,
  RegisterAction,
  RegisterContent,
  RegisterContentGrid,
  StyledCheckbox,
} from '../Questions';
import { Form } from '../../Login';
import { Address } from './Partial/Address';
import { Countries } from './Partial/Countries';
import { ROUTE } from '~/public/routes';
import { FormFields, FormElement, Headline, Paragraph, SubHeadline, Hint, FormLabel, Line } from '~/styles';
import {
  AddressType,
  BusinessPageIdType,
  DepgraphNodeValues,
  GoogleFile,
  GoogleFileRepository,
  validDocumentExtensions,
} from '~/repositories';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { FileUpload, NotificationList } from '~/components';
import { Columns } from '~/public/pages/Register/Business/Partial/Person';
import { LearnMore } from '~/elements';
import { helpSidebarContent } from '~/util/helpSidebarContent';

export const FormElementHint = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  margin: -2px 0 4px 0;
`;

const StyledLine = styled(Line)`
  margin: 24px 0;
`;

export const PageRegisterBusinessLocation: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locationsOfBusinessOperation, setLocationsOfBusinessOperation] = useState<string[]>(['']);
  const navigate = useNavigate();
  const useFormMethods = useForm<DepgraphNodeValues>();
  const [physicalAddressIsRegisteredAddress, setPhysicalAddressIsRegisteredAddress] = useState<boolean>(false);
  const [physicalAddressIsMailingAddress, setPhysicalAddressIsMailingAddress] = useState<boolean>(false);
  const [registeredAddressIsMailingAddress, setRegisteredAddressIsMailingAddress] = useState<boolean>(false);
  const { handleSubmit, reset, watch, setValue } = useFormMethods;

  const handlePhysicalAddressIsRegisteredAddress = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      setPhysicalAddressIsRegisteredAddress(value);
      return;
    }
    setPhysicalAddressIsRegisteredAddress((s) => !s);
  }, []);

  const handlePhysicalAddressIsMailingAddress = useCallback((value?: boolean) => {
    if (!value) {
      setRegisteredAddressIsMailingAddress(false);
    }
    if (typeof value === 'boolean') {
      setPhysicalAddressIsMailingAddress(value);
      return;
    }
    setPhysicalAddressIsMailingAddress((s) => !s);
  }, []);

  const isSameAddress = (l: AddressType, r: AddressType) => {
    return (
      l.line1 === r.line1 &&
      l.line2 === r.line2 &&
      l.city === r.city &&
      l.state === r.state &&
      l.countryCode === r.countryCode &&
      l.postalCode === r.postalCode
    );
  };

  const onSuccess = (depgraphNodeValues: DepgraphNodeValues) => {
    if (isLoading) {
      return;
    }

    if (physicalAddressIsRegisteredAddress) {
      depgraphNodeValues.entityRegisteredAddress = depgraphNodeValues.entityPhysicalAddress;
    }
    if (physicalAddressIsMailingAddress) {
      depgraphNodeValues.entityMailingAddress = depgraphNodeValues.entityPhysicalAddress;
    }
    if (registeredAddressIsMailingAddress) {
      depgraphNodeValues.entityMailingAddress = depgraphNodeValues.entityRegisteredAddress;
    }

    if (locationsOfBusinessOperation.filter(Boolean).length < 1) {
      addDangerNotification({
        content: 'Please select at least one country.',
        display: 'business-countries',
      });
      return;
    }
    if (!depgraphNodeValues.entityPhysicalAddressProof) {
      addDangerNotification({
        content: 'Please upload a proof documentation',
        display: 'physical-address-proof',
      });
      return;
    }

    setIsLoading(true);

    currentPlatformState
      ?.updateDepgraphNodeValues({
        locationsOfBusinessOperation,
        entityPhysicalAddress: depgraphNodeValues.entityPhysicalAddress,
        entityPhysicalAddressProof: depgraphNodeValues.entityPhysicalAddressProof,
        entityRegisteredAddress: depgraphNodeValues.entityRegisteredAddress,
        entityMailingAddress: depgraphNodeValues.entityMailingAddress,
        completedPages: [
          ...new Set<BusinessPageIdType>([
            ...(currentPlatformState.depgraphNodeValues?.completedPages ?? []),
            'location',
          ]),
        ],
      })
      .then(() => {
        navigate(ROUTE.REGISTER_BUSINESS_CUSTOMER_TYPES);
      })
      .catch((error) => {
        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentPlatform,
        (currentPlatform) => {
          reset(currentPlatform?.depgraphNodeValues);

          setLocationsOfBusinessOperation(currentPlatform?.depgraphNodeValues?.locationsOfBusinessOperation ?? ['']);

          const physicalAddr = currentPlatform?.depgraphNodeValues?.entityPhysicalAddress;
          const registeredAddr = currentPlatform?.depgraphNodeValues?.entityRegisteredAddress;
          const mailingAddr = currentPlatform?.depgraphNodeValues?.entityMailingAddress;

          if (physicalAddr && registeredAddr && mailingAddr) {
            setPhysicalAddressIsRegisteredAddress(isSameAddress(physicalAddr, registeredAddr));
            setPhysicalAddressIsMailingAddress(isSameAddress(physicalAddr, mailingAddr));
            setRegisteredAddressIsMailingAddress(isSameAddress(registeredAddr, mailingAddr));
          }
        },
        { fireImmediately: true }
      ),
    []
  );

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>Business Location</Headline>
        <Paragraph size="small">We would like to know where your business and its operations are located.</Paragraph>
      </RegisterContentGrid>
      <FormProvider {...useFormMethods}>
        <Form onSubmit={handleSubmit(onSuccess)}>
          <NotificationList display="page" />
          <RegisterContent>
            <div>
              <NotificationList display="business-countries" />
              <Countries
                value={locationsOfBusinessOperation}
                onValueChange={setLocationsOfBusinessOperation}
                formLabel="Where does your business operate? Only include countries with physical offices."
              />
            </div>

            <FormFields>
              <FormElement>
                <SubHeadline>Physical address</SubHeadline>
                <FormElementHint>
                  This is the physical address of your company and cannot be a virtual address. {'  '}
                  <LearnMore headline="Physical address">{helpSidebarContent.physicalAddress}</LearnMore>
                </FormElementHint>
              </FormElement>
              <OptionList>
                <Option
                  isActive={physicalAddressIsRegisteredAddress}
                  onClick={() => handlePhysicalAddressIsRegisteredAddress()}
                >
                  <StyledCheckbox
                    isChecked={physicalAddressIsRegisteredAddress}
                    onCheckedChange={handlePhysicalAddressIsRegisteredAddress}
                  />
                  <OptionLabel>Physical address is also the registered address</OptionLabel>
                  <Hint>
                    Registered address is found on your state registration or Employer Identification Number documents.
                  </Hint>
                </Option>
                <Option
                  isActive={physicalAddressIsMailingAddress}
                  onClick={() => handlePhysicalAddressIsMailingAddress()}
                >
                  <StyledCheckbox
                    isChecked={physicalAddressIsMailingAddress}
                    onCheckedChange={handlePhysicalAddressIsMailingAddress}
                  />
                  <OptionLabel>Physical address is also the mailing address</OptionLabel>
                  <Hint>Mailing address is your address for mails and packages.</Hint>
                </Option>
              </OptionList>
              <Address id="entityPhysicalAddress" formLabel="" />
              <StyledLine />
              <RegisterContentGrid>
                <SubHeadline>Physical address proof</SubHeadline>
                <Paragraph size="small">
                  Please upload a document which verifies your physical address (e.g., utility bill, rent contract,
                  etc.)
                </Paragraph>
                <NotificationList display="physical-address-proof" />
                <FileUpload<GoogleFile>
                  handleUpload={(file) =>
                    GoogleFileRepository.upload(
                      {
                        subfolderName: 'CIP',
                        file,
                      },
                      currentPlatformState?.id as string
                    )
                  }
                  name="phyiscal-address-proof"
                  description="Physical address proof"
                  onFileUpload={(file: GoogleFile) => setValue('entityPhysicalAddressProof', file)}
                  onFileRemove={(file: GoogleFile) => setValue('entityPhysicalAddressProof', undefined)}
                  renderFileName={(file) => <>{file?.fileName}</>}
                  allowedExtensions={validDocumentExtensions}
                  file={watch('entityPhysicalAddressProof')}
                />
              </RegisterContentGrid>
            </FormFields>

            {!physicalAddressIsRegisteredAddress && (
              <FormFields>
                <FormElement>
                  <StyledLine />
                  <SubHeadline>Registered address</SubHeadline>
                  <FormElementHint>
                    This will be the address found on your state registration or Employer Identification Number
                    documents.
                  </FormElementHint>
                </FormElement>
                {!physicalAddressIsMailingAddress && (
                  <>
                    <FormElement>
                      <FormLabel>Is this also the mailing address?</FormLabel>
                      <Columns>
                        <Option
                          isActive={registeredAddressIsMailingAddress}
                          isSimple
                          onClick={() => setRegisteredAddressIsMailingAddress(true)}
                        >
                          <Radio
                            isChecked={registeredAddressIsMailingAddress}
                            onCheckedChange={() => setRegisteredAddressIsMailingAddress(true)}
                            label="Yes"
                          />
                        </Option>
                        <Option
                          isActive={!registeredAddressIsMailingAddress}
                          isSimple
                          onClick={() => setRegisteredAddressIsMailingAddress(false)}
                        >
                          <Radio
                            isChecked={!registeredAddressIsMailingAddress}
                            onCheckedChange={() => setRegisteredAddressIsMailingAddress(false)}
                            label="No"
                          />
                        </Option>
                      </Columns>
                    </FormElement>
                  </>
                )}

                <Address id="entityRegisteredAddress" formLabel="" />
              </FormFields>
            )}

            {!physicalAddressIsMailingAddress && !registeredAddressIsMailingAddress && (
              <FormFields>
                <FormElement>
                  <StyledLine />
                  <SubHeadline>Mailing address</SubHeadline>
                  <FormElementHint>This will be your address for mail. Virtual addresses are okay.</FormElementHint>
                </FormElement>
                <Address id="entityMailingAddress" formLabel="" />
              </FormFields>
            )}
          </RegisterContent>
          <RegisterAction>
            <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER_BUSINESS_INFORMATION)} type="button">
              Back
            </Button>
            <Button isLoading={isLoading} isDisabled={false}>
              Continue
            </Button>
          </RegisterAction>
        </Form>
      </FormProvider>
    </RegisterContent>
  );
};
