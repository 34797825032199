import React from 'react';
import styled from 'styled-components';
import { ROUTE } from '~/app/routes';
import { EditPage, Headline, Line } from '~/styles';
import { UnderlineLink } from '~/elements';

const Content = styled.div`
  display: grid;
  grid-gap: 32px;
  max-width: 780px;
`;

const Section = styled.div`
  display: grid;
  grid-gap: 12px;
`;

const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.foreground};
  font-weight: 500;
  margin: 0;
  font-size: 18px;
  line-height: 28px;
`;

const SectionContent = styled.div<{ $isItalic?: boolean }>`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.secondary.background};
  font-style: ${({ $isItalic }) => $isItalic && 'italic'};

  strong {
    color: ${({ theme }) => theme.secondary.blendToBackground(1100)};
    font-weight: 500;
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  small {
    display: block;
    font-size: 14px;
    font-style: italic;
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
  }

  ul {
    margin: 12px 0;
    padding: 0 0 0 16px;
    display: grid;
    grid-gap: 8px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    li {
    }
  }
`;

export const PageSVBResources: React.FC = () => {
  return (
    <EditPage fullWidth smallGap>
      <Headline>SVB Resources</Headline>
      <Content>
        <SectionContent $isItalic>
          Please note that all wires that have not been processed by SVB on Thursday, March 9th and Friday, March 10th
          have been cancelled. This means your funds are now available to initiate an ACH credit and/or wire to your
          Column account. Funds received via wire from an SVB account will be made available to you immediately, and ACH
          credits will be made available as soon as they settle at Column. You will be able to initiate any outgoing
          transfer from Column with these funds.
        </SectionContent>

        <Line />

        <SectionContent>
          Welcome to Column! We know it has been a chaotic few days, and we look forward to serving your needs in the
          most efficient and transparent way. As an FDIC-insured, nationally chartered bank built for complex commercial
          and payments focused customers, we understand your needs acutely and are here to help. Please see below for
          answers to some SVB specific questions you probably have. We recommend checking back periodically as this
          situation remains fluid.
        </SectionContent>

        <Section>
          <SectionTitle>Who can I contact about the state of my deposits or uninsured funds with SVB?</SectionTitle>
          <SectionContent>
            Please contact the FDIC Call Center at 1-866-799-0959 with any additional questions regarding your deposits
            at SVB. Contact Column at{' '}
            <UnderlineLink withoutArrow href="mailto:onboarding@column.com">
              onboarding@column.com
            </UnderlineLink>{' '}
            with any questions on how we can help you and your business.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>
            I initiated a transfer from SVB last week or this weekend. What is the status of this transaction(s)?
          </SectionTitle>
          <SectionContent>
            If the wire or ACH transaction does not show up in your dashboard at Column, the transaction did not leave
            SVB. We reconcile our data constantly throughout the day and do not miss payments. After reviewing payments
            activity across our customer base, it appears that if a transaction was initiated after Thursday morning, it
            did not leave SVB. You should <strong>not</strong> assume this payment will clear on Monday. We are
            recommending that people resubmit their wires when SVB opens and (hopefully) funds become available on
            Monday or early this coming week.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>
            I initiated an ACH debit from my Column account to pull funds from my SVB account last week or this weekend.
            What is the status of this transaction(s)?
          </SectionTitle>
          <SectionContent>
            ACH debits initiated on Thursday, Friday and this weekend debiting from SVB to Column have not cleared. The
            Fed and FDIC are still determining if and how these funds should be cleared, and they are in a pending state
            until banks receive regulatory clarification on how to handle these funds. Historical precedence would
            suggest that these funds will most likely get pulled back and be part of the SVB receivership. Our primary
            goal is to get you your funds as quickly as possible, and we are cautiously optimistic that we’ll receive
            regulatory guidance before opening hours on Monday. If we are allowed to clear these funds we will do so
            immediately as we recognize that these are immensely critical.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Can I transfer funds from SVB to Column on Monday?</SectionTitle>
          <SectionContent>
            If you are trying to move funds from SVB to Column, we highly recommend you wire your money out of SVB on
            Monday regardless of what you did last week. You should not assume wires initiated last week will be
            automatically processed, and these should be resubmitted. In an abundance of caution, we also do not
            recommend submitting ACH debits to pull from your SVB account or initiating ACH credits out. The FDIC{' '}
            <UnderlineLink
              newTab
              withoutArrow
              href="https://www.fdic.gov/resources/resolutions/bank-failures/failed-bank-list/silicon-valley.html"
            >
              has provided information about the process to claim funds from SVB
            </UnderlineLink>
            , and we recommend you follow these steps.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>How will Column handle potential incoming ACH debits from SVB?</SectionTitle>
          <SectionContent>
            To protect your funds and accounts, all incoming ACH debits from SVB attempting to pull funds out of your
            Column accounts will be automatically returned. This will remain in place until we have further guidance
            from the FDIC.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>
            I’m concerned about meeting payroll and/or paying my invoices as soon as possible. What are my options?
          </SectionTitle>
          <SectionContent>
            To minimize disruptions to your business, we recommend arranging for your bill pay services and automatic
            payments to go through your new Column account as soon as possible. Please note that we are not making funds
            received from SVB available for customer withdrawal indefinitely until the Fed provides guidance. However,
            if you would like to fund your Column account with incoming payments from other financial institutions, we
            are fully able to support and allow those outflows.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>
            Can I send funds this weekend or do I have to wait till Monday to initiate transfers?
          </SectionTitle>
          <SectionContent>
            Silicon Valley Bank banking activities, including online banking, billy pay, and other services will not be
            available over the weekend of March 11th and March 12th, 2023. However, the FDIC announced these services
            will resume no later than Monday, March 13th, 2023.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>Can I send funds to Column from other banks?</SectionTitle>
          <SectionContent>
            To transfer funds into Column from a bank other than SVB or out of Column, things should be a bit more
            straightforward. We still recommend that you initiate a wire from your other bank account to your Column
            account. These funds should be made available to you when they settle and are released, and won't be subject
            to other restrictions. You can initiate a transfer from Column via Wire or ACH at any time, in your
            dashboard or via API.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>What amount of my deposits can I get FDIC insured?</SectionTitle>
          <SectionContent>
            Our standard FDIC coverage per customer is $250,000. However, given you're working directly with us as a
            bank, for our strategic customers, we have the unique ability to get FDIC insurance coverage for up to 100%
            of your deposits (up to $150mm FDIC insured). For this, we will have a minimum balance requirement and
            expect a longer-term strategic relationship.
          </SectionContent>
        </Section>

        <Section>
          <SectionTitle>What details do I need to initiate a wire transfer?</SectionTitle>
          <SectionContent>
            <ul>
              <li>Column Routing number: 121145307</li>
              <li>For international wires, SWIFT BIC: CLNOUS66</li>
              <li>
                Column Account number: you can find this in your{' '}
                <UnderlineLink withoutArrow to={ROUTE.BANK_ACCOUNTS}>
                  Accounts page
                </UnderlineLink>
                , by clicking on a specific account, and looking at the Operating Account Number.
              </li>
              <li>
                Column Address (if required): Column N.A., 1 Letterman Drive, Suite A4-700, San Francisco, CA 94129,
                United States.
                <small>
                  Our branch address may populate instead: 1717 Mangrove Avenue, Chico, CA 95926. This works as well.
                </small>
              </li>
            </ul>
          </SectionContent>
        </Section>
      </Content>
    </EditPage>
  );
};
