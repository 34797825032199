import React, { HTMLProps, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { Calendar, DateObj, useDayzed } from 'dayzed';
import { Button, Icon } from '@column/column-ui-kit';
import { getDateFormat, adjustTimezone } from '~/util';
import { Months } from '~/util/dateFilterHelpers';

export interface DatepickerProps {
  date?: string | string[];
  showDate?: string;
  onDateSubmit?: (date: string | string[] | undefined) => void;
  onHoverDate?: (date: string | undefined) => void;
  disableBefore?: Date;
  disableAfter?: Date;
  showMonths?: number;
}

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Wrapper = styled.div`
  position: relative;
  display: flex;
  gap: 32px;
  flex-grow: 1;
  width: 100%;
`;

const CalendarWrapper = styled.div`
  width: 100%;
`;

const Back = styled(Button)`
  position: absolute;
  left: 0;
  top: 0;
  height: 28px;
  width: 28px;
`;

const Forward = styled(Button)`
  position: absolute;
  right: 0;
  top: 0;
  height: 28px;
  width: 28px;
`;

const MonthYear = styled.div`
  line-height: 28px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.foreground};
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin-top: 8px;
`;

const Weekday = styled.div`
  font-size: 13px;
  line-height: 28px;
  height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};
`;

const Day = styled.button<{ isActive?: boolean; isToday?: boolean; isInactive?: boolean; isOtherMonth?: boolean }>`
  appearance: none;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.foreground};
  font-size: 14px;
  line-height: 28px;
  height: 28px;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  transition:
    background-color 0.2s,
    box-shadow 0.2s,
    color 0.2s;

  ${({ isActive, isInactive }) =>
    !isActive &&
    !isInactive &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.secondary.blendToBackground(100)};
      }
    `}

  ${({ isToday, isActive }) =>
    isToday &&
    !isActive &&
    css`
      color: ${({ theme }) => theme.primary.background};
      background-color: ${({ theme }) => theme.primary.blendToBackground(100)};

      &:hover {
        background-color: ${({ theme }) => theme.primary.blendToBackground(150)};
      }
    `}

  ${({ isOtherMonth }) =>
    isOtherMonth &&
    css`
      color: ${({ theme }) => theme.secondary.blendToBackground(600)};

      &:hover {
        color: ${({ theme }) => theme.secondary.blendToBackground(800)};
      }
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.primary.foreground};
      background-color: ${({ theme }) => theme.primary.background};
    `}

  ${({ isInactive }) =>
    isInactive &&
    css`
      color: ${({ theme }) => theme.secondary.blendToBackground(400)};
    `}

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Datepicker: React.FC<DatepickerProps> = (props) => {
  const propDate = useMemo(() => {
    if (!props.date) {
      return undefined;
    }
    if (Array.isArray(props.date)) {
      return props.date.map((d) => adjustTimezone(new Date(d)));
    }
    return adjustTimezone(new Date(props.date));
  }, [props.date]);

  const currentDate = useMemo(() => {
    const value = Array.isArray(props.date) ? props.date[props.date.length - 1] : props.date;
    const current = props.showDate
      ? adjustTimezone(new Date(props.showDate))
      : value
        ? adjustTimezone(new Date(value))
        : undefined;

    const setDate = current ?? adjustTimezone(new Date());

    if (props.showMonths && props.showMonths > 1) {
      setDate.setMonth(setDate.getMonth() - (props.showMonths - 1));
    }

    return setDate;
  }, [props.showDate]);

  const { calendars, getBackProps, getForwardProps, getDateProps } = useDayzed({
    selected: propDate,
    date: currentDate,
    onDateSelected: ({ date: selectedDate, selected }) => {
      if (!props.onDateSubmit) {
        return;
      }
      if (Array.isArray(propDate)) {
        const newDates = [...propDate];
        if (propDate.length) {
          if (propDate.length === 1) {
            const first = propDate[0];
            if (first < selectedDate) {
              newDates.push(selectedDate);
            } else {
              newDates.unshift(selectedDate);
            }
            props.onDateSubmit(newDates.map((n) => getDateFormat(n)));
          } else if (newDates.length === 2) {
            props.onDateSubmit([getDateFormat(selectedDate)]);
          }
        } else {
          newDates.push(selectedDate);
          props.onDateSubmit(newDates.map((n) => getDateFormat(n)));
        }
        return;
      }
      props.onDateSubmit(!selected ? getDateFormat(selectedDate) : undefined);
    },
    minDate: props.disableBefore,
    maxDate: props.disableAfter,
    monthsToDisplay: props.showMonths ?? 1,
    showOutsideDays: true,
  });

  const [hoverDate, setHoverDate] = useState<Date | undefined>(undefined);

  const backProps = getBackProps({ calendars }) as HTMLProps<HTMLButtonElement>;
  const forwardProps = getForwardProps({ calendars }) as HTMLProps<HTMLButtonElement>;

  const isInRange = (checkDate: Date) => {
    if (Array.isArray(propDate) && propDate.length) {
      const firstSelected = propDate[0];
      if (propDate.length === 2) {
        const secondSelected = propDate[1];
        return firstSelected < checkDate && secondSelected > checkDate;
      } else {
        return (
          hoverDate &&
          ((firstSelected < checkDate && hoverDate >= checkDate) ||
            (checkDate < firstSelected && checkDate >= hoverDate))
        );
      }
    }
    return false;
  };

  return (
    <Wrapper
      onMouseLeave={() => {
        if (!Array.isArray(propDate)) {
          return;
        }

        if (props.onHoverDate) {
          props.onHoverDate(undefined);
        }

        setHoverDate(undefined);
      }}
    >
      <Back type="button" size="tiny" variant="secondary" onClick={backProps.onClick} isDisabled={backProps.disabled}>
        <Icon.ChevronLeft />
      </Back>
      <Forward
        type="button"
        size="tiny"
        variant="secondary"
        onClick={forwardProps.onClick}
        isDisabled={forwardProps.disabled}
      >
        <Icon.ChevronRight />
      </Forward>
      {calendars.map((calendar: Calendar, index: number) => (
        <CalendarWrapper key={index}>
          <MonthYear>
            {Months[calendar.month]} {calendar.year}
          </MonthYear>
          <Grid>
            {weekdays.map((weekday) => (
              <Weekday key={weekday}>{weekday.substring(0, 2)}</Weekday>
            ))}

            {calendar.weeks.map((week, weekIndex) =>
              week.map((dateObj, dayIndex: number) => {
                if (!dateObj) {
                  return <div key={`${calendar.month}-week-${weekIndex}-day-${dayIndex}`} />;
                }

                const { date, selected, selectable, today, prevMonth, nextMonth } = dateObj as DateObj;

                return (
                  <Day
                    key={`${calendar.month}-week-${weekIndex}-day-${dayIndex}`}
                    isToday={today}
                    isActive={selected || (Array.isArray(propDate) && isInRange(date))}
                    isInactive={!selectable}
                    isOtherMonth={prevMonth || nextMonth}
                    {...getDateProps({
                      dateObj,
                      onMouseEnter: () => {
                        if (!Array.isArray(propDate)) {
                          return;
                        }

                        if (props.onHoverDate) {
                          props.onHoverDate(getDateFormat(date));
                        }

                        setHoverDate(date);
                      },
                    } as DateObj | any)}
                    type="button"
                  >
                    {date.getDate()}
                  </Day>
                );
              })
            )}
          </Grid>
        </CalendarWrapper>
      ))}
    </Wrapper>
  );
};
