import { createApiHook } from './utils/createApiHook';
import {
  CounterpartyRepository,
  CounterpartiesList,
  CounterpartyListParams,
  Counterparty,
  GetCounterpartyRequest,
} from '~/repositories/CounterpartyRepository';

export const useCounterparties = createApiHook<CounterpartiesList, CounterpartyListParams>(
  CounterpartyRepository.getAll,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
    includeQueryParams: true,
  }
);

export const useCounterparty = createApiHook<Counterparty, GetCounterpartyRequest>(CounterpartyRepository.get);
