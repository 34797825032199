import { DropdownOption } from '@column/column-ui-kit';
import { client } from './client';
import { uploadFile } from './util/upload';
import { convertValues } from '~/util';
import { useSessionStore } from '~/stores/Session';

export type SubfolderName = 'CIP' | 'CDD';

export interface UploadGoogleFileRequest {
  file: File;
  subfolderName: SubfolderName;
}

export interface GoogleFile {
  fileId?: string;
  fileName?: string;
  revisionId?: string;
  webViewUrl?: string;
  downloadUrl?: string;
}

export class GoogleFileRepository {
  static async upload(request: UploadGoogleFileRequest, platformId: string) {
    return uploadFile<UploadGoogleFileRequest, GoogleFile>('/dashboard/onboarding/depgraph/upload-file', request, {
      platformId,
      isSandbox: false,
    }).then((response) => convertValues<GoogleFile>(response));
  }
}
