import React, {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
  ReactNode,
} from 'react';
import styled, { css } from 'styled-components';
import { gsap } from 'gsap';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import { Button, Icon } from '@column/column-ui-kit';
import {
  calculateDistance,
  calculateRotate,
  clampNumber,
  createParticles,
  getCirclePath,
  moveCircle,
  resetCircle,
} from './utils';
import { NotificationList } from '~/components';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';

gsap.registerPlugin(MorphSVGPlugin);

export type FileUploadStatus = 'loading' | 'success' | 'default';

/**
 * Defines the properties for a file upload component, allowing for custom
 * handling of file uploads and fetch operations, as well as flexible UI rendering
 * based on the file data.
 *
 * @template Response The type of data expected in return from the upload or fetch operations.
 *
 * @property {(file: File) => Promise<Response>} handleUpload - Function to handle the file upload process. Takes a `File` object as input and returns a `Promise` that resolves to a response of type `Response`.
 * @property {(id: string) => Promise<Response>} handleFetch - Optional Function to handle fetching file details. Takes an identifier string as input and returns a `Promise` that resolves to a response of type `Response`.
 * @property {string} [id] - Optional unique identifier for the file upload instance. If provided, the component will attempt to fetch file details using the `handleFetch` function.
 * @property {Response} [file] - Optional file data to display in the file upload component. If provided, the component will render the file details based on this data.
 * @property {(file: Response) => ReactNode} renderFileName - Function to render the file name or details based on the file data. Receives the file data as its argument and returns a `ReactNode` to display the file information.
 * @property {string} [className] - Optional CSS class name for styling the file upload component.
 * @property {string} description = Description text to display above the file upload component.
 * @property {string[]} [allowedExtensions] - Optional array of allowed file extensions for the upload, used to restrict the file types that can be uploaded.
 * @property {string | false} [preventFileUpload] - Optional message to display when file uploads are prevented. If set to `false`, file uploads are not prevented. Defaults to `undefined`.
 * @property {(response: Response) => void} [onFileUpload] - Optional callback function invoked after a successful file upload. Receives the file data as its argument, allowing for custom handling of the uploaded file.
 * @property {(file: Response) => void} [onFileRemove] - Optional callback function invoked when a file is removed. Receives the file data as its argument, allowing for custom handling of the removed file.
 */
export interface FileUploadProps<Response> {
  handleUpload: (file: File) => Promise<Response>;
  handleFetch?: (id: string) => Promise<Response>;
  id?: string;
  file?: Response;
  name: string;
  renderFileName: (file: Response) => ReactNode;
  className?: string;
  description: string;
  allowedExtensions?: string[];
  preventFileUpload?: string | false;
  onFileUpload?: (response: Response) => void;
  onFileRemove?: (file: Response) => void;
  largePreview?: boolean;
}

interface UploadStyleProps {
  $isDragOver: boolean;
  $isShowCircle: boolean;
  $isShowRemove: boolean;
  $state: FileUploadStatus;
}

interface UploadCircleStyleProps {
  $rotate: string;
}

const Wrapper = styled.div``;

const Field = styled.div`
  position: relative;
`;

const UploadInner = styled.div<Partial<UploadStyleProps>>`
  --upload-circle-opacity: ${({ $isShowCircle }) => ($isShowCircle ? '1' : '0')};
  --upload-circle-opacity-delay: ${({ $isShowCircle }) => ($isShowCircle ? '.1s' : '0s')};
  --upload-circle-pointer-events: ${({ $isShowCircle }) => !$isShowCircle && 'none'};
  --upload-dot-color: ${({ theme }) => theme.primary.background};
  --upload-file-name-opacity: 0;
  --upload-file-name-x: 4px;
  --upload-file-icon-opacity: 0;
  --upload-remove-button-opacity: ${({ $isShowRemove }) => ($isShowRemove ? '1' : '0')};
  --upload-remove-button-pointer-events: ${({ $isShowRemove }) => ($isShowRemove ? 'auto' : 'none')};

  display: table;
  table-layout: fixed;
  width: 100%;
  height: 120px;
  padding: 12px ${({ $isShowRemove }) => ($isShowRemove ? '48px' : '2px')} 12px 40px;
  color: ${({ theme }) => theme.secondary.background};
  box-sizing: border-box;
  border-radius: 5px;
  background-color: ${({ $isDragOver, $state, theme }) =>
    $state === 'success'
      ? theme.background
      : $isDragOver
        ? theme.primary.blendToBackground(100)
        : theme.secondary.blendToBackground(theme.id === 'dark' ? 10 : 75)};
  transition: background-color 0.15s;
  position: relative;
  z-index: 1;

  &:before,
  &:after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    pointer-events: none;
  }

  &:before {
    border: 1px dashed
      ${({ $isDragOver, theme }) =>
        $isDragOver
          ? theme.primary.blendToBackground(850)
          : theme.secondary.blendToBackground(theme.id === 'dark' ? 200 : 300)};
    transition: border-color 0.15s;
  }

  &:after {
    border: 1px solid ${({ theme }) => theme.secondary.blendToBackground(theme.id === 'dark' ? 150 : 200)};
    opacity: ${({ $state }) => ($state === 'success' ? '1' : '0')};
    transition: opacity 0.15s;
    z-index: 1;
  }

  &:hover {
    &:before {
      border-color: ${({ $isDragOver, $state, theme }) =>
        !$isDragOver && $state !== 'success' && theme.secondary.blendToBackground(700)};
    }
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    inset: 0;
    cursor: pointer;
    pointer-events: ${({ $state }) => $state !== 'default' && 'none'};
  }
`;

const RemoveButton = styled(Button)`
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: 4;
  transform: opacity 0.15s;
  opacity: var(--upload-remove-button-opacity);
  pointer-events: var(--upload-remove-button-pointer-events);
`;

const CircleWrapper = styled.div`
  --circle-svg-scale: 1;

  opacity: var(--upload-circle-opacity);
  pointer-events: var(--upload-circle-pointer-events);
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -26px 0 0 -26px;
  z-index: 2;
  transition: opacity 0.15s ease var(--upload-circle-opacity-delay);
  color: ${({ theme }) => theme.primary.background};
`;

const Circle = styled.div`
  transform: translateY(var(--upload-circle-svg-y));
  position: relative;
  pointer-events: none;
`;

const CircleSVG = styled.svg.attrs<UploadCircleStyleProps>(({ $rotate }) => ({
  style: {
    transform: `scale(var(--circle-svg-scale)) rotate(${$rotate}) translateZ(0)`,
  },
}))<UploadCircleStyleProps>`
  display: block;
  width: 52px;
  height: 52px;
  position: relative;
  z-index: 2;
  fill: currentColor;
`;

const FileName = styled.div`
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: table-cell;
  pointer-events: none;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  opacity: var(--upload-file-name-opacity);
  transform: translateX(var(--upload-file-name-x)) translateZ(0);

  small {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

const FileIcon = styled.div<{ $isShow?: boolean }>`
  position: absolute;
  left: 12px;
  top: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary.background};
  z-index: 2;
  pointer-events: none;
  opacity: var(--upload-file-icon-opacity);
`;

const iconStyles = css`
  color: ${({ theme }) => theme.primary.foreground};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  transition: opacity 0.15s;
`;

const IconArrow = styled(Icon.ArrowDown)<{ $isShow: boolean }>`
  ${iconStyles}
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  transition-delay: ${({ $isShow }) => ($isShow ? '.1s' : '0s')};
`;

const IconLoading = styled(Icon.Loading)<{ $isShow: boolean }>`
  ${iconStyles}
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  transition-delay: ${({ $isShow }) => ($isShow ? '.1s' : '0s')};
`;

const IconCeck = styled(Icon.Checkmark)<{ $isShow: boolean }>`
  ${iconStyles}
  opacity: ${({ $isShow }) => ($isShow ? '1' : '0')};
  transition-delay: ${({ $isShow }) => ($isShow ? '.1s' : '0s')};
`;

const UploadText = styled.div<{ $isHidden?: boolean }>`
  color: ${({ theme }) => theme.secondary.blendToBackground(700)};
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  pointer-events: none;
  opacity: ${({ $isHidden }) => $isHidden && '0'};
  transform: translate(0, ${({ $isHidden }) => ($isHidden ? '0%' : '-50%')}) translateZ(0);
  transition:
    transform 0.15s,
    opacity 0.15s ease ${({ $isHidden }) => ($isHidden ? '0s' : '.1s')};

  strong {
    color: ${({ theme }) => theme.secondary.blendToBackground(800)};
    font-weight: 600;
  }
`;

const Info = styled.p`
  margin: 8px 0 0 0;
  color: ${({ theme }) => theme.secondary.blendToBackground(900)};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;

export const FileUpload = <Response,>({
  handleUpload,
  handleFetch,
  id,
  file,
  name,
  renderFileName,
  className,
  children,
  description,
  allowedExtensions,
  preventFileUpload,
  onFileUpload,
  onFileRemove,
  largePreview = false,
}: PropsWithChildren<FileUploadProps<Response>>) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const circleWrapperRef = useRef<HTMLDivElement>(null);
  const circleRef = useRef<HTMLDivElement>(null);
  const circleSVGRef = useRef<SVGSVGElement>(null);
  const pathRef = useRef<SVGPathElement>(null);

  const circleTweenRef = useRef<gsap.core.Tween>();
  const [circleRotation, setCircleRotation] = useState<string>('0deg');

  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentUser = useSessionStore((s) => s.currentUser);

  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isDragOverCircle, setIsDragOverCircle] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const [state, setState] = useState<FileUploadStatus>('default');

  const [fileObject, setFileObject] = useState<Response | undefined>(file);

  const setDistance = (value: number, options?: gsap.TweenVars) => {
    const duration = options?.duration ?? 0.15;

    gsap.to(pathRef.current, {
      morphSVG: getCirclePath(value),
      duration,
      ...options,
    });
  };

  const handleDragEnterCircle = () => {
    setDistance(12);

    setIsDragOverCircle(true);
  };

  const handleDragLeaveCircle = () => {
    setIsDragOverCircle(false);
  };

  const handleDragEnter = useCallback((event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();

    setIsDragOver(true);

    if (circleTweenRef.current) {
      circleTweenRef.current.play();
      return;
    }

    circleTweenRef.current = gsap.to(circleRef.current, {
      repeat: -1,
      keyframes: [
        {
          '--upload-circle-svg-y': '4px',
          duration: 0.4,
        },
        {
          '--upload-circle-svg-y': '-4px',
          duration: 0.8,
        },
        {
          '--upload-circle-svg-y': '0px',
          duration: 0.4,
        },
      ],
    });
  }, []);

  const handleDragLeave = (event: DragEvent<HTMLInputElement>) => {
    event.preventDefault();

    setDistance(12, {
      duration: 0.25,
    });

    setIsDragOver(false);
  };

  const handleDragOver = useCallback(
    (event: DragEvent<HTMLInputElement>) => {
      const element = event.target as HTMLElement;

      if (!circleRef.current) {
        return;
      }

      setCircleRotation(
        `${calculateRotate(element, {
          mouseX: event.pageX,
          mouseY: event.pageY,
        })}deg`
      );

      setDistance(
        clampNumber(
          calculateDistance(circleRef.current, element, {
            mouseX: event.pageX,
            mouseY: event.pageY,
          })
        ) * 12
      );

      setIsDragOver(true);
    },
    [circleRef]
  );

  const handleRemoveButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const uploadElement = uploadRef.current;

    setState('default');
    setIsDragOver(false);
    setIsDone(false);
    setFileObject((s) => {
      if (s && onFileRemove) {
        onFileRemove(s);
      }
      return undefined;
    });

    const circleWrapperElement = circleWrapperRef.current;

    if (circleWrapperElement) {
      resetCircle(circleWrapperElement);
    }

    gsap.to(uploadElement, {
      '--upload-file-name-opacity': 0,
      '--upload-file-name-x': '4px',
      '--upload-file-icon-opacity': 0,
      height: '120px',
      duration: 0.25,
    });
  };

  const handleFileUpload = useCallback(
    (uploadFile: File, onSuccess: () => void, onError: () => void) => {
      if (!currentUser?.defaultPlatformId) {
        return;
      }

      if (typeof preventFileUpload !== 'undefined') {
        if (preventFileUpload && preventFileUpload.length > 0) {
          addDangerNotification({
            content: preventFileUpload,
            display: `file-upload-${name}`,
          });
          onError();
          return;
        }
      }

      if (typeof allowedExtensions !== 'undefined') {
        const fileExtension = uploadFile.name.split('.').pop();
        if (allowedExtensions.includes(String(fileExtension).toLowerCase())) {
          addDangerNotification({
            content: `The file extension ".${fileExtension}" is not allowed.`,
            display: `file-upload-${name}`,
          });
          onError();
          return;
        }
      }

      handleUpload(uploadFile)
        .then((response) => {
          onSuccess();

          setIsDone(true);
          setFileObject(response);

          if (onFileUpload) {
            onFileUpload(response);
          }
        })
        .catch((error) => {
          onError();

          addDangerNotification({
            content: error.message,
            display: `file-upload-${name}`,
          });
        });
    },
    [
      handleUpload,
      description,
      preventFileUpload,
      onFileUpload,
      allowedExtensions,
      currentUser?.defaultPlatformId,
      name,
    ]
  );

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const uploadElement = uploadRef.current;
      const circleWrapperElement = circleWrapperRef.current;

      if (!uploadElement || !circleWrapperElement || state !== 'default') {
        return;
      }

      const eventFile =
        event.dataTransfer.files && event.dataTransfer.files?.length > 0 ? event.dataTransfer.files[0] : undefined;

      if (!uploadRef.current || !circleRef.current || !eventFile) {
        return;
      }

      if (circleTweenRef.current) {
        circleTweenRef.current.pause();

        gsap.to(circleRef.current, {
          '--upload-circle-svg-y': '0px',
        });
      }

      setState('loading');

      createParticles(
        uploadElement,
        circleRef.current,
        {
          mouseX: event.pageX,
          mouseY: event.pageY,
        },
        () => {
          setDistance(18, {
            duration: 0.15,
            onComplete: () => {
              setDistance(12, {
                duration: 0.2,
              });

              handleFileUpload(
                eventFile,
                () => {
                  moveCircle(circleWrapperElement, uploadElement, largePreview, () => {
                    gsap.to(uploadElement, {
                      '--upload-file-name-opacity': 1,
                      '--upload-file-name-x': '0px',
                      duration: 0.25,
                    });
                  });
                  setState('success');
                },
                () => {
                  setIsDragOver(false);
                  setState('default');
                }
              );
            },
          });
        }
      );
    },
    [state, description]
  );

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const uploadElement = uploadRef.current;
    const circleElement = circleWrapperRef.current;

    if (!uploadElement || !circleElement || state !== 'default') {
      return;
    }

    const eventFile = event.target.files && event.target.files?.length > 0 ? event.target.files[0] : undefined;

    if (!eventFile) {
      return;
    }

    setState('loading');

    handleFileUpload(
      eventFile,
      () => {
        moveCircle(circleElement, uploadElement, largePreview, () => {
          gsap.to(uploadElement, {
            '--upload-file-name-opacity': 1,
            '--upload-file-name-x': '0px',
            '--upload-file-icon-opacity': 1,
            duration: 0.25,
          });

          gsap.to(uploadElement, {
            height: '44px',
            duration: 0.3,
          });

          setState('success');
        });
      },
      () => {
        setState('default');

        if (inputRef.current?.value) {
          inputRef.current.value = '';
        }
      }
    );
  };

  useEffect(() => {
    const uploadElement = uploadRef.current;

    if (!id || !handleFetch || !currentUser?.defaultPlatformId || !uploadElement || state === 'loading' || fileObject) {
      return;
    }

    handleFetch(id)
      .then((response) => {
        setFileObject(response);
        setIsDone(true);

        gsap.set(uploadElement, {
          '--upload-file-name-opacity': 1,
          '--upload-file-name-x': '0px',
          '--upload-file-icon-opacity': 1,
          height: '44px',
        });

        setState('success');
      })
      .catch((e) => console.error('FileUpload.handleGet', e));
  }, [handleFetch, id, currentUser?.defaultPlatformId, state, fileObject, uploadRef]);

  useEffect(() => {
    const uploadElement = uploadRef.current;

    if (file && file !== fileObject && uploadElement && state === 'default') {
      setFileObject(file);
      setIsDone(true);

      gsap.set(uploadElement, {
        '--upload-file-name-opacity': 1,
        '--upload-file-name-x': '0px',
        '--upload-file-icon-opacity': 1,
        height: '44px',
      });

      setState('success');
    }
  }, [file, uploadRef]);

  return (
    <Wrapper className={className}>
      <NotificationList display={`file-upload-${name}`} />
      <Field>
        <UploadInner
          ref={uploadRef}
          $isShowCircle={isDragOver || isDragOverCircle || state === 'loading'}
          $isShowRemove={!!onFileRemove && isDone}
          $isDragOver={isDragOver || isDragOverCircle}
          $state={state}
          onDrop={handleDrop}
        >
          <input
            ref={inputRef}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragLeave}
            type="file"
            name="file"
            onChange={handleFileChange}
          />
          <CircleWrapper
            ref={circleWrapperRef}
            onDragEnter={handleDragEnterCircle}
            onDragOver={handleDragEnterCircle}
            onDragLeave={handleDragLeaveCircle}
            onDragEnd={handleDragLeaveCircle}
          >
            <Circle ref={circleRef}>
              <CircleSVG ref={circleSVGRef} $rotate={circleRotation} viewBox="0 0 92 92">
                <path
                  ref={pathRef}
                  d="M46,80 C55.3966448,80 63.9029705,76.1880913 70.0569683,70.0262831 C76.2007441,63.8747097 80,55.3810367 80,46 C80,36.6003571 76.1856584,28.0916013 70.0203842,21.9371418 C63.8692805,15.7968278 55.3780386, 12 46, 12 C36.596754, 12 28.0850784,15.8172663 21.9300655,21.9867066 C15.7939108,28.1372443 12,36.6255645 12,46 C12,55.4035343 15.8175004,63.9154436 21.9872741,70.0705007 C28.1377665,76.2063225 36.6258528,80 46,80 Z"
                />
              </CircleSVG>

              <IconArrow $isShow={state === 'default'} />
              <IconLoading $isShow={state === 'loading'} />
              <IconCeck $isShow={state === 'success'} />
            </Circle>
          </CircleWrapper>
          <UploadText $isHidden={isDragOver || isDragOverCircle || (state && state !== 'default')}>
            {children}
            Drag file here, or <strong>click to upload</strong>
          </UploadText>
          <FileIcon>
            <IconLoading $isShow={state === 'loading'} />
            <IconCeck $isShow={state === 'success'} />
          </FileIcon>
          <FileName>{fileObject && renderFileName(fileObject)}</FileName>
          <RemoveButton
            icon={<Icon.Cross />}
            variant="subtle"
            size="small"
            type="button"
            onlyIcon
            onClick={handleRemoveButtonClick}
          />
        </UploadInner>

        <Info>
          Please ensure all information is legible.{' '}
          {allowedExtensions && <>We support {allowedExtensions.join(', ')}.</>}
        </Info>
      </Field>
    </Wrapper>
  );
};
