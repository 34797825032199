import React, { useState, FormEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Checkbox } from '@column/column-ui-kit';
import { Form } from '../../Login';
import {
  Option,
  OptionList,
  OtherContainerSmall,
  RegisterAction,
  RegisterContent,
  RegisterContentGrid,
  StyledTextarea,
} from './FinancialProducts';
import { NotificationList } from '~/components';
import { Headline, Paragraph } from '~/styles';
import { useNotificationStore } from '~/stores/Notification';
import { useSessionStore } from '~/stores/Session';
import { ROUTE } from '~/public/routes';

export const PageRegisterCustomers: React.FC = () => {
  const addDangerNotification = useNotificationStore((s) => s.addDangerNotification);
  const currentPlatformState = useSessionStore((state) => state.currentPlatform);
  const [loading, setLoading] = useState<boolean>(false);
  const [customerTypes, setCustomerTypes] = useState<string[]>([]);
  const [customerTypeOther, setCustomerTypeOther] = useState<string>('');
  const navigate = useNavigate();

  const handleOptionClick = (optionKey: string) => {
    if (customerTypes.includes(optionKey)) {
      setCustomerTypes(customerTypes.filter((k) => k !== optionKey));
      return;
    }
    setCustomerTypes([...customerTypes, optionKey]);
  };

  const onSuccess = (event: FormEvent) => {
    event.preventDefault();

    setLoading(true);

    currentPlatformState
      ?.updateMeta({
        customerTypes,
        customerTypeOther,
      })
      .then(() => {
        setLoading(false);
        navigate(ROUTE.REGISTER_COMPLIANCE);
      })
      .catch((error) => {
        setLoading(false);

        addDangerNotification({
          content: error.message,
          display: 'page',
        });
      });
  };

  useEffect(
    () =>
      useSessionStore.subscribe(
        (state) => state.currentPlatform,
        (currentPlatform) => {
          setCustomerTypes(currentPlatform?.customerTypes ?? []);
          setCustomerTypeOther(currentPlatform?.customerTypeOther ?? '');
        },
        { fireImmediately: true }
      ),
    []
  );

  return (
    <RegisterContent>
      <RegisterContentGrid>
        <Headline>Who are your customers?</Headline>
        <Paragraph size="small">The more we know about who your customers are, the more helpful we can be.</Paragraph>
      </RegisterContentGrid>

      <Form onSubmit={onSuccess}>
        <NotificationList display="page" />
        <div>
          <OptionList>
            {['Consumers', 'Sole Propretorships', 'Small Businesses', 'Large Enterprises', 'Other'].map(
              (optionKey: string) => {
                return (
                  <Option
                    isActive={customerTypes.includes(optionKey)}
                    isSimple
                    key={optionKey}
                    onClick={() => handleOptionClick(optionKey)}
                  >
                    <Checkbox
                      isChecked={customerTypes.includes(optionKey)}
                      onCheckedChange={() => handleOptionClick(optionKey)}
                      label={optionKey}
                    />
                  </Option>
                );
              }
            )}
          </OptionList>

          <OtherContainerSmall isClose={!customerTypes.includes('Other')}>
            <StyledTextarea
              placeholder="Other customer types"
              onChange={setCustomerTypeOther}
              value={customerTypeOther}
            />
          </OtherContainerSmall>
        </div>
        <RegisterAction>
          <Button variant="muted" onClick={() => navigate(ROUTE.REGISTER_COMPANY_EMPLOYEES)} type="button">
            Back
          </Button>
          <Button isLoading={loading} isDisabled={customerTypes.length < 1}>
            Continue
          </Button>
        </RegisterAction>
      </Form>
    </RegisterContent>
  );
};
