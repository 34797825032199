import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, PasswordInput } from '@column/column-ui-kit';
import { Form, HintLink, Panel, Rows, StyledLogo } from './Login';
import { ROUTE } from '~/public/routes';
import { Headline } from '~/styles';
import { UserRepository } from '~/repositories';
import { NotificationList, PasswordStrength } from '~/components';
import { useNotificationStore } from '~/stores/Notification';
import { log } from '~/util';
import { reCaptchaCheck } from '~/util/reCaptcha';

interface Params {
  code: string;
}

export const PageNewPassword: React.FC = () => {
  const { addSuccessNotification, addDangerNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { control, getValues, handleSubmit } = useForm();
  const { code } = useParams<keyof Params>() as Params;

  const { handleReCaptcha } = reCaptchaCheck();

  const equal = () => {
    return getValues('password') === getValues('repeat-password');
  };

  const onSuccess = () => {
    setLoading(true);

    handleReCaptcha(
      (reCaptchaToken: string) =>
        UserRepository.passwordReset(getValues('password'), code, reCaptchaToken)
          .then(() => {
            setLoading(false);
            log({
              name: 'User password request changed',
              context: {
                code,
              },
            });
            navigate(ROUTE.LOGIN);
            addSuccessNotification({
              content: 'Password changed',
              display: 'page',
            });
          })
          .catch((error) => {
            setLoading(false);
            addDangerNotification({
              content: error.message,
              display: 'page',
            });
          }),
      (err) => {
        setLoading(false);

        addDangerNotification({
          content: err.message,
          display: 'page',
        });
      }
    );
  };

  return (
    <Panel>
      <StyledLogo size="24px" />
      <Headline size="small">New password</Headline>
      <Form onSubmit={handleSubmit(onSuccess)}>
        <NotificationList display="page" />
        <Rows>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <PasswordStrength hasError={isTouched && !!error} {...field} />
            )}
          />
          <Controller
            name="repeat-password"
            control={control}
            defaultValue=""
            rules={{ required: true, validate: equal }}
            render={({ field, fieldState: { error, isTouched } }) => (
              <PasswordInput placeholder="Repeat" hasError={isTouched && !!error} {...field} />
            )}
          />
        </Rows>
        <Rows>
          <Button isLoading={loading}>Save</Button>
          <HintLink as={Link} to={ROUTE.LOGIN}>
            Back to sign in
          </HintLink>
        </Rows>
      </Form>
    </Panel>
  );
};
