import { createApiHook } from './utils/createApiHook';
import { ListInvitesResponse } from '~/repositories/InviteRepository';
import {
  AcceptInviteToPlatformRequest,
  AcceptInviteToPlatformResponse,
  ListInvitesRequest,
  ListPlatformRolesRequest,
  ListPlatformRolesResponse,
  UserRepository,
} from '~/repositories/UserRepository';
import { User } from '~/app/Layout';

export const useAcceptPlatformInvite = createApiHook<AcceptInviteToPlatformResponse, AcceptInviteToPlatformRequest>(
  UserRepository.acceptInvite
);

export const useListPlatformInvites = createApiHook<ListInvitesResponse, ListInvitesRequest>(
  UserRepository.getInvites,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
  }
);

export const useListPlatformRoles = createApiHook<ListPlatformRolesResponse, ListPlatformRolesRequest>(
  UserRepository.platformRoles,
  {
    triggerAutomatically: true,
    triggerOnSessionStoreSubscribe: true,
  }
);
