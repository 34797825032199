import { DependencyList, useCallback, useEffect, useRef } from 'react';

const debounce = <F extends (...args: any[]) => any>(func: F, waitMilliseconds: number) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: any[]) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => func(...args), waitMilliseconds);
  };
};

export const useDebounce = <F extends (...args: any[]) => any>(
  callback: F,
  delay: number,
  dependencyList?: DependencyList
) => {
  const callbackRef = useRef<F>(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const dependencies = [delay, callback];

  return useCallback(
    (...args: Parameters<F>) => debounce<F>(callbackRef.current, delay)(...args),
    dependencyList ? [...dependencies, ...dependencyList] : dependencies
  );
};
