import React, { useRef, FC } from 'react';
import styled from 'styled-components';
import { IconBase } from '@column/column-ui-kit';

interface CheckScannerIconProps {
  className?: string;
}

const Wrapper = styled.div``;

export const CheckScannerIcon: FC<CheckScannerIconProps> = ({ className }) => {
  const wrapeprRef = useRef<HTMLDivElement>(null);

  return (
    <Wrapper ref={wrapeprRef} className={className}>
      <IconBase viewportSize={20}>
        <path d="M17.25 9.75H2.75V17.25H17.25V9.75Z" stroke="currentColor" strokeWidth="1.5" />
        <path d="M16 6.67999H4" stroke="currentColor" strokeWidth="1.5" />
        <path d="M14 3.75H6" stroke="currentColor" strokeWidth="1.5" />
        <path d="M9.5 14.5H5.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M15 13H12" stroke="currentColor" strokeWidth="1.5" />
      </IconBase>
    </Wrapper>
  );
};
