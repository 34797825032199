import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Icon } from '@column/column-ui-kit';
import styled from 'styled-components';
import { BankAccountRouteProps } from './Route';
import { Datepicker, EmptyState, SectionHeader } from '~/components';
import { ReportingTable } from '~/components/ReportingTable';
import { EditPage, FormLabel, Inner, Grid, Paragraph, FormElement } from '~/styles';
import { downloadBlob } from '~/util';
import { AlertData, useAlertStore } from '~/stores/Alert';
import { useCustomizedBankAccountStatement } from '~/hooks/useReporting';
import { useNotificationStore } from '~/stores/Notification';
import { CustomizedBankAccountStatementParams } from '~/typings/API';
import { FeatureFlag, useFeatureFlag } from '~/lib/flags';

const DatePreviewText = styled.span`
  color: ${({ theme }) => theme.secondary.blendToBackground(800)};

  svg {
    display: inline-block;
    vertical-align: top;
    margin: 4px;

    --icon-size: 14px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(600)};
  }
`;

const DatePreview: React.FC<{ selectedDates: string[]; hoverDate?: string }> = ({ selectedDates, hoverDate }) => {
  if (selectedDates.length === 0) {
    return null;
  }

  const [fromDate, toDate] = useMemo(() => {
    if (selectedDates.length === 1) {
      if (hoverDate) {
        return [selectedDates[0], hoverDate].toSorted();
      }
      return [selectedDates[0], hoverDate ?? '?'];
    }

    return selectedDates.toSorted();
  }, [hoverDate, selectedDates]);

  return (
    <DatePreviewText>
      {fromDate}
      <Icon.ArrowRight />
      {toDate}
    </DatePreviewText>
  );
};

type ActivityExportModalData = AlertData & {
  fromDate?: string;
  toDate?: string;
};

const ActivityExportModalContent: React.FC<{ disableBefore: string; text: string }> = ({ disableBefore, text }) => {
  const { updateAlertData } = useAlertStore();
  const [dates, setDates] = useState<string[]>([]);
  const [hoverDate, setHoverDate] = useState<string | undefined>(undefined);

  useEffect(() => {
    const sortedDates = dates.toSorted();
    updateAlertData({
      fromDate: sortedDates.length >= 1 ? sortedDates[0] : undefined,
      toDate: sortedDates.length >= 2 ? sortedDates[1] : undefined,
    });
  }, [dates]);

  const disableBeforeExclusive = useMemo(() => {
    const disableBeforeInclusive = new Date(disableBefore);
    disableBeforeInclusive.setDate(disableBeforeInclusive.getDate() - 1);
    return disableBeforeInclusive;
  }, [disableBefore]);

  return (
    <>
      <Paragraph>{text}</Paragraph>
      <Grid>
        <FormElement fullWidth>
          <Inner pb={0}>
            <Inner pb={12} pt={0} px={0}>
              <FormLabel style={{ textAlign: 'left' }}>
                Date Range <DatePreview hoverDate={hoverDate} selectedDates={dates} />
              </FormLabel>
            </Inner>
            <Datepicker
              date={dates}
              onDateSubmit={(value) => {
                setDates(value as string[]);
              }}
              showMonths={2}
              disableBefore={disableBeforeExclusive}
              disableAfter={new Date()}
              onHoverDate={(value) => setHoverDate(value)}
            />
          </Inner>
        </FormElement>
      </Grid>
    </>
  );
};

const getFilenameFromStatementRequest = (req: CustomizedBankAccountStatementParams | undefined): string => {
  if (!req) {
    return 'activity.csv';
  }

  const { bankAccountId, fromDate, toDate } = req;
  return `activity_${bankAccountId}_${fromDate}_${toDate}.csv`;
};

export const PageBankAccountsStatements: FC = () => {
  const { openAlert, closeAlert, updateAlertData } = useAlertStore();
  const { addDangerNotification, addSuccessNotification } = useNotificationStore();
  const { bankAccount } = useOutletContext<BankAccountRouteProps>();
  const enableCustomCSVStatements = useFeatureFlag(FeatureFlag.EnableCustomCSVStatements);

  const currentStatementRequest = useRef<CustomizedBankAccountStatementParams | undefined>(undefined);
  const customizedBankAccountStatement = useCustomizedBankAccountStatement({
    onSuccess: (data) => {
      const filename = getFilenameFromStatementRequest(currentStatementRequest.current);
      downloadBlob(data, filename);
      addSuccessNotification({
        content: 'Report exported successfully',
        display: 'page',
      });
      currentStatementRequest.current = undefined;
      updateAlertData({ submitLoading: false });
      closeAlert();
    },
    onError: (error) => {
      addDangerNotification({
        content: error.message,
        display: 'popup',
      });
      currentStatementRequest.current = undefined;
      updateAlertData({ submitLoading: false });
    },
  });

  const createStatement = useCallback(
    (data?: ActivityExportModalData) => {
      if (!data?.fromDate || !data?.toDate) {
        addDangerNotification({
          content: 'Please select a date range.',
          display: 'popup',
        });

        return;
      }

      updateAlertData({ submitLoading: true });
      const request: CustomizedBankAccountStatementParams = {
        bankAccountId: bankAccount.id,
        fromDate: data.fromDate,
        toDate: data.toDate,
        type: 'csv',
      };
      currentStatementRequest.current = request;
      customizedBankAccountStatement.createRequest(request);
    },
    [bankAccount]
  );

  const handleCreateStatementClick = useCallback(() => {
    const title = bankAccount.displayName ?? bankAccount.description;
    const text = `Create a statement ${title ? `for ${title}` : ''} for a date range or a single date. The statement will be downloaded as a .csv file.`;

    openAlert(
      {
        headline: 'Custom CSV Statement',
        text: <ActivityExportModalContent disableBefore={bankAccount.createdAt} text={text} />,
        submitText: 'Create Statement',
        callback: createStatement,
        size: 'medium',
        type: 'info',
        icon: <Icon.AnimationDownload />,
      },
      {
        submitLoading: false,
        submitStay: true,
        closeTimeoutMs: 500,
      }
    );
  }, [bankAccount]);

  return (
    <EditPage fullWidth smallGap>
      <ReportingTable
        tableId="statements"
        bankAccountId={bankAccount.id}
        filter={{ category: 'statement' }}
        visibleFiles={['pdf', 'csv']}
        hiddenFiles={['json', 'parquet', 'bai2']}
        empty={<EmptyState headline="No statements found" text="Wait for your first statements to get generated." />}
        action={
          enableCustomCSVStatements && (
            <Button
              icon={<Icon.AnimationDownload />}
              size="small"
              variant="secondary"
              onClick={handleCreateStatementClick}
            >
              Create Statement
            </Button>
          )
        }
      />
    </EditPage>
  );
};
